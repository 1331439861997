import { useState } from "react";

import { FeedbackStep, FeedbackSteps } from "../utils";

export const useFeedbackSteps = () => {
  const [activeStep, setActiveStep] = useState<FeedbackStep>(
    FeedbackSteps.WelcomeStep
  );
  const [completedSteps, setCompletedSteps] = useState<FeedbackStep[]>([]);

  const isStepComplete = (step: FeedbackStep) => completedSteps.includes(step);

  const goToStep = (step: FeedbackStep) => {
    setActiveStep(step);
  };

  const goToNextStep = () => {
    setCompletedSteps((prevState) => [...new Set([...prevState, activeStep])]);

    if (activeStep !== FeedbackSteps.AdditionalFeedback) {
      setActiveStep((prevState) => (prevState + 1) as FeedbackStep);
    }
  };

  const goToPrevStep = () => {
    if (activeStep !== FeedbackSteps.WelcomeStep) {
      const prevStep = (activeStep - 1) as FeedbackStep;
      setActiveStep(prevStep);
      setCompletedSteps((prevState) =>
        prevState.filter((step) => step !== prevStep)
      );
    }
  };

  return {
    activeStep,
    goToNextStep,
    goToPrevStep,
    goToStep,
    isStepComplete,
    totalSteps: Object.keys(FeedbackSteps).length,
  };
};
