import { useInstalmentScheduleList } from "@gocardless/api/dashboard/instalment-schedule";
import { usePaymentList } from "@gocardless/api/dashboard/payment";
import { useSubscriptionList } from "@gocardless/api/dashboard/subscription";
import { getLocalTimeZone, today } from "@internationalized/date";

import {
  castStringToDate,
  parseToAbsoluteDateTimeString,
} from "src/common/date-helper";

const localTimeZone = getLocalTimeZone();
const localToday = today(localTimeZone);

export const useHasPaymentBeenCreatedWithinLastXDays = ({
  daySpan,
}: {
  daySpan: number;
}) => {
  const { data: paymentListResponse, isLoading: loadingPayments } =
    usePaymentList({
      created_at: {
        gte: castStringToDate(
          parseToAbsoluteDateTimeString(localToday.subtract({ days: daySpan }))
        ),
      },
    });

  const { data: subscriptionListResponse, isLoading: loadingSubscriptions } =
    useSubscriptionList({
      created_at: {
        gte: castStringToDate(
          parseToAbsoluteDateTimeString(localToday.subtract({ days: daySpan }))
        ),
      },
    });

  const {
    data: instalmentScheduleListResponse,
    isLoading: loadingInstalments,
  } = useInstalmentScheduleList({
    created_at: {
      gte: castStringToDate(
        parseToAbsoluteDateTimeString(localToday.subtract({ days: daySpan }))
      ),
    },
  });

  return {
    isLoading: loadingInstalments || loadingSubscriptions || loadingPayments,
    paymentExists:
      !!paymentListResponse?.payments?.length ||
      !!subscriptionListResponse?.subscriptions?.length ||
      !!instalmentScheduleListResponse?.instalment_schedules?.length,
  };
};
