import {
  Box,
  Text,
  TypePreset,
  FieldSet,
  H3,
  AlignItems,
  Space,
  Color,
  ColorPreset,
  Separator,
  useTheme,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";

import { FeedbackFormData } from "../../utils";

import { noOptionStyle, radioStyle } from "./WelcomeStep.styles";

import { ToTranslate } from "src/components/i18n";
import { CustomRadio } from "src/components/ui/CustomRadio/CustomRadio";
import { StepIndicator } from "src/components/ui/StepIndicator/StepIndicator";
import { useSendPageViewEvent } from "src/technical-integrations/segment/useSendPageViewEvent";
import { TrackingEvent } from "src/common/trackingEvents";

export const WelcomeStep: React.FC = () => {
  const { register, watch } = useFormContext<FeedbackFormData>();
  const { theme } = useTheme();
  const keepPostedValue = watch("keepPosted");

  useSendPageViewEvent(
    TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FEEDBACK_FORM_WELCOME_STEP_VIEWED
  );

  return (
    <Box>
      <H3 preset={TypePreset.Heading_03} spaceBelow={1}>
        <ToTranslate>
          We&apos;re working on our data insights and reporting offering and
          your feedback would be invaluable.
        </ToTranslate>
      </H3>
      <Space v={0.25} />
      <Box>
        <Box layout="flex" alignItems={AlignItems.Center}>
          <StepIndicator
            containerStyle={{
              bg: Color.Transparent,
              borderColor: ColorPreset.BorderOnLight_01,
            }}
            number={1}
          />
          <Space layout="inline" h={1.5} />
          <Text preset={TypePreset.Heading_02}>
            <ToTranslate>Register your interest</ToTranslate>
          </Text>
        </Box>

        <Box spaceAbove={0.5}>
          <FieldSet>
            <CustomRadio
              {...register("keepPosted", { required: true })}
              value="yes"
              containerStyle={{
                css: [
                  radioStyle(theme, { isSelected: keepPostedValue === "yes" }),
                ],
              }}
              description={
                <Text preset={TypePreset.Caption_01}>
                  <ToTranslate>
                    I am happy to receive updates on new data insights &
                    reporting features, including being invited to interviews
                    and early access programmes.
                  </ToTranslate>
                </Text>
              }
            >
              <Text preset={TypePreset.Heading_01}>
                <ToTranslate>Keep me notified</ToTranslate>
              </Text>
            </CustomRadio>
            <CustomRadio
              {...register("keepPosted", { required: true })}
              value="no"
              containerStyle={{
                css: [
                  radioStyle(theme, { isSelected: keepPostedValue === "no" }),
                  noOptionStyle(theme),
                ],
              }}
              description={
                <Text preset={TypePreset.Caption_01}>
                  <ToTranslate>
                    I do not wish to receive communications about data insights
                    & reporting features.
                  </ToTranslate>
                </Text>
              }
            >
              <Text preset={TypePreset.Heading_01}>
                <ToTranslate>No thanks</ToTranslate>
              </Text>
            </CustomRadio>
          </FieldSet>
        </Box>
      </Box>

      <Separator direction="block-horizontal" spacing={[[2, 0]]} />

      <Box>
        <Box layout="flex" alignItems={AlignItems.Center} spaceBelow={1}>
          <StepIndicator
            containerStyle={{
              bg: Color.Transparent,
              borderColor: ColorPreset.BorderOnLight_01,
            }}
            number={2}
          />
          <Space layout="inline" h={1.5} />
          <Text preset={TypePreset.Heading_02}>
            <ToTranslate>Share your opinion</ToTranslate>
          </Text>
        </Box>

        <Text preset={TypePreset.Body_01}>
          <ToTranslate>
            Contribute to our solution design by answering 3 simple questions
          </ToTranslate>
        </Text>
      </Box>
    </Box>
  );
};
