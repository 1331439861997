import { PaymentStatusEnum } from "@gocardless/api/dashboard/types";

import { Currency } from "src/common/currencies";

export interface Payment {
  id: string;
  status: string;
  amount: number | string;
  net: number | null;
  associatedResource: string | null;
  amountRefunded: number | string;
  description: string | null;
  chargeDate: string;
  paidOut: string;
  customer: string | null;
  currency: Currency;
  netCurrency: Currency;
  source: string;
  app: string | null;
  failureProbability: string | null;
  creditor: string | null;
  mandate: string | null;
  account?: string;
}

export const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

// This is required format for the API for charge dates.
export const DateFormat = "YYYY-MM-DD";

export enum PaymentFilters {
  type = "date_type",
  currency = "currency",
  status = "status",
  createdAt = "created_at",
  created = "created",
  createdAtGTE = "created_at_gte",
  createdAtLTE = "created_at_lte",
  chargeDate = "charge_date",
  chargeDateGTE = "charge_date_gte",
  chargeDateLTE = "charge_date_lte",
  before = "before",
  after = "after",
  sortDirection = "sort_direction",
  sortField = "sort_field",
  customer = "customer",
  subscription = "subscription",
  mandate = "mandate",
  creditor = "creditor",
}

export enum PaymentsFilterType {
  Created = "created",
  ChargeDate = "charge_date",
}

export enum PaymentsSortType {
  failureProbability = "failure_probability",
  chargeDate = "charge_date",
  amount = "amount",
}

export const PaymentsAPIExcludedFilters = [
  PaymentFilters.chargeDate,
  PaymentFilters.chargeDateGTE,
  PaymentFilters.chargeDateLTE,
  PaymentFilters.createdAt,
  PaymentFilters.createdAtGTE,
  PaymentFilters.createdAtLTE,
  PaymentFilters.type,
];

export const DashboardChargedBackAndRefunded = "charged_back_and_refunded";
export const DashboardFailedAndRefunded = "failed_and_refunded";
export const DashboardFullyRefunded = "fully_refunded";
export const DashboardPartRefunded = "part_refunded";

export const NullableStates = [
  PaymentStatusEnum.Failed,
  PaymentStatusEnum.Cancelled,
  PaymentStatusEnum.PendingCustomerApproval,
  PaymentStatusEnum.CustomerApprovalDenied,
];
