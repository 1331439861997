import {
  Dialog,
  P,
  UL,
  LI,
  Color,
  Interpose,
  Space,
  TypePreset,
  Text,
  FontWeight,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Trans } from "@lingui/macro";

import { DialogBodyWrapper } from "../components/DialogBodyWrapper";
import { withChildrenTarget } from "../components/withChildrenTarget";
import { DialogCTA, DialogCTAProps } from "../components/DialogCTA";

import DDPaymentDialogSVG from "src/assets/svg/suggested-actions-dd-payment-dialog.svg";
import { Route } from "src/common/routing";

export const TryDDPaymentsBodyWrapper = () => (
  <DialogBodyWrapper
    bg={Color.Dusk_200}
    image={
      <Image
        src={DDPaymentDialogSVG}
        alt="one-off-dialog"
        width={96}
        height={96}
      />
    }
    title={<Trans>Try One-off payments with Direct Debit</Trans>}
  >
    <Interpose node={<Space v={1} />}>
      <P preset={TypePreset.Body_01}>
        <Trans>
          {`Schedule a single payment from a customer for extra charges or services and minimise the risk of your customers forgetting to pay you.`}{" "}
        </Trans>
      </P>
      <P>
        <Text weight={FontWeight.Bold}>
          <Trans>One-off payments are great for:</Trans>
        </Text>
      </P>

      <UL>
        <LI>
          <Text preset={TypePreset.Body_01}>
            <Trans>Charging for extra work or project</Trans>
          </Text>
        </LI>
        <LI>
          <Text preset={TypePreset.Body_01}>
            <Trans>Charging for yearly software fee</Trans>
          </Text>
        </LI>
        <LI>
          <Text preset={TypePreset.Body_01}>
            <Trans>Collecting for one-off invoice</Trans>
          </Text>
        </LI>
      </UL>
    </Interpose>
  </DialogBodyWrapper>
);

export const TryDDPaymentsFooter = ({ name }: Pick<DialogCTAProps, "name">) => (
  <DialogCTA route={Route.OneOffPaymentCreate} name={name} />
);

export const TryDDPayments = withChildrenTarget(({ ...props }) => (
  <>
    <Dialog {...props} footer={<TryDDPaymentsFooter name={props.id} />}>
      <DialogBodyWrapper
        bg={Color.Dusk_200}
        image={
          <Image
            src={DDPaymentDialogSVG}
            alt="one-off-dialog"
            width={96}
            height={96}
          />
        }
        title={<Trans>Try One-off payments with Direct Debit</Trans>}
      >
        <Interpose node={<Space v={1} />}>
          <P preset={TypePreset.Body_01}>
            <Trans>
              {`Schedule a single payment from a customer for extra charges or services and minimise the risk of your customers forgetting to pay you.`}
            </Trans>
          </P>
          <P>
            <Text weight={FontWeight.Bold}>
              <Trans>One-off payments are great for:</Trans>
            </Text>
          </P>

          <UL>
            <LI>
              <Text preset={TypePreset.Body_01}>
                <Trans>Charging for extra work or projects</Trans>
              </Text>
            </LI>
            <LI>
              <Text preset={TypePreset.Body_01}>
                <Trans>Charging for yearly software fees</Trans>
              </Text>
            </LI>
            <LI>
              <Text preset={TypePreset.Body_01}>
                <Trans>Collecting for one-off invoices</Trans>
              </Text>
            </LI>
          </UL>
        </Interpose>
      </DialogBodyWrapper>
    </Dialog>
  </>
));
