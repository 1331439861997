import { Fragment, createRef, useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@gocardless/flux-react";
import { ClassicCard } from "@braze/web-sdk";

import { BadBanner } from "./BadBanner";
import { TwoFactorAuthenticationBanner } from "./TwoFactorAuthenticationBanner";
import { ForcedPasswordResetBanner } from "./ForcedPasswordResetBanner";
import { MFAEnforcementDelayedBanner } from "./MFAEnforcementDelayedBanner";
import { PricingUpdateBanner } from "./PricingUpdateBanner";
import { PipeBanner } from "./PipeBanner";
import { FlexiblePayoutsBannerOptIn } from "./FlexiblePayoutsBannerOptIn";

import { FlexiblePayoutsBannerOptOut } from "src/components/routes/Payouts/PayoutBanners/FlexiblePayoutsBannerOptOut";
import {
  BrazeWithBannerTypes,
  withBrazeBanners,
} from "src/components/ui/BrazeBanner/BrazeBanners";
import { BrazeBanner } from "src/components/ui/BrazeBanner/BrazeBanner";
import {
  BannerType,
  BannerPriority,
  MAX_BANNERS,
} from "src/common/bannerProperties";

const banners = [
  {
    priority: BannerPriority.High,
    component: FlexiblePayoutsBannerOptOut,
    type: BannerType.Hardcoded,
  },
  {
    priority: BannerPriority.High,
    component: MFAEnforcementDelayedBanner,
    type: BannerType.Hardcoded,
  },
  {
    priority: BannerPriority.High,
    component: ForcedPasswordResetBanner,
    type: BannerType.Hardcoded,
  },
  {
    priority: BannerPriority.Medium,
    component: FlexiblePayoutsBannerOptIn,
    type: BannerType.Hardcoded,
  },
  {
    priority: BannerPriority.Medium,
    component: TwoFactorAuthenticationBanner,
    type: BannerType.Hardcoded,
  },
  {
    priority: BannerPriority.Medium,
    component: PricingUpdateBanner,
    type: BannerType.Hardcoded,
  },
  {
    priority: BannerPriority.Medium,
    component: PipeBanner,
    type: BannerType.Hardcoded,
  },
  {
    priority: BannerPriority.Low,
    component: BadBanner,
    type: BannerType.Hardcoded,
  },
];

export const TopBanners = ({
  brazeCards = [],
}: {
  brazeCards: BrazeWithBannerTypes[];
}) => {
  const { theme } = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const bannerRef = useRef<HTMLDivElement>(null);
  const totalBanners = [...banners, ...brazeCards];
  const elementsRef = useRef(
    totalBanners.map(({ priority, type }) => ({
      element: createRef<HTMLDivElement>(),
      priority,
      type,
    }))
  );

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      // Filter out null element and sort elements by ascending priority
      const sortedByPriority = elementsRef.current
        .filter((el) => el.element.current)
        .sort((a, b) => b.priority - a.priority);

      const totalBannerItems = sortedByPriority.length;
      const itemsToRemoveCount = Math.max(0, totalBannerItems - MAX_BANNERS); // Calculate the number of items to remove
      // Loop through the elements to remove up to the calculated count
      sortedByPriority.slice(0, itemsToRemoveCount).forEach((item) => {
        const element = item?.element.current;
        if (element instanceof HTMLElement) {
          // Check if the current element is an instance of HTMLElement
          element.style.display = "none";
        }
      });
    });

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
      setIsVisible(true);
      return () => observer.disconnect();
    }

    return () => {};
  }, []);

  // managing visibility in the state allows for a first lifecycle render of banners without flickering of the order
  const visibility = isVisible ? "visible" : "hidden";

  return (
    <Box
      ref={bannerRef}
      css={{ gap: theme.spacing(1), visibility }}
      layout="flex"
      flexDirection="column"
    >
      {totalBanners.map(({ component: Item, ...props }, idx) => (
        <Fragment key={idx.toString()}>
          {elementsRef.current[idx]?.type === BannerType.Hardcoded && Item ? (
            <Item ref={elementsRef.current[idx]?.element} />
          ) : (
            <BrazeBanner
              ref={elementsRef.current[idx]?.element}
              card={props as unknown as ClassicCard}
            />
          )}
        </Fragment>
      ))}
    </Box>
  );
};

export const TopBannersWithBrazeBanners = withBrazeBanners(TopBanners);
