import { CreditorBalanceResource } from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  FontWeight,
  Icon,
  JustifyContent,
  P,
  Space,
  Text,
  TypePreset,
  Visibility,
} from "@gocardless/flux-react";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { FundCategoryTooltipCopy } from "../utils/sharedTranslations";

import { FundCategoryType } from "./constants";
import { useCreditorBalancesLastUpdatedAt } from "./utils";
import { LastUpdatedText } from "./LastUpdatedText";

import { HomeSectionHeader } from "src/components/HomeSectionHeader/HomeSectionHeader";
import {
  Currency,
  currencyToCountryIconName,
  currencyToName,
} from "src/common/currencies";

export interface CarouselConfigItem {
  title: string;
  type: FundCategoryType;
  tooltipCopy: React.ReactNode;
  color: Color;
}

const LAST_PAYOUT_INTERPOLATION_SYMBOL = "{LAST_PAYOUT}";

export interface CreditorBalancesSingleCurrencyProps {
  creditorBalance: CreditorBalanceResource;
  children: ({
    cardConfig,
  }: {
    cardConfig: ReadonlyArray<CarouselConfigItem>;
  }) => JSX.Element;
}

export const CreditorBalancesSingleCurrency: React.FC<
  CreditorBalancesSingleCurrencyProps
> = ({ creditorBalance, children }) => {
  const { i18n } = useLingui();

  const lastUpdatedAt = useCreditorBalancesLastUpdatedAt(creditorBalance);

  const cardConfig: ReadonlyArray<CarouselConfigItem> = [
    {
      type: FundCategoryType.PENDING,
      title: i18n._(
        t({
          id: "pending",
          message: "Pending",
        })
      ),
      tooltipCopy: (
        <FundCategoryTooltipCopy fundCategoryType={FundCategoryType.PENDING} />
      ),
      color: Color.Sunrise_700,
    },
    {
      type: FundCategoryType.COLLECTED,
      title: i18n._(
        t({
          id: "collected",
          message: "Collected",
        })
      ),
      tooltipCopy: (
        <FundCategoryTooltipCopy
          fundCategoryType={FundCategoryType.COLLECTED}
          isNegative={Number(creditorBalance.confirmed) < 0}
        />
      ),
      color: Color.Success_100,
    },
    {
      type: FundCategoryType.PAYOUT,
      title: i18n._(
        t({
          id: "home.last-payout-text",
          message: `Last payout ${LAST_PAYOUT_INTERPOLATION_SYMBOL}`,
        })
      ),
      tooltipCopy: (
        <FundCategoryTooltipCopy fundCategoryType={FundCategoryType.PAYOUT} />
      ),
      color: Color.Info_300,
    },
  ];

  return (
    <Box>
      <Box
        layout="flex"
        justifyContent={JustifyContent.SpaceBetween}
        alignItems={AlignItems.Center}
      >
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Icon
            name={
              currencyToCountryIconName[creditorBalance.currency as Currency]
            }
            size="24px"
          />
          <Space layout="inline" h={0.75} />
          <HomeSectionHeader>
            {i18n._(currencyToName()[creditorBalance.currency as Currency])}{" "}
            <Text weight={FontWeight.Normal}>({creditorBalance.currency})</Text>
          </HomeSectionHeader>
        </Box>
        <Visibility visible={["none", null, "block"]}>
          <Box>
            <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_02}>
              <LastUpdatedText dateTimeString={lastUpdatedAt} />
            </P>
          </Box>
        </Visibility>
      </Box>
      <Space v={1.5} />

      {children({ cardConfig })}
    </Box>
  );
};
