import {
  Box,
  TypePreset,
  H6,
  TypeScale,
  ButtonSize,
  ButtonVariant,
  Button,
  JustifyContent,
  ButtonLayout,
  XYGrid,
  Space,
} from "@gocardless/flux-react";
import { useState } from "react";
import { Trans } from "@lingui/macro";
import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";

import QuickActionCard from "../QuickActionCard/QuickActionCard";
import ActivatePayoutsDialog from "../ActivatePayouts/ActivatePayoutsDialog";

import GreatWorkBanner from "./GreatWorkBanner";
import GetMoreCard from "./GetMoreCard";

import { Route, routerPush } from "src/common/routing";

interface AccountSetupCompletedProps {
  organisationName: string;
  hasClosedBanner: boolean;
  closeBanner: () => void;
  hasDiscountExtension: boolean;
  useRevampedLayout: boolean;
  status: CreditorsVerificationStatus;
  collectionsEnabled?: boolean;
}

const AccountSetupCompleted: React.FC<AccountSetupCompletedProps> = ({
  organisationName,
  hasClosedBanner,
  closeBanner,
  hasDiscountExtension,
  useRevampedLayout,
  status,
  collectionsEnabled,
}) => {
  const [show, setShow] = useState(!hasClosedBanner);
  const [openActivatePayoutsDialog, setOpenActivatePayoutsDialog] =
    useState(false);

  const quickActionsCards = (
    <XYGrid
      templateColumns={["1fr", null, "repeat(3, 1fr)"]}
      rowGap={1.5}
      columnGap={1.5}
    >
      {status === CreditorsVerificationStatus.Successful ? (
        <QuickActionCard.ViewPayments status={status} />
      ) : (
        <QuickActionCard.ActivatePayouts
          status={status}
          setOpenActivatePayoutsDialog={setOpenActivatePayoutsDialog}
        />
      )}
      <QuickActionCard.AddCustomers />
      <QuickActionCard.CreatePayments />
    </XYGrid>
  );

  return (
    <Box gutterV={[0, null, null, 4]}>
      <ActivatePayoutsDialog
        open={openActivatePayoutsDialog}
        toggleDialog={() => setOpenActivatePayoutsDialog(false)}
        collectionsEnabled={collectionsEnabled}
      />
      <XYGrid
        templateAreas={["'name' 'button'", null, "'name button'"]}
        rowGap={2}
        width="100%"
      >
        <Box gridArea="name" width="100%" maxWidth="633px">
          <H6
            size={[TypeScale.Size_06, null, TypeScale.Size_07]}
            preset={TypePreset.Heading_06}
          >
            {organisationName}
          </H6>
        </Box>

        {!useRevampedLayout && (
          <Box
            gridArea="button"
            width="100%"
            layout="flex"
            justifyContent={[JustifyContent.Center, null, JustifyContent.End]}
          >
            <Button
              variant={ButtonVariant.PrimaryOnLight}
              size={[ButtonSize.Md, null, ButtonSize.Sm]}
              layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
              onClick={() =>
                routerPush({
                  route: Route.SetupPayments,
                })
              }
            >
              <Trans id="page.create-payment">Create payment</Trans>
            </Button>
          </Box>
        )}
      </XYGrid>

      <Space v={3} />

      {show && (
        <>
          <GreatWorkBanner
            onClose={() => {
              setShow(false);
              closeBanner();
            }}
            hasDiscountExtension={hasDiscountExtension}
          />
          <Space v={2} />
        </>
      )}

      {useRevampedLayout ? quickActionsCards : <GetMoreCard />}
    </Box>
  );
};

export default AccountSetupCompleted;
