import { CSSRulesFunction } from "@gocardless/flux-react";

import { HEADER_HEIGHT, NAV_WIDTH, NAV_WIDTH_XL } from "./constants";

export const layoutGutterVStyle: CSSRulesFunction = (theme) => [
  theme.responsive([theme.spacing(3), null, theme.spacing(6)], (value) => ({
    paddingTop: value,
  })),
  theme.responsive([theme.spacing(4), null, theme.spacing(6)], (value) => ({
    paddingBottom: value,
  })),
];

//**********************************//
// SINGLE FULL LAYOUT WITH NAV
//**********************************//
export const layoutStyle: CSSRulesFunction = (theme) => [
  theme.responsive([null, null, null, NAV_WIDTH, NAV_WIDTH_XL], (w) => ({
    marginLeft: w ? `${w}` : 0,
    width: w ? `calc(100% - ${w})` : "100%",
  })),
  {
    width: "100%",
    position: "relative",
    borderRadius: "0 0 0 24px",
  },
];

export const contentStyle: CSSRulesFunction<{ hasHeader?: boolean }> = (
  theme,
  { hasHeader }
) => [
  theme.responsive([`${theme.spacing(1.5)}`], (value) => ({
    paddingTop: value,
  })),
  theme.responsive([`${theme.spacing(4)}`, `${theme.spacing(6)}`], (value) => ({
    paddingBottom: value,
  })),
  {
    marginTop: hasHeader ? `${HEADER_HEIGHT}` : 0,
    height: "auto",
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
  },
];

export const headerStyle: CSSRulesFunction = (theme) => [
  theme.responsive([null, null, null, NAV_WIDTH, NAV_WIDTH_XL], (w) => ({
    width: w ? `calc(100% - ${w})` : "100%",
  })),
  {
    width: "100%",
    height: `${HEADER_HEIGHT}`,
    position: "fixed",
  },
  theme.responsive(
    [
      `${theme.spacing(1.5)}`,
      `${theme.spacing(1.5)}`,
      `${theme.spacing(2)}`,
      `${theme.spacing(3)}`,
    ],
    (value) => ({
      paddingLeft: value,
    })
  ),
  theme.responsive(
    [
      `${theme.spacing(0.5)}`,
      `${theme.spacing(0.5)}`,
      `${theme.spacing(1)}`,
      `${theme.spacing(3)}`,
    ],
    (value) => ({
      paddingRight: value,
    })
  ),
];
//**********************************//
