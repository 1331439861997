import {
  Box,
  Color,
  ColorPreset,
  H2,
  Interpose,
  Separator,
  TextAlign,
  TypePreset,
  VisuallyHidden,
  XYGrid,
  useTheme,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { Fragment } from "react";

import { accountHealthSectionDataWidgetTypes } from "../CollectionDataWidget/utils";
import {
  betaWidgetContainerLargeBreakpointStyle,
  mobileSeparatorStyle,
} from "../../utils/style";
import { CollectionDataWidget } from "../CollectionDataWidget/CollectionDataWidget";
import BetaWidget from "../BetaWidget/BetaWidget";
import { useReportingExperiment } from "../experiments/hooks/useReportingExperiment";

export const AccountHealthSection = () => {
  const { theme } = useTheme();

  const { isUpsellExperimentEnabled } = useReportingExperiment();

  const HeadingWrapper = isUpsellExperimentEnabled ? VisuallyHidden : Fragment;

  return (
    <Box gridArea="accountHealth" layout="flex" flexDirection="column">
      <HeadingWrapper>
        <H2
          preset={TypePreset.Heading_04}
          textAlign={TextAlign.Left}
          spaceBelow={1.5}
        >
          <Trans>Account health</Trans>
        </H2>
      </HeadingWrapper>

      <Box
        bg={[Color.White, Color.Brownstone_50]}
        borderColor={[ColorPreset.BorderOnLight_04, Color.White]}
        borderRadius={[1, 0]}
        borderWidth={[1, 0]}
        gutterH={[1.5, 0]}
        gutterV={[1.5, 0]}
        flexGrow={1}
      >
        <XYGrid
          columnGap={[0, 1.5, 2]}
          rowGap={[0, 1.5, 2]}
          templateColumns={[
            "1fr",
            "repeat(2, 1fr)",
            null,
            isUpsellExperimentEnabled ? "repeat(3, 1fr)" : "1fr",
          ]}
          height="100%"
        >
          <Interpose
            node={
              <Separator align="center" css={mobileSeparatorStyle(theme)} />
            }
            trailing
          >
            {accountHealthSectionDataWidgetTypes.map((dataWidgetType) => (
              <CollectionDataWidget
                key={dataWidgetType}
                type={dataWidgetType}
              />
            ))}
          </Interpose>
          <Box css={betaWidgetContainerLargeBreakpointStyle(theme)}>
            <BetaWidget />
          </Box>
        </XYGrid>
      </Box>
    </Box>
  );
};
