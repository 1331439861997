import { ButtonGroup, ButtonVariant, Link } from "@gocardless/flux-react";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";

import { SuggestedActionType } from "../types";

import { TrackingEvent } from "src/common/trackingEvents";
import LinkBuilder, {
  LinkBuilderProps,
} from "src/components/routing/LinkBuilder";
import { useSegment } from "src/technical-integrations/segment/useSegment";

export type DialogCTAProps = Omit<LinkBuilderProps, "onClick" | "children"> & {
  name: SuggestedActionType;
};

export const DialogCTA = (props: DialogCTAProps) => {
  const router = useRouter();
  const { sendEvent } = useSegment();

  const onClick = () => {
    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_SUGGESTED_ACTION_DIALOG_CTA_CLICKED,
      {
        page: router.pathname,
        name: props.name,
      }
    );
  };

  return (
    <ButtonGroup arrangement={["column-center", null, null, "row-end"]}>
      <LinkBuilder {...props} onClick={onClick}>
        {(result) => (
          <Link variant={ButtonVariant.PrimaryOnLight} {...result}>
            <Trans>Try it now</Trans>
          </Link>
        )}
      </LinkBuilder>
    </ButtonGroup>
  );
};
