import {
  OutboundPaymentBalanceResource,
  OutboundPaymentBalancesCurrency,
} from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  JustifyContent,
  Box,
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  ColorPreset,
  FontWeight,
  Glyph,
  H3,
  IconButton,
  MoneyText,
  Space,
  Tooltip,
  TypePreset,
  TypeScale,
} from "@gocardless/flux-react";
import { round } from "lodash";

import { SetupPaymentsEnabled } from "../routes/SetupPayments/SetupPaymentsEnabled";

import { OutboundPaymentBalanceType } from "./OutboundPaymentBalanceType";
import { OutboundPaymentBalanceCardTooltipCopy } from "./OutboundPaymentBalanceCardTooltipCopy";
import { outboundPaymentBalanceAmount } from "./outboundPaymentBalanceAmount";
import { OutboundPaymentBalanceCardTitle } from "./OutboundPaymentBalanceCardTitle";
import { OutboundPaymentBalanceCardTooltipLabelCopy } from "./OutboundPaymentBalanceCardTooltipLabelCopy";

import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useI18n } from "src/technical-integrations/i18n";

export interface OutboundPaymentBalanceCardProps {
  type: OutboundPaymentBalanceType;
  balance: OutboundPaymentBalanceResource | undefined;
  currency: OutboundPaymentBalancesCurrency;
}

export const OutboundPaymentBalanceCard: React.FC<
  OutboundPaymentBalanceCardProps
> = ({ type, balance, currency }) => {
  const [locale] = useI18n();
  const amount = outboundPaymentBalanceAmount(balance, type);
  const roundedAmount = round(amount / 100, 2);
  const contentColor =
    type === OutboundPaymentBalanceType.Available && amount < 0
      ? ColorPreset.AlertTextOnLight
      : ColorPreset.TextOnLight_01;

  const { isVariationOn: preActiveReportingEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_PREACTIVE_MERCHANTS_REPORTING,
  });

  const revampedBalance = !preActiveReportingEnabled;
  const moneyTextProps = {
    amount: roundedAmount,
    currency: currency.toString().toUpperCase(),
    locale: locale,
    preset: TypePreset.Heading_07,
    color: contentColor,
  };
  const responsiveTextSizeProp = revampedBalance ? { size: [7, null, 6] } : {};

  return (
    <Box
      layout="flex"
      flexDirection="column"
      width="100%"
      justifyContent={JustifyContent.FlexStart}
    >
      <Box layout="flex" alignItems={AlignItems.Center}>
        <H3
          preset={TypePreset.Subheading_01}
          size={TypeScale.Size_04}
          color={ColorPreset.TextOnLight_01}
          weight={FontWeight.Light}
        >
          <OutboundPaymentBalanceCardTitle type={type} />
        </H3>
        <Space layout="inline" h={0.5} />
        <Tooltip
          message={<OutboundPaymentBalanceCardTooltipCopy type={type} />}
          triggeredBy="click"
        >
          {(triggerProps) => (
            <IconButton
              {...triggerProps}
              size={{ base: ButtonSize.Md, gutters: ButtonGutter.Sm }}
              icon={Glyph.Tooltip}
              label={<OutboundPaymentBalanceCardTooltipLabelCopy type={type} />}
              variant={ButtonVariant.TextOnLight}
            />
          )}
        </Tooltip>
      </Box>
      <Space v={0.5} />
      <SetupPaymentsEnabled
        defaultNode={
          <MoneyText {...moneyTextProps} data-testid={`amount-${type}`} />
        }
      >
        <MoneyText
          {...moneyTextProps}
          {...responsiveTextSizeProp}
          data-testid={`amount-${type}`}
        />
      </SetupPaymentsEnabled>
    </Box>
  );
};
