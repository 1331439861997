import { useState } from "react";

export const useChartOpacity = ({
  items,
}: {
  items: { dataKey: string }[];
}) => {
  const [opacityConfig, setOpacityConfig] = useState(() =>
    items.reduce<Record<string, number>>((acc, { dataKey }) => {
      acc[dataKey] = 1;
      return acc;
    }, {})
  );

  const handleMouseEnter = (focusedDataKey: string) => {
    setOpacityConfig((prevState) => {
      const newOpacityConfig = { ...prevState };

      Object.keys(newOpacityConfig).forEach((dataKey) => {
        newOpacityConfig[dataKey] = dataKey === focusedDataKey ? 1 : 0.1;
      });

      return newOpacityConfig;
    });
  };

  const handleMouseLeave = () => {
    setOpacityConfig((prevState) => {
      const newOpacityConfig = { ...prevState };

      Object.keys(newOpacityConfig).forEach((key) => {
        newOpacityConfig[key] = 1;
      });

      return newOpacityConfig;
    });
  };

  return { opacityConfig, handleMouseEnter, handleMouseLeave };
};
