import {
  Tag,
  TagColor,
  Glyph,
  TagVariant,
  Breakpoint,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";

export const OutboundPaymentBalanceBetaTag = () => {
  const isSmallBreakpointOrHigher = useIsBreakpoint({
    min: Breakpoint.Sm,
  });

  return (
    <Tag
      color={TagColor.Info}
      iconBefore={isSmallBreakpointOrHigher ? Glyph.Bulb : undefined}
      variant={TagVariant.Tinted}
      data-testid="beta-tag"
    >
      {isSmallBreakpointOrHigher ? (
        <Trans>Beta Access</Trans>
      ) : (
        <Trans>Beta</Trans>
      )}
    </Tag>
  );
};
