import {
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Dialog,
  H3,
  TypePreset,
} from "@gocardless/flux-react";
import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";

import { useFeedbackSteps } from "./hooks/useFeedbackSteps";
import { FEATURE_LIST, FeedbackFormData, FeedbackSteps } from "./utils";
import { FeedbackFormSteps } from "./FeedbackFormSteps";
import { WelcomeStep } from "./steps/WelcomeSteps/WelcomeStep";
import { useStepValidation } from "./hooks/useStepValidation";

import { ToTranslate } from "src/components/i18n";
import { getFormspreeApiUrl } from "src/utils/formspree";
import { useUser } from "src/queries/user";
import { useToastNotification } from "src/hooks/useToastNotification";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";

interface MultiStepFeedbackDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const MultiStepFeedbackDialog: React.FC<
  MultiStepFeedbackDialogProps
> = ({ open, handleClose }) => {
  const formMethods = useForm<FeedbackFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      keepPosted: "yes",
      dataInsights: "",
      features: FEATURE_LIST.map((featureText) => ({
        feature: featureText,
        rank: "",
      })),
      willingToPay: "yes",
      expectedPrice: "",
      maxPayablePrice: "",
      additionalFeedback: "",
    },
  });

  const { getValues, reset } = formMethods;
  const user = useUser();
  const [showFeedbackSteps, setShowFeedbackSteps] = useState(false);
  const { sendEvent } = useSegment();
  const { activeStep, goToNextStep, goToPrevStep, totalSteps, goToStep } =
    useFeedbackSteps();
  const { isStepValid } = useStepValidation(activeStep, formMethods);

  const isFirstStep = activeStep === FeedbackSteps.WelcomeStep;
  const isLastStep = activeStep === FeedbackSteps.AdditionalFeedback;

  const handleBack = () => {
    if (!showFeedbackSteps) {
      handleClose();
    } else if (isFirstStep) {
      setShowFeedbackSteps(false);
    } else {
      goToPrevStep();
    }
    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FEEDBACK_FORM_BACK_BUTTON_CLICKED
    );
  };

  const onFormClose = () => {
    handleClose();
    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FEEDBACK_FORM_CLOSE_BUTTON_CLICKED
    );
  };

  const getBackButtonText = () => {
    if (!showFeedbackSteps || isFirstStep) {
      return <Trans>Close</Trans>;
    }
    return <Trans>Back</Trans>;
  };

  const handleNext = async () => {
    const isValid = await isStepValid();

    if (!isValid) return;

    if (activeStep === FeedbackSteps.WelcomeStep) {
      setShowFeedbackSteps(true);
    }
    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FEEDBACK_FORM_NEXT_BUTTON_CLICKED
    );
    goToNextStep();
  };

  const { triggerSuccessNotification, triggerErrorNotification } =
    useToastNotification();

  const handleFormReset = () => {
    reset();
    goToStep(FeedbackSteps.WelcomeStep);
  };

  const onSubmit = async ({
    keepPosted,
    dataInsights,
    features,
    willingToPay,
    expectedPrice,
    maxPayablePrice,
    additionalFeedback,
  }: FeedbackFormData) => {
    try {
      const response = await fetch(getFormspreeApiUrl("reporting_experiment"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user?.id || "",
          _subject: "Reporting Experiment Feedback",
          user_agent: window.navigator.userAgent,
          keepPosted,
          dataInsights,
          features,
          willingToPay,
          expectedPrice,
          maxPayablePrice,
          additionalFeedback,
        }),
      });
      if (response.status !== 200) throw Error("Invalid response");

      sendEvent(
        TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FEEDBACK_FORM_SUBMITTED,
        {
          keepPosted,
          dataInsights: dataInsights ? "yes" : "no",
          features,
          willingToPay,
          expectedPrice,
          maxPayablePrice,
          additionalFeedback: additionalFeedback ? "yes" : "no",
        }
      );

      onFormClose();
      handleFormReset();
      triggerSuccessNotification({
        message: i18n._(
          t({
            id: "leave-feedback.feedback-success",
            message: "Thanks for your feedback",
          })
        ),
      });
    } catch {
      triggerErrorNotification({
        message: i18n._(
          t({
            id: "leave-feedback.error",
            message: "There was a problem, please try again.",
          })
        ),
      });
    }
  };

  const handleFormSubmit = () => {
    const {
      keepPosted,
      dataInsights,
      features,
      willingToPay,
      expectedPrice,
      maxPayablePrice,
      additionalFeedback,
    } = getValues();
    onSubmit({
      keepPosted,
      dataInsights,
      features,
      willingToPay,
      expectedPrice,
      maxPayablePrice,
      additionalFeedback,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog
        open={open}
        as="form"
        onSubmit={handleFormSubmit}
        aria-labelledby="multi-step-dialog"
        bodyGutterV={2}
        bodyGutterH={3}
        closeAction={{
          onClose: onFormClose,
          label: <Trans>Close</Trans>,
        }}
        header={
          <H3 preset={TypePreset.Heading_02}>
            <ToTranslate>Be part of creating something great</ToTranslate>
          </H3>
        }
        footer={
          <ButtonGroup arrangement={["column-center", null, "row-end-reverse"]}>
            {isLastStep ? (
              <Button
                layout={[ButtonLayout.Full, null, null, ButtonLayout.Inline]}
                size={ButtonSize.Md}
                variant={ButtonVariant.PrimaryOnLight}
                onClick={handleFormSubmit}
              >
                <Trans id="submit">Submit</Trans>
              </Button>
            ) : (
              <Button
                layout={[ButtonLayout.Full, null, null, ButtonLayout.Inline]}
                size={ButtonSize.Md}
                variant={ButtonVariant.PrimaryOnLight}
                onClick={handleNext}
              >
                <Trans>Next</Trans>
              </Button>
            )}
            <Button
              layout={[ButtonLayout.Full, null, null, ButtonLayout.Inline]}
              size={ButtonSize.Md}
              variant={ButtonVariant.TextOnLight}
              onClick={handleBack}
            >
              {getBackButtonText()}
            </Button>
          </ButtonGroup>
        }
      >
        {showFeedbackSteps ? (
          <FeedbackFormSteps activeStep={activeStep} totalSteps={totalSteps} />
        ) : (
          <WelcomeStep />
        )}
      </Dialog>
    </FormProvider>
  );
};
