import {
  Dialog,
  Text,
  TypePreset,
  FontWeight,
  Interpose,
  Space,
  ButtonGroup,
  PlainButton,
  Button,
  ButtonVariant,
  Box,
  ButtonSize,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { Route, routerPush } from "src/common/routing";
import CreatePaymentAnimation from "src/components/routes/SetupPayments/animation/CreatePaymentAnimation";

interface PaymentFromCustomerProps {
  open: boolean;
  toggleDialog: () => void;
}

const PaymentFromCustomerDialog: React.FC<PaymentFromCustomerProps> = ({
  open,
  toggleDialog,
}) => (
  <Dialog
    as="div"
    open={open}
    header={
      <Text preset={TypePreset.Heading_03} weight={FontWeight.SemiBold}>
        <Trans id="create-payment-from-customer-dialog.title">
          Create payments for existing customer
        </Trans>
      </Text>
    }
    footer={
      <ButtonGroup
        arrangement={["column-center-reverse", null, "row-end"]}
        spacing={[1, null, 2]}
      >
        <PlainButton
          weight={FontWeight.SemiBold}
          preset={TypePreset.Body_01}
          onClick={toggleDialog}
        >
          <Trans id="Back">Back</Trans>
        </PlainButton>
        <Button
          variant={ButtonVariant.PrimaryOnLight}
          size={ButtonSize.Md}
          onClick={() => routerPush({ route: Route.Customers })}
        >
          <Trans id="create-payment-from-customer-dialog.view-customers">
            View customers
          </Trans>
        </Button>
      </ButtonGroup>
    }
    closeAction={{ label: "Close", onClose: toggleDialog }}
  >
    <Interpose node={<Space v={1} />}>
      <Text preset={TypePreset.Body_02}>
        <Trans id="create-payment-from-customer-dialog.description">
          Schedule recurring or one-off payments for any customer with an active
          mandate. You’ll be able to see them on the customers page
        </Trans>
      </Text>
      <Box width="100%">
        <CreatePaymentAnimation />
      </Box>
    </Interpose>
  </Dialog>
);

export default PaymentFromCustomerDialog;
