import { CSSRulesFunction, Color } from "@gocardless/flux-react";

import { getRgbaColorWithOpacity } from "src/utils/colorHelpers";

export const headerBorderStyle: CSSRulesFunction = (theme) => {
  const borderColor = getRgbaColorWithOpacity(
    theme.color(Color.Greystone_1400),
    0.16
  );
  return [
    {
      border: `1px solid ${borderColor}`,
      borderWidth: "0 0 1px 0",
    },
  ];
};
