import { useRouter } from "next/router";

import { TrackingEvent } from "src/common/trackingEvents";
import { SharingMethod } from "src/common/trackingEventAttributes";
import { useSegment } from "src/technical-integrations/segment/useSegment";

export const useTrackShareEvent = (
  eventName: TrackingEvent
): {
  trackShareEvent: (
    sharingMethod: SharingMethod,
    extraAttributes?: object
  ) => Promise<void>;
} => {
  const router = useRouter();
  const { sendEventPromise } = useSegment();

  const trackShareEvent = (
    sharingMethod: SharingMethod,
    extraAttributes: object = {}
  ): Promise<void> =>
    sendEventPromise(eventName, {
      page: router?.pathname,
      sharing_method: sharingMethod,
      ...extraAttributes,
    });

  return {
    trackShareEvent,
  };
};
