import { useLingui } from "@lingui/react";
import {
  Box,
  Color,
  ColorPreset,
  FontWeight,
  H3,
  P,
  Shimmer,
  Space,
  Text,
  Tooltip,
} from "@gocardless/flux-react";
import { OutboundPaymentBalancesCurrency } from "@gocardless/api/dashboard/types";
import { round } from "lodash";

import { useReportingError } from "../../hooks/useReportingError";

import { OutboundDataWidgetTooltipLabel } from "./OutboundDataWidgetTooltipLabel";

import { formatCurrencyAndSplit } from "src/utils/formatCurrencyAndSplit";
import { useOutboundPaymentBalance } from "src/components/HomeOutboundPaymentBalance/useOutboundPaymentBalance";
import { OutboundPaymentBalanceCardTooltipCopy } from "src/components/HomeOutboundPaymentBalance/OutboundPaymentBalanceCardTooltipCopy";
import { OutboundPaymentBalanceCardTitle } from "src/components/HomeOutboundPaymentBalance/OutboundPaymentBalanceCardTitle";
import { OutboundPaymentBalanceType } from "src/components/HomeOutboundPaymentBalance/OutboundPaymentBalanceType";

export interface OutboundDataWidgetProps {
  type: OutboundPaymentBalanceType;
}

export const OutboundDataWidget: React.FC<OutboundDataWidgetProps> = ({
  type,
}) => {
  const selectedCurrency = OutboundPaymentBalancesCurrency.Gbp;

  const {
    data: outboundBalance,
    error,
    isLoading,
  } = useOutboundPaymentBalance(selectedCurrency);

  const { i18n } = useLingui();

  useReportingError(error, "outbound_payment_balances");

  const getFormattedMetricValue = () => {
    const rounded = (n: number | undefined) => round((n ?? 0) / 100, 2);

    let value = 0;
    switch (type) {
      case OutboundPaymentBalanceType.Available: {
        value = rounded(outboundBalance?.available_funds_in_cents);
        break;
      }
      case OutboundPaymentBalanceType.Scheduled: {
        value = rounded(outboundBalance?.scheduled_payments_in_cents);
        break;
      }
      case OutboundPaymentBalanceType.Current: {
        value = rounded(outboundBalance?.current_balance_in_cents);
        break;
      }
    }

    const [currencyValue, fraction] = formatCurrencyAndSplit({
      locale: i18n.locale,
      value: error ? 0 : value,
      separateBy: "decimal",
      options: {
        style: "decimal",
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    });

    return (
      <Text
        layout="block"
        size={7}
        weight={FontWeight.SemiBold}
        css={{ whiteSpace: "nowrap" }}
      >
        {currencyValue}
        <Text size={[5, 4]} lineHeight={5}>
          {fraction} {selectedCurrency}
        </Text>
      </Text>
    );
  };

  const formattedMetricValue = getFormattedMetricValue();

  return (
    <Box
      bg={Color.White}
      borderColor={[Color.White, ColorPreset.BorderOnLight_04]}
      borderRadius={[0, 1]}
      borderWidth={1}
      flexGrow={1}
      gutterH={[0, 1.5]}
      gutterV={[0, 1.5]}
    >
      <Box layout="flex">
        <H3 size={[4, 3]} spaceBelow={0.5}>
          <OutboundPaymentBalanceCardTitle type={type} />
        </H3>
        <Space layout="inline" h={0.5} />
        <Text>
          <Tooltip
            tooltipId={`home.${type}-tooltip`}
            message={
              <P>
                <OutboundPaymentBalanceCardTooltipCopy type={type} />
              </P>
            }
          >
            <OutboundDataWidgetTooltipLabel type={type} />
          </Tooltip>
        </Text>
      </Box>
      {isLoading ? (
        <Shimmer
          borderRadius={0.5}
          height="24px"
          spaceAbove={0.5}
          spaceBelow={1}
        />
      ) : (
        <>{formattedMetricValue}</>
      )}
    </Box>
  );
};
