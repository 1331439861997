import { PaymentStatusEnum } from "@gocardless/api/dashboard/types";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";

import { LEARN_MORE_BASE_URL } from "../../utils/common";

import { Route, RouteURLParams } from "src/common/routing";
import { TrackingEvent } from "src/common/trackingEvents";
import { PaymentsFilterType } from "src/components/routes/Payments/models";

export const collectionDataWidgetTypes = {
  activeCustomers: "active_customers",
  confirmedFunds: "confirmed_funds",
  failedPayments: "failed_payments",
  pendingPayments: "pending_payments",
  pendingPayouts: "pending_payouts",
} as const;

export type CollectionDataWidgetType =
  (typeof collectionDataWidgetTypes)[keyof typeof collectionDataWidgetTypes];

export const useCollectionDataWidgetTypesMap: (i18n: I18n) => Record<
  CollectionDataWidgetType,
  {
    buttonText?: string;
    buttonURLParams?: RouteURLParams;
    name: string;
    tooltipDescription: string;
    tooltipId: string;
    tooltipLabel: string;
    tooltipLink?: string;
  }
> = (i18n) => ({
  active_customers: {
    name: i18n._(
      t({
        message: "Active customers",
      })
    ),
    tooltipId: "activeCustomers",
    tooltipLabel: i18n._(
      t({
        message: "About Active customers",
      })
    ),
    tooltipDescription: i18n._(
      t({
        message: "Total number of customers who have an active mandate",
      })
    ),
    tooltipLink: LEARN_MORE_BASE_URL,
    buttonText: i18n._(
      t({
        message: "View customers",
      })
    ),
    buttonURLParams: { route: Route.Customers },
  },
  confirmed_funds: {
    name: i18n._(
      t({
        message: "Confirmed funds",
      })
    ),
    tooltipId: "confirmedFunds",
    tooltipLabel: i18n._(
      t({
        message: "About Confirmed funds",
      })
    ),
    tooltipDescription: i18n._(
      t({
        message:
          "Collected payments that have been confirmed, minus fees and unclaimed debits for refunds, failures, and chargebacks. This balance can be negative, as payment failures and chargebacks may happen after funds have been paid out.",
      })
    ),
    tooltipLink: LEARN_MORE_BASE_URL,
    buttonText: i18n._(
      t({
        message: "View confirmed payments",
      })
    ),
    buttonURLParams: {
      route: Route.Payments,
      queryParams: {
        status: PaymentStatusEnum.Confirmed,
      },
    },
  },
  failed_payments: {
    name: i18n._(
      t({
        message: "Failed payments",
      })
    ),
    tooltipId: "failedPayments",
    tooltipLabel: i18n._(
      t({
        message: "About Failed payments",
      })
    ),
    tooltipDescription: i18n._(
      t({
        message:
          "How many payments have failed, out of the total you’ve attempted to collect since the previous midday",
      })
    ),
    tooltipLink: LEARN_MORE_BASE_URL,
    buttonText: i18n._(
      t({
        message: "View failed payments",
      })
    ),
    buttonURLParams: {
      route: Route.Payments,
      queryParams: {
        date_type: PaymentsFilterType.Created,
        status: PaymentStatusEnum.Failed,
      },
    },
  },
  pending_payments: {
    name: i18n._(
      t({
        message: "Pending payments",
      })
    ),
    tooltipId: "pendingPayments",
    tooltipLabel: i18n._(
      t({
        message: "About Pending payments",
      })
    ),
    tooltipDescription: i18n._(
      t({
        message: "Total in the process of being collected from your customers",
      })
    ),
    tooltipLink: LEARN_MORE_BASE_URL,
    buttonText: i18n._(
      t({
        message: "View all payments",
      })
    ),
    buttonURLParams: {
      route: Route.Payments,
    },
  },
  pending_payouts: {
    name: i18n._(
      t({
        message: "Pending payouts",
      })
    ),
    tooltipId: "pendingPayouts",
    tooltipLabel: i18n._(
      t({
        message: "About Pending payouts",
      })
    ),
    tooltipDescription: i18n._(
      t({
        message:
          "Total due to be paid into your bank account next (fees have already been deducted)",
      })
    ),
    tooltipLink: LEARN_MORE_BASE_URL,
    buttonText: i18n._(
      t({
        message: "View payouts",
      })
    ),
    buttonURLParams: { route: Route.Payouts },
  },
});

export const collectionSectionDataWidgetTypes: CollectionDataWidgetType[] = [
  "pending_payments",
  "confirmed_funds",
  "pending_payouts",
];

export const accountHealthSectionDataWidgetTypes: CollectionDataWidgetType[] = [
  "active_customers",
  "failed_payments",
];

export const getDataWidgetButtonEvent = (type: CollectionDataWidgetType) => {
  switch (type) {
    case "active_customers":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_VIEW_CUSTOMERS_BUTTON_CLICKED;

    case "confirmed_funds":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_VIEW_ALL_COLLECTED_PAYMENTS_BUTTON_CLICKED;

    case "failed_payments":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_VIEW_FAILED_PAYMENTS_BUTTON_CLICKED;

    case "pending_payments":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_VIEW_ALL_PENDING_PAYMENTS_BUTTON_CLICKED;

    case "pending_payouts":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_VIEW_PAYOUTS_BUTTON_CLICKED;
  }
};

export const getDataWidgetTooltipEvent = (type: CollectionDataWidgetType) => {
  switch (type) {
    case "active_customers":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_ACTIVE_CUSTOMERS_TOOLTIP_CLICKED;

    case "confirmed_funds":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_COLLECTED_PAYMENTS_DATA_WIDGET_TOOLTIP_CLICKED;

    case "failed_payments":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FAILED_PAYMENTS_TOOLTIP_CLICKED;

    case "pending_payments":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_TOTAL_PENDING_PAYMENTS_TOOLTIP_CLICKED;

    case "pending_payouts":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_TOTAL_PENDING_PAYOUTS_TOOLTIP_CLICKED;
  }
};
