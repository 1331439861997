import {
  Box,
  Text,
  TypePreset,
  TextArea,
  Label,
  Space,
  Field,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";

import { TrackingEvent } from "src/common/trackingEvents";
import { ToTranslate } from "src/components/i18n";
import { useSendPageViewEvent } from "src/technical-integrations/segment/useSendPageViewEvent";

export const AdditionalFeedbackStep: React.FC = () => {
  const { register } = useFormContext();

  useSendPageViewEvent(
    TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FEEDBACK_FORM_ADDITIONAL_STEP_VIEWED
  );

  return (
    <Box width="100%">
      <Field>
        <Label htmlFor="additional_feedback">
          <Text preset={TypePreset.Heading_01}>
            <ToTranslate>
              Thank you! Is there anything else you&apos;d like us to consider?
            </ToTranslate>
          </Text>
        </Label>
        <Space v={0.5} />
        <Box>
          <TextArea
            id="additional_feedback"
            {...register("additionalFeedback")}
            placeholder="Add description here"
            rows={5}
          />
        </Box>
      </Field>
    </Box>
  );
};
