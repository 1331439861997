import {
  ButtonSize,
  Color,
  MoneyText,
  Banner,
  BannerVariant,
  MoneyTextVariant,
  BannerLeftAccessoryType,
} from "@gocardless/flux-react";
import { useRouter } from "next/router";
import { forwardRef, useCallback } from "react";

import { TrackingEvent } from "src/common/trackingEvents";
import { ToTranslate } from "src/components/i18n";
import {
  ContentName,
  DismissibleContent,
} from "src/components/layout/DismissibleContent";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import {
  useOrganisation,
  usePipeStatus,
} from "src/libraries/organisation/hooks";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useUserPermissions } from "src/common/hooks/user-permissions/useUserPermissions";
import { Route, getRouteURL, routerPush } from "src/common/routing";

const bannerName = "Embedded Pipe promotion";

export const PipeBanner = forwardRef<HTMLDivElement, {}>((_props, ref) => {
  const organisation = useOrganisation();
  const { sendEvent } = useSegment();
  const router = useRouter();
  const userPermissions = useUserPermissions();
  const isAdmin = !!userPermissions.isAdmin;
  const { isVariationOn: isInPipeEmbedExperiment } = useOptimizelyVariation({
    flag: OptimizelyFlag.ASAP_PAYMENTS_PIPE_EMBED,
  });

  const triggerBannerViewedEvent = useCallback(() => {
    sendEvent(TrackingEvent.BANNER_VIEWED, {
      page: router.pathname,
      name: bannerName,
    });
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  const { pipeStatus } = usePipeStatus({
    shouldMakeRequest: isInPipeEmbedExperiment && isAdmin,
  });
  const shouldShowBanner =
    !!pipeStatus?.pre_approved &&
    !pipeStatus?.active_advance && // do not show the banner if already active
    !!((pipeStatus.available_amount_in_cents ?? 0) > 0); //  or if the user spent it all

  if (!shouldShowBanner) {
    return null;
  }

  const amount = pipeStatus?.maximum_amount_in_cents;
  const triggerBannerDismissedEvent = () => {
    sendEvent(TrackingEvent.BANNER_DISMISSED, {
      page: router.pathname,
      name: bannerName,
    });
  };
  const triggerBannerPrimaryCtaClicked = () => {
    sendEvent(TrackingEvent.BANNER_PRIMARY_CTA_CLICKED, {
      page: router.pathname,
      name: bannerName,
      destination: getRouteURL({ route: Route.Capital }),
    });
  };
  const handleBannerDismiss = (dismiss: () => void) => {
    triggerBannerDismissedEvent();
    dismiss();
  };

  return (
    <DismissibleContent
      ref={ref}
      name={
        isInPipeEmbedExperiment
          ? ContentName.EmbeddedPipeBannerDismissed
          : ContentName.PipeBannerDismissed
      }
      onContentMount={triggerBannerViewedEvent}
      renderContent={(dismiss: () => void) => (
        <Banner
          elevation={0}
          variant={BannerVariant.Light}
          backgroundColor={Color.Ocean_100}
          title={
            <ToTranslate>
              <>Get up to&nbsp;</>
              <MoneyText
                format="short"
                amount={(amount ?? 0) / 100}
                currency={organisation?.domestic_currency ?? ""}
                locale="en"
                variant={MoneyTextVariant.Flat}
              />
              <>&nbsp;capital powered by Pipe</>
            </ToTranslate>
          }
          leftAccessory={{
            type: BannerLeftAccessoryType.Tag,
            text: "NEW",
          }}
          primaryAction={{
            size: ButtonSize.Sm,
            control: "button",
            onClick: () => {
              ButtonSize.Sm, triggerBannerPrimaryCtaClicked();
              routerPush({
                route: Route.Capital,
              });
            },
            children: "Check my eligibility",
          }}
          closeAction={{
            onClose: () => handleBannerDismiss(dismiss),
            label: "Close",
          }}
        >
          <ToTranslate>
            Upgrade your equipment, expand marketing campaigns or strengthen
            your cash flow.
          </ToTranslate>
        </Banner>
      )}
    />
  );
});
