import {
  Box,
  ColorPreset,
  JustifyContent,
  Space,
} from "@gocardless/flux-react";

import { useExperimentChartWidgetMetric } from "../hooks/useExperimentChartWidgetMetric";
import { ExperimentChartWidgetType } from "../utils";
import { useExperimentChartWidgetTypesMap } from "../hooks/useExperimentChartWidgetTypesMap";
import { MultiStepFeedbackDialog } from "../../MultiStepFeedbackForm/MultiStepFeedbackDialog";

import { Currency } from "src/common/currencies";
import { useOrganisation } from "src/libraries/organisation/hooks";
import useToggle from "src/hooks/useToggle";

interface ExperimentChartWidgetProps {
  type: ExperimentChartWidgetType;
}

export const ExperimentChartWidget: React.FC<ExperimentChartWidgetProps> = ({
  type,
}) => {
  const {
    isOn: isFeedbackDialogOpen,
    off: closeFeedbackDialog,
    on: openFeedbackDialog,
  } = useToggle(false);

  const currency = (useOrganisation()?.domestic_currency ??
    Currency.Gbp) as Currency;

  const { data } = useExperimentChartWidgetMetric({
    type,
    currency,
  });

  const { chart, header, footer } = useExperimentChartWidgetTypesMap({
    currency,
    data,
    onOpenFeedbackDialog: openFeedbackDialog,
  })[type];

  return (
    <>
      <Box
        bg={ColorPreset.BackgroundLight_01}
        height="100%"
        width="100%"
        gutterH={2}
        gutterV={2}
        borderRadius={1}
        borderWidth={1}
        borderColor={ColorPreset.BorderOnLight_04}
        layout="flex"
        flexDirection="column"
        justifyContent={JustifyContent.SpaceBetween}
      >
        {header}

        <Space v={1.5} layout="inline" />

        {chart}

        <Space v={1} layout="inline" />

        {footer}
      </Box>
      <MultiStepFeedbackDialog
        open={isFeedbackDialogOpen}
        handleClose={closeFeedbackDialog}
      />
    </>
  );
};
