import {
  AlignItems,
  ColorPreset,
  CSSRulesFunction,
} from "@gocardless/flux-react";

export const radioStyle: CSSRulesFunction<{ isSelected: boolean }> = (
  theme,
  { isSelected }
) => ({
  borderRadius: theme.spacing(1),
  borderColor: isSelected
    ? theme.color(ColorPreset.BorderOnLight_02)
    : theme.color(ColorPreset.BorderOnLight_05),
  "label > div": {
    alignItems: AlignItems.Center,
    display: "flex",
  },
});

export const noOptionStyle: CSSRulesFunction = (theme) => ({
  marginTop: theme.spacing(0.75),
});
