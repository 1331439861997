import {
  Box,
  Button,
  ButtonVariant,
  Color,
  Glyph,
  Icon,
  Link,
  LinkProps,
  useTheme,
} from "@gocardless/flux-react";
import { useCallback } from "react";
import { Trans } from "@lingui/macro";

import { useShareWithEvents } from "../../../hooks/useShareWithEvents";

import { TrackingEvent } from "src/common/trackingEvents";
import { ShareWrapper } from "src/components/payment-sharing/ShareWrapper";
import { useGetDefaultCustomerInviteLink } from "src/common/hooks/useGetDefaultCustomerInviteLink";

export const CustomerLink: React.FC<LinkProps> = (props) => {
  const { theme } = useTheme();

  const onCopyCustomerLink = useShareWithEvents({
    event: TrackingEvent.CUSTOMERS_SHARE_COMPLETED,
    dedupedEvent: TrackingEvent.CUSTOMERS_SHARE_COMPLETED_DEDUPED,
  });

  const { link: defaultCustomerInviteLink, isLoading } =
    useGetDefaultCustomerInviteLink();

  const onClick = useCallback(
    () => onCopyCustomerLink(defaultCustomerInviteLink),
    [onCopyCustomerLink, defaultCustomerInviteLink]
  );

  return (
    <Box layout="flex">
      <ShareWrapper onClick={onClick}>
        {(triggerProps) => (
          <Button
            {...triggerProps}
            variant={ButtonVariant.PrimaryOnLight}
            css={{
              padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,

              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: `solid 1px ${theme.color(Color.White)}`,
            }}
            disabled={!defaultCustomerInviteLink}
          >
            <Icon size="12px" name={isLoading ? Glyph.Spinner : Glyph.Link} />
          </Button>
        )}
      </ShareWrapper>
      <Link
        {...props}
        css={{
          padding: `${theme.spacing(0.5)} ${theme.spacing(2)} ${theme.spacing(
            0.5
          )} ${theme.spacing(1.5)}`,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Trans>Invite</Trans>
      </Link>
    </Box>
  );
};
