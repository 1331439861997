import {
  Box,
  AlignItems,
  JustifyContent,
  TypographyStyleProps,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { OutboundPaymentBalanceBetaTag } from "./OutboundPaymentBalanceBetaTag";

import { HomeSectionHeader } from "src/components/HomeSectionHeader/HomeSectionHeader";

export const HomeOutboundAccountHeader: React.FC<TypographyStyleProps> = (
  props
) => (
  <Box
    layout="flex"
    alignItems={AlignItems.Center}
    justifyContent={JustifyContent.SpaceBetween}
  >
    <HomeSectionHeader {...props}>
      <Trans>Outbound account</Trans>
    </HomeSectionHeader>
    <OutboundPaymentBalanceBetaTag />
  </Box>
);
