import {
  AlignItems,
  Box,
  ButtonVariant,
  Breakpoint,
  FontWeight,
  Glyph,
  Icon,
  JustifyContent,
  IconButton,
  PlainButton,
  Space,
  Text,
  TypePreset,
  HoverEffect,
} from "@gocardless/flux-react";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";

import { Route } from "src/components/routing";
import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";
import { routerPush } from "src/common/routing";

export interface FlowPageHeaderProps {
  title?: React.ReactNode;
  showExitButton?: boolean;
  showBackButton?: boolean;
  onBackClick?: () => void;
  onExitClick?: () => void;
}

// Part of UXI accessible layout project.
// This will gradually replace the existing FlowPageHeader with a version that's included in the new accessible layout.
export const FlowPageHeader: React.FC<FlowPageHeaderProps> = ({
  title,
  showExitButton = true,
  showBackButton = true,
  onExitClick,
  onBackClick,
}) => {
  const router = useRouter();
  const defaultOnBackClick = () => {
    router.back();
  };
  const defaultOnExitClick = () => {
    routerPush({ route: Route.Home });
  };

  const isSmallBreakpointOrHigher = useIsBreakpoint({ min: Breakpoint.Sm });
  const handleBackClick = onBackClick ?? defaultOnBackClick;
  const handleExitClick = onExitClick ?? defaultOnExitClick;

  const BackButton = () =>
    isSmallBreakpointOrHigher ? (
      <PlainButton
        onClick={handleBackClick}
        effect={HoverEffect.TextDecoration}
      >
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Icon name={Glyph.ChevronLeft} size="12px" />
          <Space layout="inline" h={0.5} />
          <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            {title || <Trans id="Back">Back</Trans>}
          </Text>
        </Box>
      </PlainButton>
    ) : (
      <IconButton
        shift="left"
        variant={ButtonVariant.TextOnLight}
        onClick={handleBackClick}
        label={<Trans id="Back">Back</Trans>}
        icon={Glyph.ChevronLeft}
      />
    );

  const ExitButton = () =>
    isSmallBreakpointOrHigher ? (
      <PlainButton
        onClick={handleExitClick}
        effect={HoverEffect.TextDecoration}
      >
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Icon name={Glyph.Close} size="12px" />
          <Space layout="inline" h={0.5} />
          <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            <Trans id="Exit">Exit</Trans>
          </Text>
        </Box>
      </PlainButton>
    ) : (
      <IconButton
        shift="right"
        variant={ButtonVariant.TextOnLight}
        onClick={handleExitClick}
        label={<Trans id="Exit">Exit</Trans>}
        icon={Glyph.Close}
      />
    );

  return (
    <Box
      layout="flex"
      alignItems={AlignItems.Center}
      justifyContent={
        !showBackButton && showExitButton
          ? JustifyContent.FlexEnd
          : JustifyContent.SpaceBetween
      }
    >
      {showBackButton && <BackButton />}
      {showExitButton && <ExitButton />}
    </Box>
  );
};
