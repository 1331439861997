import { getReportingExperimentName } from "../utils";

import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";

export const useReportingExperiment = () => {
  const { isVariationOn: isMerchantEngagementUpsellIbpEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.MERCHANT_ENGAGEMENT_UPSELL_IBP,
    });

  const { isVariationOn: isMerchantEngagementUpsellFasterAchEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.MERCHANT_ENGAGEMENT_UPSELL_FASTER_ACH,
    });

  const { isVariationOn: isMerchantEngagementFutureDataEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.MERCHANT_ENGAGEMENT_FUTURE_DATA,
    });

  const activeExperimentName = getReportingExperimentName({
    isFutureDataExperimentEnabled: isMerchantEngagementFutureDataEnabled,
    isUpsellFasterAchExperimentEnabled:
      isMerchantEngagementUpsellFasterAchEnabled,
    isUpsellIbpExperimentEnabled: isMerchantEngagementUpsellIbpEnabled,
  });

  return {
    isUpsellExperimentEnabled:
      isMerchantEngagementUpsellIbpEnabled ||
      isMerchantEngagementUpsellFasterAchEnabled,
    isUpsellIbpExperimentEnabled: isMerchantEngagementUpsellIbpEnabled,
    isUpsellFasterAchExperimentEnabled:
      isMerchantEngagementUpsellFasterAchEnabled,
    isFutureDataExperimentEnabled: isMerchantEngagementFutureDataEnabled,
    activeExperimentName,
  };
};
