import { FC } from "react";
import {
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Dialog,
  DialogProps,
  FontWeight,
  P,
  Space,
  Text,
} from "@gocardless/flux-react";
import Image from "next/image";

import { ToTranslate } from "src/components/i18n";
import BookSessionDialogImage from "src/assets/png/setup-payments/home/keep-collecting-confidently/book-session-dialog@2x.png";
import { useNeedHelpCardUrls } from "src/components/routes/SetupPayments/home/NeedHelp/useNeedHelpCardUrls";

interface BookSessionDialog extends Pick<DialogProps, "open"> {
  onClose: () => void;
}

export const BookSessionDialog: FC<BookSessionDialog> = ({ open, onClose }) => {
  const { meetingURL } = useNeedHelpCardUrls();

  return (
    <Dialog
      as="div"
      open={open}
      header={{
        content: (
          <Text weight={FontWeight.SemiBold}>
            <ToTranslate>
              Book a one-to-one session with our onboarding experts
            </ToTranslate>
          </Text>
        ),
      }}
      footer={{
        content: (
          <ButtonGroup arrangement={["column-center", null, "row-end-reverse"]}>
            <Button
              variant={ButtonVariant.PrimaryOnLight}
              size={ButtonSize.Sm}
              layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
              onClick={() => window.open(meetingURL)}
            >
              Book now
            </Button>
            <Button
              variant={ButtonVariant.TextOnLight}
              size={ButtonSize.Sm}
              layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
              onClick={onClose}
            >
              Back
            </Button>
          </ButtonGroup>
        ),
      }}
      closeAction={{
        label: "Close",
        onClose,
      }}
    >
      <Image
        src={BookSessionDialogImage}
        alt=""
        width={504}
        height={246}
        css={{ width: "100%", height: "auto" }}
      />

      <Space v={1} />

      <P>
        <ToTranslate>
          Our onboarding experts are available to get you fully up and running
          with GoCardless or answer any questions you might have. Simply click
          &apos;book now&apos; to choose a convenient time-slot for a free 1-2-1
          call with one of our experts.
        </ToTranslate>
      </P>
    </Dialog>
  );
};
