import { HoverEffect, PlainLink } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { dateIsInTheFuture, dateIsToday, dateOptions } from "./utils";

import { LocaleDateString } from "src/components/date";
import { LinkBuilder, Route } from "src/components/routing";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";

export const PayoutTimingsText: React.FC<{
  hasUpcomingPayments?: boolean;
  amount?: number;
  payoutDate?: string;
  payoutId?: string;
}> = ({ hasUpcomingPayments, amount, payoutDate, payoutId }) => {
  const { sendEvent } = useSegment();
  if (!amount || !payoutDate || !payoutId) {
    return <Trans id="home.nothing-to-pay-out">Nothing to pay out</Trans>;
  }

  if (hasUpcomingPayments) {
    return <Trans id="home.upcoming-payout">Upcoming payouts</Trans>;
  }

  const isCurrentOrFutureDate =
    dateIsToday(payoutDate) || dateIsInTheFuture(payoutDate);

  const payoutText = isCurrentOrFutureDate ? (
    <Trans id="home.next-payout">Next payout</Trans>
  ) : (
    <Trans id="home.last-payout">Last payout</Trans>
  );

  const sendSegmentEvent = () => {
    const trackingEvent = isCurrentOrFutureDate
      ? TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_NEXT_PAYOUT_CTA_CLICKED
      : TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_LAST_PAYOUT_CTA_CLICKED;
    sendEvent(trackingEvent);
  };

  return (
    <>
      <LinkBuilder
        route={Route.Payout}
        routeParams={{ id: payoutId }}
        onClick={sendSegmentEvent}
      >
        {(results) => (
          <PlainLink
            {...results}
            textDecoration="underline"
            effect={HoverEffect.TextDecoration}
          >
            {payoutText}
          </PlainLink>
        )}
      </LinkBuilder>{" "}
      (<LocaleDateString date={payoutDate} options={dateOptions} />)
    </>
  );
};
