import {
  Dialog,
  P,
  Color,
  Interpose,
  Space,
  TypePreset,
  Text,
  FontWeight,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Trans } from "@lingui/macro";

import { DialogBodyWrapper } from "../components/DialogBodyWrapper";
import { withChildrenTarget } from "../components/withChildrenTarget";
import { DialogCTA, DialogCTAProps } from "../components/DialogCTA";

import SubscriptionTemplatesSVG from "src/assets/svg/suggested-actions-dialog-subscription-templates.svg";
import { Route } from "src/common/routing";

export const TrySubscriptionTemplatesBodyWrapper = () => (
  <DialogBodyWrapper
    bg={Color.Dusk_200}
    image={
      <Image
        src={SubscriptionTemplatesSVG}
        alt="subscription-template"
        width={96}
        height={96}
      />
    }
    title={<Trans>Try Subscription templates</Trans>}
  >
    <Interpose node={<Space v={1} />}>
      <P preset={TypePreset.Body_01}>
        <Trans>
          Subscription templates provide a way to set up multiple customers on
          the same recurring subscription, with no need to create and manage
          them individually.
        </Trans>
      </P>

      <P preset={TypePreset.Body_01}>
        <Trans>
          Once created, you can add{" "}
          <Text weight={FontWeight.SemiBold}>new</Text> or{" "}
          <Text weight={FontWeight.SemiBold}>existing customers</Text> to the
          templates directly.
        </Trans>
      </P>
    </Interpose>
  </DialogBodyWrapper>
);

export const TrySubscriptionTemplatesFooter = ({
  name,
}: Pick<DialogCTAProps, "name">) => (
  <DialogCTA route={Route.SubscriptionTemplatesCreate} name={name} />
);

export const TrySubscriptionTemplates = withChildrenTarget(({ ...props }) => (
  <>
    <Dialog
      {...props}
      footer={<TrySubscriptionTemplatesFooter name={props.id} />}
    >
      <TrySubscriptionTemplatesBodyWrapper />
    </Dialog>
  </>
));
