import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  Interpose,
  JustifyContent,
  Separator,
  XYGrid,
  useTheme,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import { CollectionDataWidget } from "../CollectionDataWidget/CollectionDataWidget";
import { collectionSectionDataWidgetTypes } from "../CollectionDataWidget/utils";
import {
  mobileSeparatorStyle,
  betaWidgetContainerMediumBreakpointStyle,
} from "../../utils/style";
import { useCollectionCurrencies } from "../../hooks/useCollectionCurrencies";
import CollectionCurrencyMenu from "../CollectionCurrencyMenu/CollectionCurrencyMenu";
import BetaWidget from "../BetaWidget/BetaWidget";
import { useReportingExperiment } from "../experiments/hooks/useReportingExperiment";

import { HomeSectionHeader } from "src/components/HomeSectionHeader/HomeSectionHeader";
import { useOrganisation } from "src/libraries/organisation/hooks";
import { HomeCollectionOverviewHeader } from "src/components/HomeCollectionOverviewHeader";

export const CollectionSection = () => {
  const {
    fx_payout_currency: fxPayoutCurrency,
    outbound_payments_enabled: outboundPaymentsEnabled,
  } = useOrganisation() ?? {};

  const { collectionCurrencies } = useCollectionCurrencies();

  const { isFutureDataExperimentEnabled } = useReportingExperiment();

  const { theme } = useTheme();

  const showCurrencySelector =
    collectionCurrencies?.length > 1 && !isFutureDataExperimentEnabled;

  return (
    <Box gridArea="collection">
      <Box
        layout="flex"
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.SpaceBetween}
        spaceBelow={2}
      >
        {outboundPaymentsEnabled ? (
          <HomeCollectionOverviewHeader />
        ) : (
          <HomeSectionHeader>
            <Trans>Today</Trans>
          </HomeSectionHeader>
        )}

        <Box layout="flex" alignItems={AlignItems.Center}>
          {showCurrencySelector ? (
            <CollectionCurrencyMenu
              currencies={collectionCurrencies}
              fxPayoutCurrency={fxPayoutCurrency}
            />
          ) : null}
        </Box>
      </Box>

      <Box
        bg={[Color.White, Color.Brownstone_50]}
        borderColor={[ColorPreset.BorderOnLight_04, Color.White]}
        borderRadius={[1, 0]}
        borderWidth={[1, 0]}
        gutterH={[1.5, 0]}
        gutterV={[1.5, 0]}
      >
        <XYGrid
          columnGap={[0, 1.5, 2]}
          rowGap={[0, 1.5, 2]}
          templateColumns={["1fr", "repeat(2, 1fr)", null, "repeat(3, 1fr)"]}
        >
          <Interpose
            node={
              <Separator align="center" css={mobileSeparatorStyle(theme)} />
            }
            trailing
          >
            {collectionSectionDataWidgetTypes.map((dataWidgetType) => (
              <CollectionDataWidget
                key={dataWidgetType}
                type={dataWidgetType}
              />
            ))}
          </Interpose>

          <Box css={betaWidgetContainerMediumBreakpointStyle(theme)}>
            <BetaWidget />
          </Box>
        </XYGrid>
      </Box>
    </Box>
  );
};
