import { UseFormReturn } from "react-hook-form";

import { FeedbackFormData, FeedbackStep, FeedbackSteps } from "../utils";

export const useStepValidation = (
  currentStep: FeedbackStep,
  methods: UseFormReturn<FeedbackFormData>
) => {
  const willingToPay = methods.watch("willingToPay");

  const getFieldsToValidate = (
    step: FeedbackStep
  ): Array<keyof FeedbackFormData> => {
    switch (step) {
      case FeedbackSteps.WelcomeStep:
        return ["keepPosted"];
      case FeedbackSteps.FeatureRanking:
        return ["features"];
      case FeedbackSteps.DataInsights:
        return []; // Optional step
      case FeedbackSteps.PricingFeedback:
        return willingToPay === "yes"
          ? ["willingToPay", "expectedPrice", "maxPayablePrice"]
          : ["willingToPay"];
      case FeedbackSteps.AdditionalFeedback:
        return []; // Optional step
      default:
        return [];
    }
  };

  const isStepValid = async () => {
    const fields = getFieldsToValidate(currentStep);

    const hasEmptyRequiredField = fields.some((field) => {
      const value = methods.getValues(field);
      return (
        field !== "additionalFeedback" &&
        (!value || (Array.isArray(value) && value.length === 0))
      );
    });

    // Special handling for PricingFeedback step
    if (currentStep === FeedbackSteps.PricingFeedback) {
      // If they selected "no", we're done
      if (willingToPay === "no") return true;

      const isWillingToPayValid = await methods.trigger("willingToPay");
      if (!isWillingToPayValid) return false;

      // If they selected "yes", validate price fields
      if (willingToPay === "yes") {
        const isPriceFieldsValid = await methods.trigger([
          "expectedPrice",
          "maxPayablePrice",
        ]);
        return isPriceFieldsValid;
      }
    }

    if (hasEmptyRequiredField) {
      return false;
    }

    return await methods.trigger(fields);
  };

  const hasStepErrors = () => {
    const fields = getFieldsToValidate(currentStep);
    return fields.some((field) => methods.formState.errors[field]);
  };

  return {
    isStepValid,
    hasStepErrors,
  };
};
