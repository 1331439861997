import {
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Dialog,
  PlainLink,
  Space,
  LI,
  P,
  H2,
  Link,
  UL,
  FontWeight,
  Visibility,
  TypePreset,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Trans } from "@lingui/macro";
import { useEffect } from "react";

import { ExperimentNames } from "../utils";

import FasterACHImage from "src/assets/svg/faster-ACH-timeline.svg";
import FasterACHMobileImage from "src/assets/svg/faster-ACH-timeline-mobile.svg";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";

interface FasterACHEducationDialogProp {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const trackingContext = "Faster ACH education dialog";

export const FasterACHEducationDialog: React.FC<
  FasterACHEducationDialogProp
> = ({ open, onClose, onSubmit }) => {
  const { sendEvent } = useSegment();

  useEffect(() => {
    if (open) {
      sendEvent(
        TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FASTER_ACH_DIALOG_VIEWED,
        {
          ctx: trackingContext,
          experiment_name: ExperimentNames.UpsellFasterACH,
        }
      );
    }
  }, [open, sendEvent]);

  return (
    <Dialog
      open={open}
      bodyGutterV={2}
      aria-labelledby="fasterACHEducationDialogTitle"
      closeAction={{
        label: <Trans id="Close">Close</Trans>,
        onClose: () => {
          onClose();
          sendEvent(TrackingEvent.MERCHANT_DASHBOARD_REPORTING_DIALOG_CLOSED, {
            ctx: trackingContext,
            experiment_name: ExperimentNames.UpsellFasterACH,
          });
        },
      }}
      header={
        <H2 preset={TypePreset.Heading_02} id="fasterACHEducationDialogTitle">
          Speed up your payments with Faster ACH
        </H2>
      }
      footer={
        <ButtonGroup arrangement={["column-center", "row-end-reverse"]}>
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            size={[ButtonSize.Lg, ButtonSize.Sm]}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            onClick={() => {
              onSubmit();
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_CONTINUE_CTA_CLICKED,
                {
                  ctx: trackingContext,
                  experiment_name: ExperimentNames.UpsellFasterACH,
                }
              );
            }}
          >
            Continue
          </Button>
          <Link
            href="https://gocardless.com/en-us/features/ach-payment-timings/"
            target="_blank"
            rel="noopener"
            size={[ButtonSize.Lg, ButtonSize.Sm]}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            variant={ButtonVariant.TextOnLight}
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LEARN_MORE_BUTTON_CLICKED,
                {
                  ctx: trackingContext,
                  experiment_name: ExperimentNames.UpsellFasterACH,
                }
              );
            }}
          >
            Learn more
          </Link>
        </ButtonGroup>
      }
    >
      <Visibility visible={["none", "block"]}>
        <Image
          src={FasterACHImage}
          alt=""
          width={0}
          height={0}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </Visibility>
      <Visibility visible={["block", "none"]}>
        <Image
          src={FasterACHMobileImage}
          alt=""
          width={0}
          height={0}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </Visibility>
      <Space v={2} />
      <P size={2}>
        Faster ACH reduces the typical 4-5 days required for direct debit
        payments to be credited to your bank account.
      </P>
      <Space v={1} />
      <P size={2} weight={FontWeight.Bold}>
        Receive funds up to 2 days sooner by:
      </P>
      <UL size={2}>
        <LI>Charging customers on the same day you create a payment</LI>
        <LI>
          Receiving payouts one day sooner by benefitting from ACH&apos;s Same
          Day payment rail
        </LI>
      </UL>
      <Space v={1} />
      <P size={2}>
        Faster ACH is included in our advanced plan. Upgrading will give you
        access to the feature by default - you don&apos;t need to do anything!
      </P>
      <Space v={1} />
      <P size={2}>
        Further questions?{" "}
        <PlainLink
          href="https://support.gocardless.com/hc/en-gb/requests/new"
          decoration="underline"
          target="_blank"
          rel="noopener"
          onClick={() => {
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_REPORTING_CONTACT_SUPPORT_CTA_CLICKED,
              {
                ctx: trackingContext,
                experiment_name: ExperimentNames.UpsellFasterACH,
              }
            );
          }}
        >
          Contact support
        </PlainLink>
      </P>
    </Dialog>
  );
};
