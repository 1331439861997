import { useOrganisationRequestPackage } from "@gocardless/api/dashboard/organisation";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useRouter } from "next/router";
import { useCreditorDetailSelf } from "@gocardless/api/dashboard/creditor-detail";
import { Trans } from "@lingui/macro";
import { Dispatch, SetStateAction, useState } from "react";
import { UsersScope } from "@gocardless/api/dashboard/types";

import useToast from "../../../common/hooks/useToast";
import { PricingPackagesV3 } from "../packages/package/usePackageStateV3/usePackageStateV3";
import { useUpgrades } from "../useUpgrades/useUpgrades";
import { getBasePackage } from "../packages/package/usePackageStateV3/getBasePackage/getBasePackageState";
import { useSegmentForSetup } from "../../../common/hooks/useSegmentForSetup";
import { usePackageSelectionSkipped } from "../usePackageSelectionSkipped/usePackageSelectionSkipped";

import { TrackingEvent } from "src/common/trackingEvents";
import { MerchantOnboardingSetupEvents } from "src/components/routes/Setup/common/constants/MerchantOnboardingSetupEvents";
import { useToastNotification } from "src/hooks/useToastNotification";
import { useAccessToken } from "src/common/authorisation";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";
import { useOrganisation } from "src/libraries/organisation/hooks";
import { Route } from "src/common/routing";
import { RedirectResult } from "src/components/routes/Setup/package-selection/v3/PackageSelectionExperiment/utils/useRedirectionRoutes";

export const usePackageSelectionV3 = (
  isInOnboardingFlow = true,
  isInPackageSelectionExperiment = false,
  redirectTo: RedirectResult | undefined = undefined,
  setIsIncompatibleAddonDialogVisible?: Dispatch<SetStateAction<boolean>>
) => {
  const { pathname } = useRouter();

  const getNextRoute = (): RedirectResult | undefined => {
    if (redirectTo && redirectTo.previousPage) {
      return {
        previousPage: redirectTo.previousPage,
        previousRouteParams: redirectTo.previousRouteParams,
        previousQueryParams: redirectTo.previousQueryParams,
      };
    } else if (!isInOnboardingFlow) {
      return { previousPage: Route.Home };
    }
    return undefined;
  };

  const redirectResult = getNextRoute();

  const { onSuccess } = useToast(
    redirectResult?.previousPage,
    redirectResult?.previousQueryParams,
    redirectResult?.previousRouteParams
  );

  const { triggerErrorNotification } = useToastNotification();
  const { sendEvent } = useSegmentForSetup();
  const [loading, setLoading] = useState(false);
  const {
    selectedUpgrades,
    cachedSelectedUpgrades,
    selectUpgrade,
    submitUpgrades,
    isValidUpgradeSubmission,
    setSelectedUpgrades,
    setCachedSelectedUpgrades,
  } = useUpgrades();

  const creditor = usePrimaryCreditor();
  const organisation = useOrganisation();
  const [accessToken] = useAccessToken();
  const { data: creditorDetails } = useCreditorDetailSelf(creditor?.id || null);
  const { data: userDetails } = useUserShowSelf();

  const merchantAlreadySelectedPackage =
    !!organisation?.organisation_preferences?.merchant_has_requested_package;

  const [requestPackage] = useOrganisationRequestPackage(
    accessToken?.links?.organisation || "",
    {
      onError: () => {
        throw new Error("Error requesting plan change");
      },
      onSuccess: async (res) => {
        const pkg = res?.organisations?.package_state;
        if (!merchantAlreadySelectedPackage && pkg) {
          localStorage.setItem(`gc.package.${organisation?.id}`, pkg);
          await submitUpgrades(() => {
            setIsIncompatibleAddonDialogVisible?.(false);
            setLoading(false);
            onSuccess({ notify: true });
          });
        } else {
          setLoading(false);
          onSuccess({ notify: true });
        }
      },
    }
  );

  const isCurrentPackageInvoiced = (pkg: PricingPackagesV3) => {
    const currentPackage = organisation?.package_state as PricingPackagesV3;
    const baseCurrentPackage = getBasePackage(currentPackage);
    const isInvoiced = currentPackage.includes("invoiced");
    return baseCurrentPackage === pkg && isInvoiced;
  };

  const makeZendeskPackageRequest = (pkg: PricingPackagesV3) => {
    if (
      !organisation?.pricing_version ||
      !organisation?.id ||
      !organisation?.package_state ||
      !userDetails?.users?.email ||
      userDetails?.users?.scope !== UsersScope.Admin
    ) {
      return triggerErrorNotification({
        message: (
          <Trans id="setup.package-selection-v3.package.plan-change-error">
            To change your plan please contact help@gocardless.com
          </Trans>
        ),
      });
    }

    const {
      pricing_version,
      must_use_payment_pages,
      customer_notifications_enabled,
      id,
      package_state,
    } = organisation;

    const zenDeskBaseURL = new URL(
      "https://support.gocardless.com/hc/en-gb/requests/"
    );

    const zdFormId = "11454166375580";

    const params = new URLSearchParams({
      ticket_form_id: zdFormId,
      v: `${pricing_version}`,
      organisation: id,
      package: package_state,
      request: pkg,
      email: userDetails.users.email,
      redirect: window.location.href,
      ...(!must_use_payment_pages ? { cpp: "true" } : undefined),
      ...(customer_notifications_enabled ? { cn: "true" } : undefined),
    });

    const zenDeskFormURL = new URL(`new?${params}`, zenDeskBaseURL);

    sendEvent(TrackingEvent.SUPPORT_ZENDESK_LINK_CLICKED, {
      label: "Package selection fallback",
      zdFormId,
      pathname,
    });

    window.location.href = zenDeskFormURL.toString();
  };

  const { packageSelectionSkipped, unsetPackageSelectionSkipped } =
    usePackageSelectionSkipped(organisation?.id);

  const makePackageRequest = async (pkg: PricingPackagesV3) => {
    try {
      const sendPackageSelectedEvent = () =>
        sendEvent(MerchantOnboardingSetupEvents.PackageSelectionSelected, {
          page_version: "variant",
          package: pkg,
        });

      if (isCurrentPackageInvoiced(pkg)) {
        onSuccess();
        sendPackageSelectedEvent();
      } else if (isValidUpgradeSubmission(pkg)) {
        setLoading(true);
        await requestPackage({
          requested_package: pkg,
        });
        sendPackageSelectedEvent(); // if requestPackage() throws, this is not sent!
        packageSelectionSkipped && unsetPackageSelectionSkipped();
      } else {
        if (isInPackageSelectionExperiment) {
          setIsIncompatibleAddonDialogVisible?.(true);

          setCachedSelectedUpgrades({
            //original selection
            ...selectedUpgrades,
          });

          //we default to all false except for own_sun as this is valid for all packages
          //this allows the isValidUpgradeSubmission check to pass when buttons are clicked in incompatible dialog.
          setSelectedUpgrades({
            own_sun: selectedUpgrades.own_sun ?? false,
            custom_payment_pages: false,
            custom_notifications: false,
          });

          return;
        }
        throw new Error("Error requesting plan change");
      }
    } catch (err) {
      setLoading(false);
      makeZendeskPackageRequest(pkg);
      //TODO - We may want to log this to Sentry, but we don't want to break the flow!
    }
  };

  const completed =
    merchantAlreadySelectedPackage ||
    !!creditorDetails?.creditor_details?.creditor_type ||
    !!localStorage.getItem(`gc.package.${organisation?.id}`);

  return {
    loaded: !!creditorDetails && !!organisation,
    completed,
    route: Route.PackageSelection,
    requestPackage: async (pkg: PricingPackagesV3) => {
      await makePackageRequest(pkg);
    },
    selectedUpgrades,
    selectUpgrade,
    merchantAlreadySelectedPackage,
    loading,
    isValidUpgradeSubmission,
    cachedSelectedUpgrades,
    setSelectedUpgrades,
    submitUpgrades,
  };
};
