import { useReportingMetric } from "@gocardless/api/dashboard/reporting";

import {
  ExperimentChartWidgetType,
  ExperimentChartWidgetTypes,
} from "../utils";
import { useReportingError } from "../../../hooks/useReportingError";
import {
  last12Months,
  last24Months,
  last30Days,
  next30DaysFromToday,
  today,
  yesterday,
} from "../../DateRangeMenu/utils";

import { Currency } from "src/common/currencies";
import { castStringToDate } from "src/common/date-helper";
import { useDefaultCreditorId } from "src/libraries/creditor/hooks";

const getDateRange = (chartType: ExperimentChartWidgetType) => {
  switch (chartType) {
    case ExperimentChartWidgetTypes.MonthlyPaymentVolume:
    case ExperimentChartWidgetTypes.MonthlyFailureRate:
    case ExperimentChartWidgetTypes.AverageRevenuePerCustomer:
    case ExperimentChartWidgetTypes.ActiveCustomersHistory:
      return { startDate: last12Months, endDate: yesterday.toString() };
    case ExperimentChartWidgetTypes.RollingSixMonthPaymentVolume:
      return { startDate: last24Months, endDate: yesterday.toString() };
    case ExperimentChartWidgetTypes.PayoutForecast:
      return { startDate: today.toString(), endDate: next30DaysFromToday };
    default:
      return { startDate: last30Days, endDate: yesterday.toString() };
  }
};

const hiddenMetrics: ExperimentChartWidgetType[] = [
  ExperimentChartWidgetTypes.FutureRevenueForecasting,
  ExperimentChartWidgetTypes.PaymentFailureReasons,
  ExperimentChartWidgetTypes.CustomersAtRisk,
  ExperimentChartWidgetTypes.SimilarBusinessFailureRates,
];

export const useExperimentChartWidgetMetric = ({
  type,
  currency,
}: {
  type: ExperimentChartWidgetType;
  currency: Currency;
}) => {
  const { startDate, endDate } = getDateRange(type);

  const defaultCreditorId = useDefaultCreditorId();

  const { data, error, key } = useReportingMetric(
    type,
    {
      creditor: defaultCreditorId ?? "",
      start_date: castStringToDate(startDate),
      end_date: castStringToDate(endDate),
    },
    !hiddenMetrics.includes(type)
  );

  // This is used for the rolling_six_month_payment_volume metric,
  // to display a comparison line on the chart
  const {
    data: monthlyPaymentVolumeData,
    error: monthlyPaymentVolumeError,
    key: monthlyPaymentVolumeKey,
  } = useReportingMetric(
    ExperimentChartWidgetTypes.MonthlyPaymentVolume,
    {
      creditor: defaultCreditorId ?? "",
      start_date: castStringToDate(startDate),
      end_date: castStringToDate(endDate),
    },
    type === ExperimentChartWidgetTypes.RollingSixMonthPaymentVolume
  );

  useReportingError(
    error || monthlyPaymentVolumeError,
    key || monthlyPaymentVolumeKey
  );

  const formattedData =
    type === ExperimentChartWidgetTypes.RollingSixMonthPaymentVolume
      ? {
          ...data,
          rolling_six_month_payment_volume:
            data?.rolling_six_month_payment_volume?.[currency]?.map((item) => ({
              ...item,
              monthly_payment_volume:
                monthlyPaymentVolumeData?.monthly_payment_volume?.[
                  currency
                ]?.find((monthlyItem) => monthlyItem.date === item.date)
                  ?.value ?? 0,
            })),
          updated_at: data?.updated_at ?? new Date(),
        }
      : data;

  return {
    data: formattedData,
  };
};
