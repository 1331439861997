import {
  CreditorBalanceResource,
  CreditorResource,
} from "@gocardless/api/dashboard/types";
import {
  getLocalTimeZone,
  isToday,
  parseDate,
  today,
} from "@internationalized/date";

import { BalanceType, FundCategoryType } from "./constants";

import { Currency, currencyToName } from "src/common/currencies";

export const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const creditorBalanceAmount = (
  creditorBalance: CreditorBalanceResource,
  type: FundCategoryType
): number => {
  switch (type) {
    case FundCategoryType.PENDING:
      if (typeof creditorBalance.pending === "number") {
        return creditorBalance.pending;
      }
      return 0;
    case FundCategoryType.COLLECTED:
      if (typeof creditorBalance.confirmed === "number") {
        return creditorBalance.confirmed;
      }
      return 0;
    case FundCategoryType.PAYOUT:
      if (typeof creditorBalance.closest_payout_amount === "number") {
        return creditorBalance.closest_payout_amount;
      }
      return 0;
    case FundCategoryType.UPCOMING:
      if (
        typeof creditorBalance.upcoming_payout_amount === "number" ||
        typeof creditorBalance.upcoming_payout_amount === "string"
      ) {
        return Number(creditorBalance.upcoming_payout_amount);
      }
      return 0;
    default:
      throw new Error(`Unexpected type in creditorBalanceAmount: ${type}`);
  }
};

export const getBalanceType = (
  balance: CreditorBalanceResource,
  homeCurrency: string | null | undefined
) => {
  // We don't differentiate multi-scheme merchant balances.
  if (!homeCurrency) {
    return;
  }

  if (balance.fx_currency === null) {
    if (balance.currency === homeCurrency) {
      return BalanceType.HomeBalance;
    }

    return BalanceType.DomesticBalance;
  }

  return BalanceType.FXBalance;
};

export const orderCreditorBalances = (
  creditorBalanceList: CreditorBalanceResource[],
  creditor: CreditorResource | null | undefined
) => {
  const balanceList = [...creditorBalanceList].sort((a, b) => {
    const firstCurrency = currencyToName()[a.currency as Currency];
    const secondCurrency = currencyToName()[b.currency as Currency];

    return firstCurrency.localeCompare(secondCurrency);
  });
  const homeBalanceIndex = balanceList.findIndex((balance) => {
    const balanceType = getBalanceType(balance, creditor?.fx_payout_currency);
    return balanceType === BalanceType.HomeBalance;
  });

  if (homeBalanceIndex > 0) {
    // Moving home balance to the top of the list
    balanceList.splice(0, 0, ...balanceList.splice(homeBalanceIndex, 1));
  }

  return balanceList;
};

export const dateIsToday = (dateString: string): boolean =>
  isToday(parseDate(dateString), getLocalTimeZone());

export const dateIsInTheFuture = (dateString: string): boolean => {
  const targetDate = parseDate(dateString);

  return today(getLocalTimeZone()).compare(targetDate) < 0;
};

export const useCreditorBalancesLastUpdatedAt = (
  creditorBalances: CreditorBalanceResource | CreditorBalanceResource[]
) => {
  if (Array.isArray(creditorBalances)) {
    // Get the most recently updated creditor balance since they each have their
    // own updated time.
    const sortedUpdatedAtDates = creditorBalances
      .map(({ updated_at }) => updated_at as unknown as string)
      .sort((a, b) => (a > b ? -1 : 1));

    return sortedUpdatedAtDates[0] ?? "";
  }
  return (creditorBalances.updated_at ?? "") as unknown as string;
};
