import { Card } from "@braze/web-sdk";
import { NextRouter } from "next/router";

import { getRouteFromPath } from "src/common/routing/routes";
import { BannerPriority, MAX_BANNERS } from "src/common/bannerProperties";
import { Route } from "src/common/routing";

export const filterAndSortCards = (
  router: NextRouter,
  cards: Card[]
): Card[] => {
  if (!router || !router.asPath) return [];
  const currentRoute = getRouteFromPath(router.asPath);
  return cards
    .filter(
      (card) =>
        card.extras &&
        card.extras.priority &&
        card.extras.priority in BannerPriority &&
        card.extras.pageToDisplay &&
        card.extras.pageToDisplay in Route &&
        (card.extras.pageToDisplay as Route) === currentRoute
    )
    .sort(
      (a, b) =>
        BannerPriority[a.extras.priority as keyof typeof BannerPriority] -
        BannerPriority[b.extras.priority as keyof typeof BannerPriority]
    )
    .slice(0, MAX_BANNERS);
};
