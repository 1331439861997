import {
  Box,
  Color,
  ColorPreset,
  Interpose,
  Separator,
  useTheme,
  XYGrid,
} from "@gocardless/flux-react";

import { mobileSeparatorStyle } from "../../utils/style";
import { OutboundDataWidget } from "../OutboundDataWidget/OutboundDataWidget";

import { HomeOutboundAccountHeader } from "src/components/HomeOutboundAccountHeader";
import { OutboundPaymentBalanceType } from "src/components/HomeOutboundPaymentBalance/OutboundPaymentBalanceType";

export const OutboundSection = () => {
  const { theme } = useTheme();

  return (
    <Box gridArea="outbound">
      <Box spaceBelow={2}>
        <HomeOutboundAccountHeader />
      </Box>

      <Box
        bg={[Color.White, Color.Brownstone_50]}
        borderColor={[ColorPreset.BorderOnLight_04, Color.White]}
        borderRadius={[1, 0]}
        borderWidth={[1, 0]}
        gutterH={[1.5, 0]}
        gutterV={[1.5, 0]}
      >
        <XYGrid
          columnGap={[0, 1.5, 2]}
          rowGap={[0, 1.5, 2]}
          templateColumns={["1fr", "repeat(2, 1fr)", null, "repeat(3, 1fr)"]}
        >
          <Interpose
            node={
              <Separator align="center" css={mobileSeparatorStyle(theme)} />
            }
            trailing
          >
            {[
              OutboundPaymentBalanceType.Current,
              OutboundPaymentBalanceType.Available,
              OutboundPaymentBalanceType.Scheduled,
            ].map((dataWidgetType) => (
              <OutboundDataWidget key={dataWidgetType} type={dataWidgetType} />
            ))}
          </Interpose>
        </XYGrid>
      </Box>
    </Box>
  );
};
