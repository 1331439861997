import {
  Area,
  AreaChart as RechartsAreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { DateFormatter } from "@internationalized/date";
import {
  Box,
  P,
  Color,
  Separator,
  TypePreset,
  TypeScale,
  useTheme,
  FontWeight,
} from "@gocardless/flux-react";
import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

import { DataPoint } from "../../ChartWidget/utils";

import type {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import type { BaseAxisProps, DataKey } from "recharts/types/util/types";

import { Currency } from "src/common/currencies";
import { useI18n } from "src/components/i18n";
import { offsetUserDate } from "src/common/date-time";

export interface ChartProps {
  ariaLabelledby: string;
  data: DataPoint[] | undefined;
  xDataKey: BaseAxisProps["dataKey"];
  yDataKey: DataKey<string | number>;
  currency?: Currency;
  showTooltips?: boolean;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  currency,
  i18n,
}: TooltipProps<ValueType, NameType> & {
  currency?: Currency;
  i18n: I18n;
}) => {
  const [locale] = useI18n();
  if (active && payload?.length) {
    const value = payload[0]?.value as number;

    const formattedValue = currency
      ? `${i18n
          .number(value / 100, {
            style: "currency",
            currencyDisplay: "code",
            currency,
            minimumFractionDigits: 2,
          })
          .replace(currency, "")
          .trim()} ${currency}`
      : i18n.number(value);

    return (
      <Box
        gutterH={1}
        gutterV={1}
        borderWidth={1}
        borderColor={Color.Greystone_500}
        borderRadius={1}
        elevation={2}
        bg={Color.White}
      >
        <P
          weight={FontWeight.SemiBold}
          size={TypeScale.Size_02}
          spaceBelow={0.5}
        >
          {formattedValue}
        </P>
        <Separator thickness={1.5} direction="block-horizontal" spacing={0} />
        <P
          size={TypeScale.Size_01}
          spaceAbove={0.5}
          preset={TypePreset.Body_02}
          color={Color.Greystone_1200}
        >
          {new Intl.DateTimeFormat(locale, {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }).format(offsetUserDate(label))}
        </P>
      </Box>
    );
  }

  return null;
};

const AreaChart: React.FC<ChartProps> = ({
  ariaLabelledby,
  data,
  xDataKey,
  yDataKey,
  currency,
  showTooltips = true,
}) => {
  const { i18n } = useLingui();
  const { theme } = useTheme();
  const [locale] = useI18n();

  return (
    <ResponsiveContainer height="100%" width="100%">
      <RechartsAreaChart
        accessibilityLayer
        aria-labelledby={ariaLabelledby}
        data={data}
        throttleDelay={75}
      >
        <defs>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop stopColor={theme.color(Color.Dusk_700)} stopOpacity={0.3} />
              <stop
                offset="1"
                stopColor={theme.color(Color.Dusk_100)}
                stopOpacity={0.3}
              />
            </linearGradient>
          </defs>
        </defs>
        <CartesianGrid strokeDasharray={2} vertical={false} />
        <XAxis
          dataKey={xDataKey}
          height={50}
          tickMargin={25}
          strokeWidth={2}
          axisLine={{ stroke: theme.color(Color.Greystone_500) }}
          tickLine={{ stroke: theme.color(Color.Greystone_500) }}
          fontSize={12}
          interval="preserveStartEnd"
          tickFormatter={(tick: string) => {
            const options = {
              day: "2-digit",
              month: "short",
              year: "numeric",
            };

            const formatter = new DateFormatter(
              locale,
              options as Intl.DateTimeFormatOptions
            );

            const formattedDate = formatter.format(offsetUserDate(tick));
            const today = formatter.format(new Date());

            return today === formattedDate
              ? i18n._(t({ message: "Today" }))
              : formattedDate;
          }}
          minTickGap={72}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={5}
          fontSize={12}
          tickFormatter={(value: number, index) => {
            if (value === 0) return "0";
            // Workaround to remove every other tick value from the Y axis,
            // while preserving all grid lines
            if (index % 2 !== 0) return "";

            return new Intl.NumberFormat("en-US", {
              notation: "compact",
              compactDisplay: "short",
            }).format(currency ? value / 100 : value);
          }}
          width={35}
        />

        {showTooltips && (
          <Tooltip
            animationEasing="ease"
            animationDuration={300}
            content={<CustomTooltip currency={currency} i18n={i18n} />}
            cursor={{
              stroke: theme.color(Color.Greystone_1400),
              strokeDasharray: 3,
              strokeWidth: 1.5,
            }}
          />
        )}

        <Area
          stroke={theme.color(Color.Dusk_900)}
          strokeWidth={2}
          strokeLinejoin="round"
          strokeLinecap="round"
          type="monotone"
          dataKey={yDataKey}
          fillOpacity={1}
          fill="url(#colorUv)"
          activeDot={{
            fill: theme.color(Color.Dusk_900),
            stroke: theme.color(Color.Dusk_900),
            r: 4,
          }}
          dot={false}
        />
      </RechartsAreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChart;
