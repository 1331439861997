import { ReactNode } from "react";
import { Dialog } from "@gocardless/flux-react";

import { Loader } from "./Loader";

export interface LoadingDialogProps {
  open: boolean;
  title?: ReactNode;
  description?: ReactNode;
}

export const LoadingDialog: React.FC<LoadingDialogProps> = ({
  open,
  title,
  description,
}) => (
  <Dialog
    aria-labelledby="dialogTitle"
    aria-busy="true"
    aria-live="polite"
    bodyGutterH={4}
    bodyGutterV={4}
    open={open}
  >
    <Loader title={title} description={description} />
  </Dialog>
);

export default LoadingDialog;
