export enum BannerType {
  Hardcoded,
  Braze,
}

export enum BannerPriority {
  High,
  Medium,
  Low,
}

export const MAX_BANNERS = 2;
