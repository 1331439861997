import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { useCallback } from "react";

import AccountSetupStep from "./AccountSetupStep";

import StepCardVariant from "src/components/ui/StepCard/StepCardVariant";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useHasSelectedPackage } from "src/components/routes/SuccessPlus/api/index.hooks";

interface UseStepCardVariantParams {
  focusedStepCard?: AccountSetupStep | null;
  status: CreditorsVerificationStatus;
  useRevampedLayout: boolean;
  steps: Partial<Record<AccountSetupStep, boolean>>;
}

export const useStepCardVariant = ({
  steps,
  focusedStepCard,
  status,
  useRevampedLayout,
}: UseStepCardVariantParams) => {
  const { isVariationOn: packageSelectionExperimentEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.PACKAGE_SELECTION_PHASE_ONE,
    });
  const hasSelectedPackage = useHasSelectedPackage();

  const getVariant = useCallback(
    (step: AccountSetupStep): StepCardVariant => {
      const isFocused = focusedStepCard === step;

      if (step === AccountSetupStep.SetUpYourAccount) {
        return determineVariantForAccountSetup(
          isFocused,
          status,
          useRevampedLayout,
          packageSelectionExperimentEnabled ? hasSelectedPackage : true
        );
      }

      return determineVariantForOtherSteps(
        step,
        isFocused,
        useRevampedLayout,
        steps
      );
    },
    [
      focusedStepCard,
      useRevampedLayout,
      status,
      steps,
      packageSelectionExperimentEnabled,
      hasSelectedPackage,
    ]
  );

  return getVariant;
};

const determineVariantForAccountSetup = (
  isFocused: boolean,
  status: CreditorsVerificationStatus,
  useRevampedLayout: boolean,
  hasSelectedPackage: boolean
): StepCardVariant => {
  if (useRevampedLayout) {
    if (isFocused) {
      return status === CreditorsVerificationStatus.Successful &&
        hasSelectedPackage
        ? StepCardVariant.FOCUSED_COMPLETE_REVAMPED
        : status === CreditorsVerificationStatus.InReview
          ? StepCardVariant.FOCUSED_IN_REVIEW_REVAMPED
          : StepCardVariant.FOCUSED_INCOMPLETE_REVAMPED;
    } else {
      return status === CreditorsVerificationStatus.Successful &&
        hasSelectedPackage
        ? StepCardVariant.COMPLETE_REVAMPED
        : status === CreditorsVerificationStatus.InReview
          ? StepCardVariant.IN_REVIEW_REVAMPED
          : StepCardVariant.INCOMPLETE_REVAMPED;
    }
  } else {
    if (isFocused) {
      return status === CreditorsVerificationStatus.Successful &&
        hasSelectedPackage
        ? StepCardVariant.FOCUSED_COMPLETE
        : status === CreditorsVerificationStatus.InReview
          ? StepCardVariant.FOCUSED_IN_REVIEW
          : StepCardVariant.FOCUSED_INCOMPLETE;
    } else {
      return status === CreditorsVerificationStatus.Successful &&
        hasSelectedPackage
        ? StepCardVariant.COMPLETE
        : status === CreditorsVerificationStatus.InReview
          ? StepCardVariant.IN_REVIEW
          : StepCardVariant.INCOMPLETE;
    }
  }
};

const determineVariantForOtherSteps = (
  step: AccountSetupStep,
  isFocused: boolean,
  useRevampedLayout: boolean,
  steps: Partial<Record<AccountSetupStep, boolean>>
): StepCardVariant => {
  const stepComplete = steps[step];
  if (useRevampedLayout) {
    return isFocused
      ? stepComplete
        ? StepCardVariant.FOCUSED_COMPLETE_REVAMPED
        : StepCardVariant.FOCUSED_INCOMPLETE_REVAMPED
      : stepComplete
        ? StepCardVariant.COMPLETE_REVAMPED
        : StepCardVariant.INCOMPLETE_REVAMPED;
  } else {
    return isFocused
      ? stepComplete
        ? StepCardVariant.FOCUSED_COMPLETE
        : StepCardVariant.FOCUSED_INCOMPLETE
      : stepComplete
        ? StepCardVariant.COMPLETE
        : StepCardVariant.INCOMPLETE;
  }
};
