import { H1, TypePreset, ColorPreset } from "@gocardless/flux-react";

import { useOrganisation } from "src/libraries/organisation/hooks";

export const Header = () => {
  const organisation = useOrganisation();
  return (
    <H1 preset={TypePreset.Heading_06} color={ColorPreset.TextOnLight_01}>
      {organisation?.name}
    </H1>
  );
};
