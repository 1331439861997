import {
  AlignItems,
  Box,
  ColorScheme,
  Space,
  Tag,
  TagColor,
  Text,
  XYGrid,
} from "@gocardless/flux-react";

import { ExperimentChartWidgetTypes } from "../utils";
import { ExperimentChartWidget } from "../ExperimentChartWidget/ExperimentChartWidget";

import { HomeSectionHeader } from "src/components/HomeSectionHeader/HomeSectionHeader";

const ChartWidgetTypes = {
  AverageRevenuePerCustomer:
    ExperimentChartWidgetTypes.AverageRevenuePerCustomer,
  ActiveCustomersHistory: ExperimentChartWidgetTypes.ActiveCustomersHistory,
  CustomersAtRisk: ExperimentChartWidgetTypes.CustomersAtRisk,
} as const;

export const CustomerInsightsSection: React.FC = () => {
  const { AverageRevenuePerCustomer, ActiveCustomersHistory, CustomersAtRisk } =
    ChartWidgetTypes;

  return (
    <Box gridArea="customerInsights">
      <Box layout="flex" alignItems={AlignItems.Center} spaceBelow={2}>
        <Tag color={TagColor.Info} colorScheme={ColorScheme.OnDark}>
          <Text size={1}>New</Text>
        </Tag>
        <Space h={0.75} layout="inline" />
        <HomeSectionHeader>Customer insights</HomeSectionHeader>
      </Box>

      <XYGrid
        columnGap={2}
        rowGap={2}
        templateColumns={["1fr", "repeat(2, 1fr)", null, "repeat(3, 1fr)"]}
        autoColumns="1fr"
        templateAreas={[
          `
              "${AverageRevenuePerCustomer}"
              "${ActiveCustomersHistory}"
              "${CustomersAtRisk}"
            `,
          `
              "${AverageRevenuePerCustomer} ${ActiveCustomersHistory}"
              "${CustomersAtRisk} ."
            `,
          null,
          `
              "${AverageRevenuePerCustomer} ${ActiveCustomersHistory} ${CustomersAtRisk}"
            `,
        ]}
      >
        {Object.values(ChartWidgetTypes).map((type) => (
          <Box key={type} gridArea={type}>
            <ExperimentChartWidget type={type} />
          </Box>
        ))}
      </XYGrid>
    </Box>
  );
};
