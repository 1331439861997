import {
  AlignItems,
  Box,
  ButtonVariant,
  Color,
  ColorPreset,
  Glyph,
  Icon,
  JustifyContent,
  P,
  Space,
  TypePreset,
  ButtonSize,
} from "@gocardless/flux-react";
import { Plural, Trans } from "@lingui/macro";
import { forwardRef } from "react";

import {
  showMFAEnforcementDelayedBanner,
  useMFAEnforcementData,
} from "src/components/authentication/mfa/useMFAEnforcementData";
import { Link, Route } from "src/components/routing";

export const MFAEnforcementDelayedBanner = forwardRef<HTMLDivElement, {}>(
  (_props, ref) => {
    const mfaData = useMFAEnforcementData();
    if (!showMFAEnforcementDelayedBanner(mfaData)) {
      return null;
    }

    const mfaEnforceDueDays = mfaData.data?.mfaEnforceDueDays;
    const description = (
      <P color={ColorPreset.TextOnLight_01} preset={TypePreset.Body_02}>
        <Trans>
          Retain access to your account by setting up two-factor authentication.
        </Trans>
      </P>
    );
    const setUpPageDescription = mfaEnforceDueDays && (
      <P color={ColorPreset.TextOnLight_01} preset={TypePreset.Body_02}>
        <Plural
          value={mfaEnforceDueDays}
          one="2FA will be enforced in # day."
          other="2FA will be enforced in # days."
        />
      </P>
    );

    return (
      <Box
        ref={ref}
        gutterH={1.5}
        gutterV={[1.5, null, 1]}
        borderRadius={1}
        layout="flex"
        flexWrap={["wrap", null, "nowrap"]}
        justifyContent={JustifyContent.Start}
        bg={Color.Warning_50}
        width="100%"
      >
        <Box
          layout="flex"
          gutterH={0.5}
          gutterV={0.5}
          alignItems={AlignItems.Start}
        >
          <Icon name={Glyph.Padlock} size="16px" />
        </Box>
        <Space layout="inline" h={1} />
        <Box
          layout="flex"
          width="100%"
          justifyContent={JustifyContent.SpaceBetween}
          alignItems={AlignItems.Center}
          flexWrap={["wrap", null, "nowrap"]}
          spaceAbove={[1, null, 0]}
        >
          {mfaEnforceDueDays ? setUpPageDescription : description}
          <Box width={["100%", null, "initial"]} spaceAbove={[1, null, 0]}>
            <Link
              route={Route.MFASetup}
              variant={ButtonVariant.PrimaryAuto}
              size={[ButtonSize.Md, null, ButtonSize.Sm]}
              css={{ width: "100%" }}
            >
              <Trans>Set up now</Trans>
            </Link>
          </Box>
        </Box>
      </Box>
    );
  }
);
