import {
  LineChart as RechartsLineChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  Line,
  LineProps,
} from "recharts";
import { DateFormatter } from "@internationalized/date";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import {
  Box,
  P,
  Color,
  Separator,
  TypePreset,
  TypeScale,
  useTheme,
  FontWeight,
  Text,
  UL,
  LI,
  AlignItems,
  Space,
  Interpose,
  ColorPreset,
} from "@gocardless/flux-react";
import { useLingui } from "@lingui/react";

import { convertBasisPointsToPercentage } from "../../experiments/utils";

import type { BaseAxisProps } from "recharts/types/util/types";

import { useChartTooltipEvent } from "src/components/ui/Reporting/hooks/useChartTooltipEvent";
import { useChartOpacity } from "src/components/ui/Reporting/hooks/useChartOpacity";
import { Currency } from "src/common/currencies";
import { useI18n } from "src/components/i18n";
import { offsetUserDate } from "src/common/date-time";
import { TrackingEvent } from "src/common/trackingEvents";

interface LineConfig {
  dataKey: string;
  stroke: Color;
  strokeDasharray?: string;
  strokeWidth?: number;
}

interface TrackingEventProps {
  event: TrackingEvent;
  properties?: Record<string, unknown>;
}

type DataFormat = "currency" | "percentage" | "number";

export interface LineChartProps<DataType> {
  ariaLabelledby: string;
  data: DataType[] | undefined;
  xDataKey: BaseAxisProps["dataKey"];
  lines: LineConfig[];
  currency?: Currency;
  dataFormat?: DataFormat;
  showTooltips?: boolean;
  showLegend?: boolean;
  isAnimationActive?: LineProps["isAnimationActive"];
  hoverEvent?: TrackingEventProps;
  customLabels?: Record<string, string>;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  currency,
  i18n,
  trackingEvent,
  customLabels,
  dataFormat = "currency",
}: TooltipProps<number, string> & {
  i18n: I18n;
  currency?: Currency;
  customLabels?: Record<string, string>;
  trackingEvent?: TrackingEventProps;
  dataFormat?: DataFormat;
}) => {
  const [locale] = useI18n();
  const { theme } = useTheme();

  useChartTooltipEvent({
    isTooltipActive: active,
    trackingEvent,
  });

  if (active && payload?.length) {
    const getFormattedValue = (value: number) => {
      if (currency && dataFormat === "currency") {
        return `${i18n
          .number(value / 100, {
            style: "currency",
            currencyDisplay: "code",
            currency,
            minimumFractionDigits: 2,
          })
          .replace(currency, "")
          .trim()} ${currency}`;
      } else if (dataFormat === "percentage") {
        return i18n.number(convertBasisPointsToPercentage(value / 100), {
          style: "percent",
          maximumFractionDigits: 1,
        });
      } else {
        return i18n.number(value);
      }
    };

    return (
      <Box
        gutterH={1}
        gutterV={1}
        borderWidth={1}
        borderColor={Color.Greystone_500}
        borderRadius={1}
        elevation={2}
        bg={Color.White}
      >
        <UL gutterH={0}>
          {payload.map(({ color, name = "", value = 0 }) => {
            const customLabel = customLabels?.[name];
            const formattedValue = getFormattedValue(value);

            const tooltipLabel = customLabel
              ? `${customLabel}: ${formattedValue}`
              : formattedValue;

            return (
              <LI
                key={name}
                marker="none"
                css={{
                  "&::marker": {
                    color,
                    fontSize: theme.tokens.fontSizes[6].fontSize,
                  },
                }}
              >
                <Text
                  css={{
                    display: "inline-block",
                    height: 12,
                    width: 12,
                    backgroundColor: color,
                    borderRadius: "50%",
                    verticalAlign: "middle",
                  }}
                />
                <Space h={0.5} layout="inline" />
                <Text
                  layout="inline-block"
                  size={TypeScale.Size_02}
                  spaceBelow={0.5}
                  weight={FontWeight.SemiBold}
                >
                  {tooltipLabel}
                </Text>
              </LI>
            );
          })}
        </UL>

        <Separator thickness={1.5} direction="block-horizontal" spacing={0} />
        <P
          size={TypeScale.Size_01}
          spaceAbove={0.5}
          preset={TypePreset.Body_02}
          color={Color.Greystone_1200}
        >
          {new Intl.DateTimeFormat(locale, {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }).format(offsetUserDate(label))}
        </P>
      </Box>
    );
  }

  return null;
};

const CustomLegend = ({
  lines,
  onMouseEnter,
  onMouseLeave,
}: {
  lines: LineConfig[];
  onMouseEnter: (dataKey: string) => void;
  onMouseLeave: (dataKey: string) => void;
}) => {
  const { theme } = useTheme();

  return (
    <Box>
      <UL css={{ paddingLeft: 0, li: { display: "inline-block" } }}>
        <Interpose node={<Space h={0.5} layout="inline" />}>
          {lines.map(({ dataKey, stroke }) => (
            <LI
              key={dataKey}
              onMouseEnter={() => onMouseEnter(dataKey)}
              onMouseLeave={() => onMouseLeave(dataKey)}
            >
              <Box
                css={{
                  "&:hover": {
                    borderColor: theme.color(ColorPreset.BorderOnLight_01),
                  },
                }}
                gutterH={1}
                gutterV={0.5}
                layout="flex"
                alignItems={AlignItems.Center}
                borderWidth={2}
                borderColor={ColorPreset.IconOnDark_02}
                borderRadius={2}
              >
                <Text
                  css={{
                    display: "inline-block",
                    height: 12,
                    width: 12,
                    backgroundColor: theme.color(stroke),
                    borderRadius: "50%",
                  }}
                />
                <Space h={1} layout="inline" />
                <Text
                  layout="block"
                  size={TypeScale.Size_02}
                  weight={FontWeight.SemiBold}
                >
                  {dataKey}
                </Text>
              </Box>
            </LI>
          ))}
        </Interpose>
      </UL>
    </Box>
  );
};

const LineChart = <DataType,>({
  ariaLabelledby,
  data,
  xDataKey,
  lines,
  currency,
  dataFormat = "currency",
  showTooltips = true,
  showLegend = false,
  isAnimationActive = true,
  hoverEvent,
  customLabels,
}: LineChartProps<DataType>) => {
  const { opacityConfig, handleMouseEnter, handleMouseLeave } = useChartOpacity(
    {
      items: lines,
    }
  );

  const { i18n } = useLingui();
  const { theme } = useTheme();
  const [locale] = useI18n();

  return (
    <>
      {showLegend ? (
        <>
          <CustomLegend
            lines={lines}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <Space v={3} layout="inline" />
        </>
      ) : null}
      <ResponsiveContainer height="100%" width="100%">
        <RechartsLineChart
          accessibilityLayer
          aria-labelledby={ariaLabelledby}
          data={data}
          throttleDelay={75}
        >
          <CartesianGrid strokeDasharray={2} vertical={false} />
          <XAxis
            dataKey={xDataKey}
            height={50}
            tickMargin={25}
            strokeWidth={2}
            axisLine={{ stroke: theme.color(Color.Greystone_500) }}
            tickLine={{ stroke: theme.color(Color.Greystone_500) }}
            fontSize={12}
            interval="preserveStartEnd"
            tickFormatter={(tick: string) => {
              const options = {
                day: "2-digit",
                month: "short",
                year: "numeric",
              };

              const formatter = new DateFormatter(
                locale,
                options as Intl.DateTimeFormatOptions
              );

              const formattedDate = formatter.format(offsetUserDate(tick));
              const today = formatter.format(new Date());

              return today === formattedDate
                ? i18n._(t({ message: "Today" }))
                : formattedDate;
            }}
            minTickGap={72}
          />
          <YAxis
            axisLine={false}
            fontSize={12}
            tickLine={false}
            tickMargin={5}
            tickFormatter={(value: number, index) => {
              if (value === 0) return "0";
              // Workaround to remove every other tick value from the Y axis,
              // while preserving all grid lines
              if (index % 2 !== 0) return "";

              if (dataFormat === "percentage") {
                return i18n.number(
                  convertBasisPointsToPercentage(value / 100),
                  {
                    style: "percent",
                    maximumFractionDigits: 0,
                  }
                );
              }

              return new Intl.NumberFormat("en-US", {
                notation: "compact",
                compactDisplay: "short",
              }).format(currency ? value / 100 : value);
            }}
            width={35}
          />

          {showTooltips ? (
            <Tooltip
              animationEasing="ease"
              animationDuration={300}
              content={
                <CustomTooltip
                  currency={currency}
                  i18n={i18n}
                  trackingEvent={hoverEvent}
                  customLabels={customLabels}
                  dataFormat={dataFormat}
                />
              }
              cursor={{
                stroke: theme.color(Color.Greystone_1400),
                strokeDasharray: 3,
                strokeWidth: 1.5,
              }}
            />
          ) : null}
          {lines.map(({ dataKey, stroke, strokeDasharray, strokeWidth }) => (
            <Line
              key={dataKey}
              strokeWidth={strokeWidth ?? 2}
              strokeLinejoin="round"
              strokeLinecap="round"
              type="monotone"
              dataKey={dataKey}
              stroke={theme.color(stroke ?? Color.Sunset_800)}
              strokeDasharray={strokeDasharray}
              activeDot={{ r: 0 }}
              dot={false}
              strokeOpacity={opacityConfig[dataKey]}
              isAnimationActive={isAnimationActive}
            />
          ))}
        </RechartsLineChart>
      </ResponsiveContainer>
    </>
  );
};

export default LineChart;
