import { forwardRef } from "react";
import {
  Box,
  BoxProps,
  Color,
  ColorPreset,
  Radio,
  RadioProps,
} from "@gocardless/flux-react";

interface CustomRadioProps extends RadioProps {
  containerStyle?: BoxProps;
}

export const CustomRadio = forwardRef<HTMLInputElement, CustomRadioProps>(
  ({ children, containerStyle = {}, ...rest }, ref) => (
    <Box
      bg={Color.White}
      borderRadius={1.5}
      borderColor={ColorPreset.BorderOnLight_05}
      borderWidth={1}
      spaceAbove={1}
      gutterV={1.5}
      gutterH={1.5}
      {...containerStyle}
    >
      <Radio ref={ref} {...rest}>
        {children}
      </Radio>
    </Box>
  )
);
