import { useMemo } from "react";

import { LastActivityType } from "../types";

import { useFetchLastActivityEntries } from "./useFetchLastActivityEntries";

import { useDefaultCreditor } from "src/libraries/creditor/hooks";

const THRESHOLD_PAYMENTS = 5;
const THRESHOLD_CUSTOMERS = 15;

export const useMerchantMaturity = (): {
  isMatureMerchant: boolean;
  isActivatedMerchant: boolean;
} => {
  const { entries } = useFetchLastActivityEntries({ sizeOfList: 5 });

  const creditor = useDefaultCreditor();
  const isActivatedMerchant = creditor?.activated ?? false;

  const isMatureMerchant = useMemo(() => {
    if (!isActivatedMerchant) {
      return false;
    }
    if (
      (entries[LastActivityType.RecentCustomers]?.customers?.length ?? 0) <
      THRESHOLD_CUSTOMERS
    ) {
      return false;
    }
    if (
      (entries[LastActivityType.TopOneOffPayments]?.paylinks?.length ?? 0) +
        (entries?.[LastActivityType.TopSubscriptionTemplates]?.plans?.length ??
          0) <
      THRESHOLD_PAYMENTS
    )
      return false;
    return true;
  }, [entries, isActivatedMerchant]);

  return { isActivatedMerchant, isMatureMerchant };
};
