import { useCustomerList } from "@gocardless/api/dashboard/customer";
import { getLocalTimeZone, today } from "@internationalized/date";

import {
  castStringToDate,
  parseToAbsoluteDateTimeString,
} from "src/common/date-helper";

const localTimeZone = getLocalTimeZone();
const localToday = today(localTimeZone);

export const useCustomersAddedOneByOne = ({ daySpan }: { daySpan: number }) => {
  const { data: customerListResponse, isLoading } = useCustomerList({
    created_at: {
      gte: castStringToDate(
        parseToAbsoluteDateTimeString(localToday.subtract({ days: daySpan }))
      ),
    },
  });
  const customerList = customerListResponse?.customers || [];

  const numberOfCustomersAddedOneByOne = customerList.reduce<{
    count: number;
    createdAt: Date[];
  }>(
    (acc, customer) => {
      if (!customer.created_at || acc.createdAt.includes(customer.created_at)) {
        return acc;
      }

      return {
        createdAt: [...acc.createdAt, customer.created_at],
        count: acc.count + 1,
      };
    },
    {
      createdAt: [],
      count: 0,
    }
  );
  return {
    isLoading,
    customerAddedOneByOne: numberOfCustomersAddedOneByOne.count,
  };
};
