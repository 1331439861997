import { i18nMarkTranslate } from "src/components/i18n";

export interface FeedbackStepProps {
  index: FeedbackStep;
  isActive: boolean;
  isComplete: boolean;
  onStepClick: () => void;
  onContinue: () => void;
}

export const FeedbackSteps = {
  WelcomeStep: 0,
  DataInsights: 1,
  FeatureRanking: 2,
  PricingFeedback: 3,
  AdditionalFeedback: 4,
} as const;

export type FeedbackStep = (typeof FeedbackSteps)[keyof typeof FeedbackSteps];

export interface FeedbackFormData {
  // Welcome Step
  keepPosted: "yes" | "no";

  // Data Insights Step
  dataInsights: string;

  // Feature Ranking Step
  features: Array<{
    rank: string;
    feature: string;
  }>;

  // Pricing Step
  willingToPay: "yes" | "no";
  expectedPrice?: string;
  maxPayablePrice?: string;

  // Additional Feedback Step
  additionalFeedback?: string;
}

export const FEATURE_LIST = [
  i18nMarkTranslate("Customisable data exports"),
  i18nMarkTranslate("Benchmarking performance against industry"),
  i18nMarkTranslate("Payment and revenue performance insights"),
  i18nMarkTranslate("Customer portfolio insights"),
  i18nMarkTranslate("Alerts for issues that may require action"),
  i18nMarkTranslate("Better data visualisation"),
] as const;
