import {
  AlignItems,
  Box,
  ColorScheme,
  Space,
  Tag,
  TagColor,
  Text,
  XYGrid,
} from "@gocardless/flux-react";

import {
  ExperimentChartWidgetTypes,
  ExperimentDataWidgetTypes,
} from "../utils";
import { ExperimentChartWidget } from "../ExperimentChartWidget/ExperimentChartWidget";
import { ExperimentDataWidget } from "../ExperimentDataWidget/ExperimentDataWidget";

import { HomeSectionHeader } from "src/components/HomeSectionHeader/HomeSectionHeader";

const ChartWidgetTypes = {
  MonthlyPaymentVolume: ExperimentChartWidgetTypes.MonthlyPaymentVolume,
  RollingPaymentVolume: ExperimentChartWidgetTypes.RollingSixMonthPaymentVolume,
  FutureRevenueForecasting: ExperimentChartWidgetTypes.FutureRevenueForecasting,
} as const;

const DataWidgetTypes = {
  PaymentVolumeMoM: ExperimentDataWidgetTypes.PaymentVolumeMoMTrend,
  AverageMonthlyEarnings: ExperimentDataWidgetTypes.AverageMonthlyEarnings,
  TotalEarnings: ExperimentDataWidgetTypes.TotalEarnings,
} as const;

export const RevenueInsightsSection: React.FC = () => {
  const {
    MonthlyPaymentVolume,
    RollingPaymentVolume,
    FutureRevenueForecasting,
  } = ChartWidgetTypes;

  const { PaymentVolumeMoM, AverageMonthlyEarnings, TotalEarnings } =
    DataWidgetTypes;

  return (
    <Box gridArea="revenueInsights">
      <Box layout="flex" alignItems={AlignItems.Center} spaceBelow={2}>
        <Tag color={TagColor.Info} colorScheme={ColorScheme.OnDark}>
          <Text size={1}>New</Text>
        </Tag>
        <Space h={0.75} layout="inline" />
        <HomeSectionHeader>Revenue performance insights</HomeSectionHeader>
      </Box>

      <XYGrid
        columnGap={2}
        rowGap={2}
        templateColumns={["1fr", "repeat(2, 1fr)", null, "repeat(3, 1fr)"]}
        autoColumns="1fr"
        templateAreas={[
          `
          "${MonthlyPaymentVolume}" 
          "${RollingPaymentVolume}"
          "${FutureRevenueForecasting}"
          "${PaymentVolumeMoM}"
          "${AverageMonthlyEarnings}"
          "${TotalEarnings}"
        `,
          `
          "${MonthlyPaymentVolume} ${RollingPaymentVolume}"
          "${FutureRevenueForecasting} ${PaymentVolumeMoM}"
          "${FutureRevenueForecasting} ${AverageMonthlyEarnings}"
          "${FutureRevenueForecasting} ${TotalEarnings}"
        `,
          null,
          `
          "${MonthlyPaymentVolume} ${RollingPaymentVolume} ${FutureRevenueForecasting}"
          "${PaymentVolumeMoM} ${AverageMonthlyEarnings} ${TotalEarnings}"
        `,
        ]}
      >
        {Object.values(ChartWidgetTypes).map((type) => (
          <Box key={type} gridArea={type}>
            <ExperimentChartWidget type={type} />
          </Box>
        ))}

        {Object.values(DataWidgetTypes).map((type) => (
          <Box key={type} gridArea={type}>
            <ExperimentDataWidget type={type} />
          </Box>
        ))}
      </XYGrid>
    </Box>
  );
};
