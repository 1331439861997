import { ReactNode } from "react";
import {
  AlignItems,
  Box,
  FontWeight,
  H4,
  JustifyContent,
  Space,
  Text,
  TextAlign,
} from "@gocardless/flux-react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import LoaderOutlineGC from "src/assets/svg/loader-outline-gc.svg";

interface LoaderProps {
  title?: ReactNode;
  description?: ReactNode;
}

export const Loader: React.FC<LoaderProps> = ({ title, description }) => {
  const { i18n } = useLingui();

  return (
    <Box
      layout="flex"
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
      flexDirection="column"
    >
      <img
        src={LoaderOutlineGC}
        width={72.5}
        height={72.5}
        alt={i18n._(t({ message: "Loading icon" }))}
      />
      <H4
        id="loaderTitle"
        size={5}
        spaceAbove={1.5}
        weight={FontWeight.SemiBold}
      >
        {title ?? <Trans>Loading</Trans>}
      </H4>
      {description && (
        <Text textAlign={TextAlign.Center}>
          <Space v={1.5} />
          {description}
        </Text>
      )}
    </Box>
  );
};
