import { collectionDataWidgetTypes } from "../CollectionDataWidget/utils";

export const ExperimentNames = {
  UpsellIBP: "upsell_ibp",
  UpsellFasterACH: "upsell_faster_ach",
  FutureData: "future_data",
} as const;

export const ExperimentChartWidgetTypes = {
  PayoutForecast: "payout_forecast",
  MonthlyPaymentVolume: "monthly_payment_volume",
  RollingSixMonthPaymentVolume: "rolling_six_month_payment_volume",
  FutureRevenueForecasting: "future_revenue_forecasting",
  MonthlyFailureRate: "monthly_failure_rate",
  PaymentFailureReasons: "payment_failure_reasons",
  SimilarBusinessFailureRates: "similar_business_failure_rates",
  AverageRevenuePerCustomer: "average_revenue_per_customer",
  ActiveCustomersHistory: "active_customers_history",
  CustomersAtRisk: "customers_at_risk",
} as const;

export const ExperimentDataWidgetTypes = {
  PaymentVolumeMoMTrend: "payment_volume_mom_trend",
  AverageMonthlyEarnings: "average_monthly_earnings",
  TotalEarnings: "total_earnings",
  RecentFailedPayments: collectionDataWidgetTypes.failedPayments,
  AverageFailureRate: "average_failure_rate",
  PaymentFailureMoMTrend: "payment_failure_mom_trend",
} as const;

export type ExperimentName =
  (typeof ExperimentNames)[keyof typeof ExperimentNames];

export type ExperimentChartWidgetType =
  (typeof ExperimentChartWidgetTypes)[keyof typeof ExperimentChartWidgetTypes];

export type ExperimentDataWidgetType =
  (typeof ExperimentDataWidgetTypes)[keyof typeof ExperimentDataWidgetTypes];

export const convertBasisPointsToPercentage = (value: number) => value / 100;

export const getReportingExperimentName = ({
  isFutureDataExperimentEnabled,
  isUpsellFasterAchExperimentEnabled,
  isUpsellIbpExperimentEnabled,
}: {
  isFutureDataExperimentEnabled: boolean;
  isUpsellFasterAchExperimentEnabled: boolean;
  isUpsellIbpExperimentEnabled: boolean;
}) => {
  if (isFutureDataExperimentEnabled) {
    return ExperimentNames.FutureData;
  }
  if (isUpsellFasterAchExperimentEnabled) {
    return ExperimentNames.UpsellFasterACH;
  }
  if (isUpsellIbpExperimentEnabled) {
    return ExperimentNames.UpsellIBP;
  }
  return null;
};
