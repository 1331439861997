import { forwardRef, useCallback } from "react";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";
import {
  Banner,
  BannerVariant,
  BannerLeftAccessoryType,
  BannerLayout,
  Color,
  BannerButtonVariant,
  ButtonSize,
} from "@gocardless/flux-react";
import { formatDistanceToNow } from "date-fns";
import { CalendarDate, getLocalTimeZone } from "@internationalized/date";

import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import {
  DismissibleContent,
  ContentName,
} from "src/components/layout/DismissibleContent";
import { ToTranslate } from "src/components/i18n";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import {
  OptimizelyFlag,
  OptimizelyVariant,
} from "src/technical-integrations/optimizely/constants";
import { Route, routerPush } from "src/common/routing";
import { useUserPermissions } from "src/common/hooks/user-permissions/useUserPermissions";
import { useOrganisationWithType } from "src/libraries/organisation/hooks";
import { usePrimaryCreditor } from "src/libraries/creditor/hooks";

// This is the date for the backfill job to set flexible payout for the optout bucket of merchants
const optOutReleaseDate = new CalendarDate(2025, 4, 28);

const bannerName = "Flexible Payouts - Opt Out";
const flexiblePayoutsInfoUrl =
  "https://support.gocardless.com/hc/en-gb/articles/18625103245084-Manage-weekly-monthly-or-daily-payout-schedules";

interface FlexiblePayoutsBannerOptOutProps {
  hideRedirection?: boolean;
}
export const FlexiblePayoutsBannerOptOut = forwardRef<
  HTMLDivElement,
  FlexiblePayoutsBannerOptOutProps
>(({ hideRedirection }, ref) => {
  const { isVariationOn: isFlexiblePayoutsEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.ASAP_PAYMENTS_ENABLE_FLEXIBLE_PAYOUTS,
    variationKey: OptimizelyVariant.OPT_OUT,
  });
  const primaryCreditor = usePrimaryCreditor();
  const hasFlexiblePayoutSettings =
    primaryCreditor?.links?.payout_interval_unit;

  const { sendEvent } = useSegment();
  const router = useRouter();
  const triggerBannerViewedEvent = useCallback(() => {
    sendEvent(TrackingEvent.BANNER_VIEWED, {
      page: router.pathname,
      name: bannerName,
    });
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  const { isPaymentProvider } = useOrganisationWithType();

  const userPermissions = useUserPermissions();

  if (
    !!hasFlexiblePayoutSettings ||
    !isFlexiblePayoutsEnabled ||
    !userPermissions.isAdmin ||
    isPaymentProvider
  ) {
    return null;
  }

  const distance = formatDistanceToNow(
    optOutReleaseDate.toDate(getLocalTimeZone()),
    { addSuffix: false }
  );

  return (
    <DismissibleContent
      ref={ref}
      name={ContentName.FlexiblePayoutsOptOutBannerDismissed}
      onContentMount={triggerBannerViewedEvent}
      renderContent={(dismiss) => (
        <Banner
          elevation={0}
          variant={BannerVariant.Light}
          layout={BannerLayout.Horizontal}
          backgroundColor={Color.Info_50}
          leftAccessory={{
            type: BannerLeftAccessoryType.Tag,
            text: distance + " to go",
          }}
          primaryAction={
            !hideRedirection
              ? {
                  control: "button",
                  children: <ToTranslate>Change preferences</ToTranslate>,
                  buttonVariant: BannerButtonVariant.Primary,
                  size: ButtonSize.Sm,
                  onClick: () => {
                    sendEvent(TrackingEvent.BANNER_PRIMARY_CTA_CLICKED, {
                      page: router.pathname,
                      name: bannerName,
                      destination: Route.Payouts,
                    });
                    routerPush({
                      route: Route.Payouts,
                      queryParams: { action: "preferences-settings" },
                    });
                  },
                }
              : undefined
          }
          secondaryAction={{
            control: "link",
            target: "_blank",
            children: <Trans id="Learn more">Learn more</Trans>,
            buttonVariant: BannerButtonVariant.Plain,
            size: ButtonSize.Sm,
            onClick: () => {
              sendEvent(TrackingEvent.BANNER_SECONDARY_CTA_CLICKED, {
                page: router.pathname,
                name: bannerName,
                destination: flexiblePayoutsInfoUrl,
              });
            },
            href: flexiblePayoutsInfoUrl,
          }}
          closeAction={{
            label: <Trans id="Close">Close</Trans>,
            onClose: () => {
              sendEvent(TrackingEvent.BANNER_DISMISSED, {
                page: router.pathname,
                name: bannerName,
              });
              dismiss();
            },
          }}
        >
          <ToTranslate>
            Your payouts will be changing to a weekly schedule soon
          </ToTranslate>
        </Banner>
      )}
    />
  );
});
