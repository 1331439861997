export const MAX_WIDTH_LG = "1396px";
export const MAX_WIDTH_SM = "616px";

//**********************************//
// SINGLE FULL LAYOUT WITH NAV
//**********************************//

/* Sidenav width for large screens */
export const NAV_WIDTH_XL = "264px";
/* Sidenav width for small screens */
export const NAV_WIDTH = "240px";
/* Header height */
export const HEADER_HEIGHT = "55px";

//**********************************//
