export enum ContentName {
  PricingUpdateBannerDismissed = "gc.is_pricing_update_banner_dismissed",
  InstalmentsBannerDismissed = "gc.is_instalments_banner_dismissed",
  QRCodeBannerDismissed = "gc.is_qrcode_banner_dismissed",
  InstalmentsBannerCustomerDetailsDismissed = "gc.is_instalments_banner_customer_details_dismissed",
  PlanSchedulePauseFeedbackBannerDismissed = "gc.is_plan_schedule_pause_feedback_banner_dismissed",
  PipeBannerDismissed = "gc.is_pipe_banner_dismissed",
  EmbeddedPipeBannerDismissed = "gc.is_embedded_pipe_banner_dismissed",
  BadBannerDismissed = "gc.is_bad_banner_dismissed",
  BulkChangeEntryPointBannerDismissed = "gc.is_bulk_change_entry_point_banner_dismissed",
  CustomSunBannerDismissed = "gc.is_custom_sun_banner_dismissed",
  InstalmentBannerDismissed = "gc.is_instalment_banner_dismissed",
  InvoiceSurveyBannerDismissed = "gc.is_invoice_banner_dismissed",
  PauseSubscriptionBannerDismissed = "gc.is_pause_subscription_banner_dismissed",
  FlexiblePayoutsOptInBannerDismissed = "gc.is_flexible_payouts_optin_banner_dismissed",
  FlexiblePayoutsOptOutBannerDismissed = "gc.is_flexible_payouts_optout_banner_dismissed",
}
