import {
  Box,
  Text,
  TypePreset,
  Select,
  Option,
  AlignItems,
  Space,
  Hint,
  FormFieldStatus,
  Glyph,
  ColorPreset,
  Icon,
  FieldSet,
  Legend,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";

import { FeedbackFormData } from "../../utils";

import { i18nMarkTranslate, ToTranslate } from "src/components/i18n";
import { useSendPageViewEvent } from "src/technical-integrations/segment/useSendPageViewEvent";
import { TrackingEvent } from "src/common/trackingEvents";

const ranks = Array.from({ length: 6 }, (_, i) => i + 1);

export const FeatureRankingStep: React.FC = () => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<FeedbackFormData>();

  useSendPageViewEvent(
    TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FEEDBACK_FORM_FEATURE_RANKING_STEP_VIEWED
  );

  const features = getValues("features");

  const isDuplicateValue = (currentIndex: number, value: string | number) => {
    if (!value) return false;

    return features?.some(
      (feature, idx) =>
        idx !== currentIndex && feature?.rank && feature.rank === value
    );
  };

  return (
    <FieldSet>
      <Legend>
        <Text preset={TypePreset.Heading_01} spaceBelow={2}>
          2.
          <Space layout="inline" h={0.5} />
          <ToTranslate>
            Please stack rank the following features in relevance to you (1 =
            most relevant, 6 = least relevant)
          </ToTranslate>
        </Text>
      </Legend>

      <Box spaceAbove={1.5}>
        {features.map(({ feature }, index) => (
          <Box key={feature} spaceBelow={0.5}>
            <Box layout="flex" alignItems={AlignItems.Center}>
              <Text preset={TypePreset.Body_01} css={{ flex: 1 }}>
                {feature}
              </Text>
              <Box width="89px" height="52px">
                <Select
                  {...register(`features.${index}.rank`, {
                    required: true,
                    validate: (value) => {
                      if (isDuplicateValue(index, value)) {
                        return i18nMarkTranslate(
                          "Each field must have a different value"
                        );
                      }
                      return undefined;
                    },
                  })}
                >
                  <Option value="">{""}</Option>
                  {ranks.map((rank) => (
                    <Option key={rank} value={rank}>
                      {rank}
                    </Option>
                  ))}
                </Select>
              </Box>
            </Box>
            <Space v={[0.5, null]} />
            {errors.features?.[index]?.rank && (
              <Hint
                status={FormFieldStatus.Danger}
                css={{
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
                leftAccessory={
                  <Icon
                    css={{ display: "flex" }}
                    size="12px"
                    name={Glyph.InfoCircle}
                    color={ColorPreset.AlertIconOnLight}
                  />
                }
              >
                <ToTranslate>
                  Each field must have a different value
                </ToTranslate>
              </Hint>
            )}
          </Box>
        ))}
      </Box>
    </FieldSet>
  );
};
