import {
  ButtonVariant,
  Dialog,
  ProgressBar,
  ProgressBarVariant,
  ColorScheme,
  Box,
  JustifyContent,
  Text,
  IconButton,
  Glyph,
  AlignItems,
  ButtonSize,
  Space,
} from "@gocardless/flux-react";
import { useState } from "react";

import { withChildrenTarget } from "../components/withChildrenTarget";
import { DialogCTAProps } from "../components/DialogCTA";
import { SuggestedActionType } from "../types";

import { TryDDPaymentsBodyWrapper, TryDDPaymentsFooter } from "./TryDDPayments";
import {
  TryIBPPaymentsBodyWrapper,
  TryIBPPaymentsFooter,
} from "./TryIBPPayments";
import {
  TryInstalmentsBodyWrapper,
  TryInstalmentsFooter,
} from "./TryInstalments";
import {
  TrySubscriptionTemplatesBodyWrapper,
  TrySubscriptionTemplatesFooter,
} from "./TrySubscriptionTemplates";

import { useCanIbpForDomesticCurrency } from "src/common/hooks/useCanIbp/useCanIbp";

type Step = [
  () => JSX.Element,
  (props: Pick<DialogCTAProps, "name">) => JSX.Element,
  SuggestedActionType,
];

const useSteps = () => {
  const { canIBP } = useCanIbpForDomesticCurrency();
  return [
    [
      TryDDPaymentsBodyWrapper,
      TryDDPaymentsFooter,
      SuggestedActionType.TryDDPayments,
    ],
    ...(canIBP
      ? [
          [
            TryIBPPaymentsBodyWrapper,
            TryIBPPaymentsFooter,
            SuggestedActionType.TryIBPPayments,
          ],
        ]
      : []),
    [
      TrySubscriptionTemplatesBodyWrapper,
      TrySubscriptionTemplatesFooter,
      SuggestedActionType.TrySubscriptionTemplates,
    ],
    [
      TryInstalmentsBodyWrapper,
      TryInstalmentsFooter,
      SuggestedActionType.TryInstalments,
    ],
  ] as Array<Step>;
};
export const CreateMorePayments = withChildrenTarget(({ ...props }) => {
  const [step, setStep] = useState<number>(0);
  const steps = useSteps();

  const [Body, Footer, eventName] = steps[step] as Step;
  const maxStep = Object.keys(steps).length - 1;
  return (
    <>
      <Dialog {...props} footer={<Footer name={eventName} />}>
        <Box height={["auto", null, 464]}>
          <Body />
        </Box>
        <Box
          spaceAbove={1}
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.SpaceBetween}
        >
          <Box width={90}>
            <ProgressBar
              variant={ProgressBarVariant.Segmented}
              colorScheme={ColorScheme.OnLight}
              value={step + 1}
              max={maxStep + 1}
              label={
                <Text id="stepProgressBarLabel">
                  {step + 1} of {maxStep + 1}
                </Text>
              }
              labelVisibility="hidden"
            />
          </Box>
          <Box>
            <IconButton
              size={ButtonSize.Sm}
              icon={Glyph.ChevronLeft}
              variant={ButtonVariant.SecondaryOnLight}
              disabled={step === 0}
              onClick={() => setStep(step - 1)}
              label="Previous"
            />
            <Space h={0.5} layout="inline" />
            <IconButton
              size={ButtonSize.Sm}
              icon={Glyph.ChevronRight}
              variant={ButtonVariant.SecondaryOnLight}
              disabled={step === maxStep}
              onClick={() => setStep(step + 1)}
              label="Next"
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
});
