import { useReportingContext } from "../components/ReportingContextProvider";

export const useFallbackDataPoints = () => {
  const { startDateFilter, endDateFilter } = useReportingContext();

  return [
    { date: new Date(startDateFilter), value: 0 },
    { date: new Date(endDateFilter), value: 0 },
  ];
};
