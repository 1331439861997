import {
  AlignItems,
  Box,
  ColorScheme,
  ProgressBar,
  ProgressBarColor,
  ProgressBarVariant,
} from "@gocardless/flux-react";

import { FeedbackStep, FeedbackSteps } from "./utils";
import { DataInsightsStep } from "./steps/DataInsightsStep/DataInsightsStep";
import { FeatureRankingStep } from "./steps/FeatureRankingStep/FeatureRankingStep";
import { PricingFeedbackStep } from "./steps/PricingFeedbackStep/PricingFeedbackStep";
import { AdditionalFeedbackStep } from "./steps/AdditionalFeedbackStep/AdditionalFeedbackStep";
import { WelcomeStep } from "./steps/WelcomeSteps/WelcomeStep";

const StepComponent = {
  [FeedbackSteps.WelcomeStep]: WelcomeStep,
  [FeedbackSteps.DataInsights]: DataInsightsStep,
  [FeedbackSteps.FeatureRanking]: FeatureRankingStep,
  [FeedbackSteps.PricingFeedback]: PricingFeedbackStep,
  [FeedbackSteps.AdditionalFeedback]: AdditionalFeedbackStep,
} as const;

interface FeedbackFormStepsProps {
  activeStep: FeedbackStep;
  totalSteps: number;
}

export const FeedbackFormSteps: React.FC<FeedbackFormStepsProps> = ({
  activeStep,
  totalSteps,
}) => {
  const CurrentStep = StepComponent[activeStep];

  return (
    <>
      <Box layout="flex" flexDirection="column" alignItems={AlignItems.Center}>
        {activeStep !== FeedbackSteps.WelcomeStep && (
          <Box width="100%" spaceBelow={1.5}>
            <ProgressBar
              label={null}
              variant={ProgressBarVariant.Segmented}
              color={ProgressBarColor.Info}
              colorScheme={ColorScheme.OnLight}
              value={activeStep}
              max={totalSteps - 1} // we don't want to include the welcome step
            />
          </Box>
        )}
        <CurrentStep />
      </Box>
    </>
  );
};
