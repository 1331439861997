import { useEffect, useRef } from "react";
import { TooltipProps } from "recharts";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";

import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";

/**
 * useChartTooltipEvent
 *
 * This hook tracks when a user hovers over a data point on a chart, to display a tooltip. A tracking event is sent when this happens.
 *
 * @param {object} props
 * @prop {boolean} isTooltipActive - whether the tooltip is currently active
 * @prop {object} trackingEvent - the event to send to Segment
 * @prop {number} maxEventTriggers - the maximum number of times the event should be sent. Defaults to 1.
 *
 */

export const useChartTooltipEvent = ({
  isTooltipActive = false,
  trackingEvent,
  maxEventTriggers = 1,
}: {
  isTooltipActive: TooltipProps<ValueType, NameType>["active"];
  trackingEvent?: {
    event: TrackingEvent;
    properties?: Record<string, unknown>;
  };
  maxEventTriggers?: number;
}) => {
  const eventTriggerCount = useRef(0);

  const { sendEvent } = useSegment();

  useEffect(() => {
    const shouldSendEvent =
      isTooltipActive &&
      !!trackingEvent?.event &&
      eventTriggerCount.current < maxEventTriggers;

    if (shouldSendEvent) {
      sendEvent(trackingEvent.event, trackingEvent.properties);
      eventTriggerCount.current += 1;
    }
  }, [
    isTooltipActive,
    trackingEvent?.event,
    trackingEvent?.properties,
    sendEvent,
    maxEventTriggers,
  ]);
};
