import { I18n } from "@lingui/core";
import { ReactNode } from "react";
import { t } from "@lingui/macro";
import { P } from "@gocardless/flux-react";

import { LEARN_MORE_BASE_URL } from "../utils/common";
import { ReportingChartType } from "../components/ChartWidget/utils";

export const useChartTypesMap: (i18n: I18n) => Record<
  ReportingChartType,
  {
    name: string;
    tooltipId: string;
    tooltipLabel: string;
    tooltipDescription: ReactNode;
    tooltipLink: string;
  }
> = (i18n) => ({
  collected_payments: {
    name: i18n._(t({ message: "Collected payments" })),
    tooltipId: "collectedPayments",
    tooltipLabel: i18n._(t({ message: "About the Collected payments chart" })),
    tooltipDescription: i18n._(
      t({
        message: "Total collected from your customers, before deducting fees",
      })
    ),
    tooltipLink: LEARN_MORE_BASE_URL,
  },
  total_paid_out: {
    name: i18n._(t({ message: "Total earnings" })),
    tooltipId: "totalEarnings",
    tooltipLabel: i18n._(t({ message: "About the Total earnings chart" })),
    tooltipDescription: i18n._(
      t({
        message:
          "Total paid into your bank account, after fees have been deducted",
      })
    ),
    tooltipLink: LEARN_MORE_BASE_URL,
  },
  failed_payment_attempts: {
    name: i18n._(t({ message: "Failed payment attempts" })),
    tooltipId: "failedPaymentAttempts",
    tooltipLabel: i18n._(
      t({ message: "About the Failed payment attempts chart" })
    ),
    tooltipDescription: (
      <>
        <P>
          {i18n._(
            t({
              message:
                "This is the total number of payment attempts that have failed during the defined time period.",
            })
          )}
        </P>
        <P>
          {i18n._(
            t({
              message:
                "As the same payment can fail and be retried multiple times, this is different from the total number of failed payments.",
            })
          )}
        </P>
      </>
    ),
    tooltipLink: LEARN_MORE_BASE_URL,
  },
});
