import { useReportingMetric } from "@gocardless/api/dashboard/reporting";
import { useLingui } from "@lingui/react";
import {
  Box,
  Color,
  ColorPreset,
  FontWeight,
  H3,
  MoneyText,
  P,
  Shimmer,
  Space,
  Text,
  Tooltip,
  TypographyStyleProps,
} from "@gocardless/flux-react";

import {
  convertBasisPointsToPercentage,
  ExperimentDataWidgetType,
  ExperimentDataWidgetTypes,
} from "../utils";
import { useReportingError } from "../../../hooks/useReportingError";
import { useExperimentDataWidgetTypesMap } from "../hooks/useExperimentDataWidgetTypesMap";

import { useDefaultCreditorId } from "src/libraries/creditor/hooks";
import { Currency } from "src/common/currencies";
import { useOrganisation } from "src/libraries/organisation/hooks";
import { useSegment } from "src/technical-integrations/segment/useSegment";

export interface ExperimentDataWidgetProps {
  type: ExperimentDataWidgetType;
}

export const ExperimentDataWidget: React.FC<ExperimentDataWidgetProps> = ({
  type,
}) => {
  const defaultCreditorId = useDefaultCreditorId();

  const { data, error, isLoading, key } = useReportingMetric(type, {
    creditor: defaultCreditorId ?? "",
  });

  const { i18n } = useLingui();

  useReportingError(error, key);

  const currency = (useOrganisation()?.domestic_currency ??
    Currency.Gbp) as Currency;

  const dataWidgetTypesMap = useExperimentDataWidgetTypesMap();

  const { sendEvent } = useSegment();

  const getFormattedMetricValue = () => {
    const textProps: TypographyStyleProps = {
      layout: "block",
      size: 7,
      weight: FontWeight.SemiBold,
    };
    switch (type) {
      case ExperimentDataWidgetTypes.RecentFailedPayments: {
        const value = data?.failed_payments?.failed_payments ?? 0;

        return <Text {...textProps}>{i18n.number(value)}</Text>;
      }
      case ExperimentDataWidgetTypes.PaymentVolumeMoMTrend: {
        const value =
          (data?.payment_volume_mom_trend?.[currency]?.value ?? 0) / 100;

        return (
          <Text {...textProps}>
            {i18n.number(convertBasisPointsToPercentage(value), {
              style: "percent",
              maximumFractionDigits: 1,
            })}
          </Text>
        );
      }
      case ExperimentDataWidgetTypes.PaymentFailureMoMTrend: {
        const value =
          (data?.payment_failure_mom_trend?.[currency]?.value ?? 0) / 100;

        return (
          <Text {...textProps}>
            {i18n.number(convertBasisPointsToPercentage(value), {
              style: "percent",
              maximumFractionDigits: 1,
            })}
          </Text>
        );
      }
      case ExperimentDataWidgetTypes.AverageFailureRate: {
        const value =
          (data?.average_failure_rate?.[currency]?.value ?? 0) / 100;

        return (
          <Text {...textProps}>
            {i18n.number(convertBasisPointsToPercentage(value), {
              style: "percent",
              maximumFractionDigits: 1,
            })}
          </Text>
        );
      }
      case ExperimentDataWidgetTypes.AverageMonthlyEarnings: {
        const value =
          (data?.average_monthly_earnings?.[currency]?.value ?? 0) / 100;

        return (
          <MoneyText
            amount={value}
            currency={currency}
            locale={i18n.locale}
            size={7}
            weight={FontWeight.SemiBold}
          />
        );
      }
      case ExperimentDataWidgetTypes.TotalEarnings: {
        const value = (data?.total_earnings?.[currency]?.value ?? 0) / 100;

        return (
          <MoneyText
            amount={value}
            currency={currency}
            locale={i18n.locale}
            size={7}
            weight={FontWeight.SemiBold}
          />
        );
      }
    }
  };

  const formattedMetricValue = getFormattedMetricValue();

  const { name, tooltipDescription, tooltipId, tooltipLabel, trackingEvent } =
    dataWidgetTypesMap[type];

  return (
    <Box
      bg={Color.White}
      borderColor={ColorPreset.BorderOnLight_04}
      borderRadius={1}
      borderWidth={1}
      flexGrow={1}
      gutterH={1.5}
      gutterV={1.5}
      height="100%"
    >
      <Box layout="flex">
        <H3 size={[4, 3]} spaceBelow={0.5}>
          {name}
        </H3>
        <Space layout="inline" h={0.5} />
        <Text
          onClick={() => {
            sendEvent(trackingEvent.event, trackingEvent.properties);
          }}
        >
          <Tooltip
            positionStrategy="absolute"
            tooltipId={tooltipId}
            message={<P>{tooltipDescription}</P>}
          >
            {tooltipLabel}
          </Tooltip>
        </Text>
      </Box>
      {isLoading ? (
        <Shimmer
          borderRadius={0.5}
          height="24px"
          spaceAbove={0.5}
          spaceBelow={1}
        />
      ) : (
        <>{formattedMetricValue}</>
      )}
    </Box>
  );
};
