import {
  Banner,
  BannerVariant,
  BannerLeftAccessoryType,
  BannerStatus,
  Space,
  Button,
  ButtonVariant,
  ButtonSize,
  Box,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";

import { routerPush, Route } from "src/common/routing";
import { PackageSelectionCtaSource } from "src/common/trackingEventAttributes";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegmentForSetup } from "src/components/routes/Setup/common/hooks/useSegmentForSetup";
import { getBackTo } from "src/components/routes/Setup/package-selection/v3/PackageSelectionExperiment/utils/useRedirectionRoutes";

interface PackageSelectionSkippedBannerProps {
  description: React.ReactNode;
}

export const PackageSelectionSkippedBanner: React.FC<
  PackageSelectionSkippedBannerProps
> = ({ description }) => {
  const { sendEvent } = useSegmentForSetup();
  const router = useRouter();
  const backTo = getBackTo(router.pathname);
  const extraQueryParams = router.query;
  return (
    <Box>
      <Banner
        variant={BannerVariant.Light}
        leftAccessory={{
          type: BannerLeftAccessoryType.Status,
          status: BannerStatus.Warning,
        }}
        title="You’ll need to choose a package plan first"
      >
        {description}
        <Space v={1} />
        <Button
          variant={ButtonVariant.PrimaryOnLight}
          size={ButtonSize.Sm}
          onClick={async () => {
            routerPush({
              route: Route.PackageSelectionEdit,
              queryParams: { redirectTo: backTo, ...extraQueryParams },
            });

            sendEvent(
              TrackingEvent.MERCHANT_ONBOARDING_PACKAGE_SELECTION_CTA_CLICKED,
              {
                page: router.pathname,
                source:
                  PackageSelectionCtaSource.PACKAGE_SELECTION_SKIPPED_BANNER,
              }
            );
          }}
        >
          <Trans>See plans</Trans>
        </Button>
      </Banner>
      <Space v={2} />
    </Box>
  );
};
