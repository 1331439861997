import {
  AlignItems,
  Box,
  ColorPreset,
  Dropdown,
  FieldSet,
  FontWeight,
  Glyph,
  H3,
  Icon,
  Menu,
  MenuRole,
  Radio,
  Space,
  Text,
  ToggleControlPosition,
  Tooltip,
  Shimmer,
  PlainButton,
  HoverEffect,
  PlainLink,
  P,
} from "@gocardless/flux-react";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import AreaChart from "../charts/AreaChart/AreaChart";
import { useMenuStyle } from "../../utils/style";
import { useReportingContext } from "../ReportingContextProvider";
import { useChartTypesMap } from "../../hooks/useChartTypesMap";
import { useFallbackDataPoints } from "../../hooks/useFallbackDataPoints";

import {
  ReportingChartType,
  getSelectedChartTypeEvent,
  getChartTypeTooltipEvent,
} from "./utils";
import { useFetchTimeSeriesData } from "./useFetchTimeSeriesData";

import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { formatCurrencyAndSplit } from "src/utils/formatCurrencyAndSplit";

const MENU_ID = "chart-type-list";
const SELECTED_CHART_ID = "selected-chart";

export const ChartWidget = () => {
  const { selectedChartType, setSelectedChartType, selectedCurrency } =
    useReportingContext();

  const {
    data: metricData,
    dataPointsSum,
    isLoading,
    isValidating,
  } = useFetchTimeSeriesData({
    chartType: selectedChartType,
  });

  const menuStyle = useMenuStyle();
  const { i18n } = useLingui();
  const chartTypesMap = useChartTypesMap(i18n);

  const { sendEvent } = useSegment();

  const fallbackDataPoints = useFallbackDataPoints();

  const locale = i18n.locale;

  const [value, fraction] = formatCurrencyAndSplit({
    locale,
    value: dataPointsSum,
    separateBy: "decimal",
    options: {
      style: "decimal",
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  });

  const handleChangeChartType: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const chartType = event.target.value as ReportingChartType;

    setSelectedChartType(chartType);
    sendEvent(getSelectedChartTypeEvent(chartType));
  };

  const isDataPresent = metricData?.length > 0;

  return (
    <Box
      bg={ColorPreset.BackgroundLight_01}
      borderColor={ColorPreset.BorderOnLight_04}
      borderRadius={1}
      borderWidth={1}
      gutterH={[1.5, 2]}
      gutterV={[1.5, 2]}
      layout="flex"
      flexDirection="column"
      flexGrow={1}
      height="100%"
    >
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Dropdown
            controls={MENU_ID}
            trigger={(triggerProps) => (
              <PlainButton
                {...triggerProps}
                disabled={isLoading || isValidating}
                effect={HoverEffect.TextDecoration}
                aria-label={i18n._(t({ message: "Select chart type" }))}
                onClick={() => {
                  triggerProps.onClick();
                  sendEvent(
                    TrackingEvent.MERCHANT_DASHBOARD_REPORTING_CHART_TYPE_DROPDOWN_CLICKED
                  );
                }}
              >
                <Box layout="flex" alignItems={AlignItems.Center}>
                  <H3 id={SELECTED_CHART_ID} size={[4, 3]}>
                    {chartTypesMap[selectedChartType].name}
                  </H3>
                  <Space h={0.5} layout="inline" />
                  <Icon name={Glyph.ChevronDown} size="12px" />
                </Box>
              </PlainButton>
            )}
          >
            {({ close }) => (
              <Menu.Root id={MENU_ID} role={MenuRole.Menu}>
                <FieldSet>
                  <Box css={menuStyle} layout="flex" flexDirection="column">
                    {Object.keys(chartTypesMap).map((chartType) => (
                      <Radio
                        key={chartType}
                        checked={selectedChartType === chartType}
                        controlPosition={ToggleControlPosition.End}
                        name="chartType"
                        onChange={(event) => {
                          handleChangeChartType(event);
                          close();
                        }}
                        value={chartType}
                      >
                        {chartTypesMap[chartType as ReportingChartType].name}
                      </Radio>
                    ))}
                  </Box>
                </FieldSet>
              </Menu.Root>
            )}
          </Dropdown>
          <Space h={0.5} layout="inline" />
          <Text
            onClick={() =>
              sendEvent(getChartTypeTooltipEvent(selectedChartType))
            }
          >
            <Tooltip
              tooltipId={chartTypesMap[selectedChartType].tooltipId}
              message={
                <>
                  <P>{chartTypesMap[selectedChartType].tooltipDescription}</P>

                  <Space v={1.5} />

                  <PlainLink
                    href={chartTypesMap[selectedChartType].tooltipLink}
                    target="_blank"
                    textDecoration="underline"
                    weight={FontWeight.SemiBold}
                  >
                    <Trans id="Learn more">Learn more</Trans>
                  </PlainLink>
                </>
              }
            >
              {chartTypesMap[selectedChartType].tooltipLabel}
            </Tooltip>
          </Text>
        </Box>
      </Box>

      {isLoading || isValidating ? (
        <Shimmer
          borderRadius={0.5}
          maxWidth="309px"
          height="24px"
          spaceAbove={0.5}
          spaceBelow={1}
        />
      ) : (
        <Text
          layout="block"
          size={[7, 9]}
          spaceBelow={1.5}
          weight={FontWeight.SemiBold}
        >
          {selectedChartType === "failed_payment_attempts" ? (
            i18n.number(dataPointsSum)
          ) : (
            <>
              {value}
              <Text size={[5, 6]} lineHeight={5}>
                {fraction} {selectedCurrency}
              </Text>
            </>
          )}
        </Text>
      )}
      <Box height={[300, 400]}>
        <AreaChart
          ariaLabelledby={SELECTED_CHART_ID}
          currency={
            selectedChartType === "failed_payment_attempts"
              ? undefined
              : selectedCurrency
          }
          data={isDataPresent ? metricData : fallbackDataPoints}
          xDataKey="date"
          yDataKey="value"
          showTooltips={isDataPresent}
        />
      </Box>
    </Box>
  );
};
