import {
  AlignItems,
  Box,
  ColorScheme,
  Space,
  Tag,
  TagColor,
  Text,
  XYGrid,
} from "@gocardless/flux-react";

import {
  ExperimentChartWidgetTypes,
  ExperimentDataWidgetTypes,
} from "../utils";
import { ExperimentChartWidget } from "../ExperimentChartWidget/ExperimentChartWidget";
import { ExperimentDataWidget } from "../ExperimentDataWidget/ExperimentDataWidget";

import { HomeSectionHeader } from "src/components/HomeSectionHeader/HomeSectionHeader";

const ChartWidgetTypes = {
  MonthlyFailureRate: ExperimentChartWidgetTypes.MonthlyFailureRate,
  PaymentFailureReasons: ExperimentChartWidgetTypes.PaymentFailureReasons,
  SimilarBusinessFailureRates:
    ExperimentChartWidgetTypes.SimilarBusinessFailureRates,
} as const;

const DataWidgetTypes = {
  RecentFailedPayments: ExperimentDataWidgetTypes.RecentFailedPayments,
  AverageFailureRate: ExperimentDataWidgetTypes.AverageFailureRate,
  PaymentFailureMoMTrend: ExperimentDataWidgetTypes.PaymentFailureMoMTrend,
} as const;

export const PaymentInsightsSection: React.FC = () => {
  const {
    MonthlyFailureRate,
    PaymentFailureReasons,
    SimilarBusinessFailureRates,
  } = ChartWidgetTypes;

  const { RecentFailedPayments, AverageFailureRate, PaymentFailureMoMTrend } =
    DataWidgetTypes;

  return (
    <Box gridArea="paymentInsights">
      <Box layout="flex" alignItems={AlignItems.Center} spaceBelow={2}>
        <Tag color={TagColor.Info} colorScheme={ColorScheme.OnDark}>
          <Text size={1}>New</Text>
        </Tag>
        <Space h={0.75} layout="inline" />
        <HomeSectionHeader>Payment failure insights</HomeSectionHeader>
      </Box>

      <XYGrid
        columnGap={2}
        rowGap={2}
        templateColumns={["1fr", "repeat(2, 1fr)", null, "repeat(3, 1fr)"]}
        autoColumns="1fr"
        templateAreas={[
          `
            "${MonthlyFailureRate}"
            "${PaymentFailureReasons}"
            "${SimilarBusinessFailureRates}"
            "${RecentFailedPayments}"
            "${AverageFailureRate}"
            "${PaymentFailureMoMTrend}"
          `,
          `
            "${MonthlyFailureRate} ${PaymentFailureReasons}"
            "${SimilarBusinessFailureRates} ${RecentFailedPayments}"
            "${SimilarBusinessFailureRates} ${AverageFailureRate}"
            "${SimilarBusinessFailureRates} ${PaymentFailureMoMTrend}"
          `,
          null,
          `
            "${MonthlyFailureRate} ${PaymentFailureReasons} ${SimilarBusinessFailureRates}"
            "${RecentFailedPayments} ${AverageFailureRate} ${PaymentFailureMoMTrend}"
          `,
        ]}
      >
        {Object.values(ChartWidgetTypes).map((type) => (
          <Box key={type} gridArea={type}>
            <ExperimentChartWidget type={type} />
          </Box>
        ))}

        {Object.values(DataWidgetTypes).map((type) => (
          <Box key={type} gridArea={type}>
            <ExperimentDataWidget type={type} />
          </Box>
        ))}
      </XYGrid>
    </Box>
  );
};
