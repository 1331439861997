import {
  Dialog,
  P,
  Color,
  TypePreset,
  LI,
  Text,
  OL,
  Glyph,
  PlainLink,
  Icon,
  FontWeight,
  Interpose,
  Space,
  HoverEffect,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Trans } from "@lingui/macro";

import { DialogBodyWrapper } from "../components/DialogBodyWrapper";
import { withChildrenTarget } from "../components/withChildrenTarget";
import { DialogCTA, DialogCTAProps } from "../components/DialogCTA";

import RetryFailedPaymentsDialogSVG from "src/assets/svg/suggested-actions-dialog-retry-failed-payments-dialog.svg";
import { Route } from "src/common/routing";

export const RetryFailedPaymentsFooter = ({
  name,
}: Pick<DialogCTAProps, "name">) => (
  <DialogCTA
    route={Route.Payments}
    queryParams={{ status: "failed" }}
    name={name}
  />
);

export const RetryFailedPayments = withChildrenTarget(({ ...props }) => (
  <>
    <Dialog {...props} footer={<RetryFailedPaymentsFooter name={props.id} />}>
      <DialogBodyWrapper
        bg={Color.Alert_50}
        image={
          <Image
            src={RetryFailedPaymentsDialogSVG}
            alt="payments-retry"
            width={256}
            height={137}
          />
        }
        title={<Trans>Retry your failed payments</Trans>}
      >
        <Interpose node={<Space v={1} />}>
          <P preset={TypePreset.Body_01}>
            <Trans>
              {`Payments commonly fail because there are insufficient funds in a
              customers' bank account. These can easily be retried at another
              time.`}
            </Trans>
          </P>
          <P>
            <Text weight={FontWeight.Bold}>
              <Trans>Follow these simple steps:</Trans>
            </Text>
          </P>
          <OL>
            <LI>
              <Text preset={TypePreset.Body_01}>
                <Trans>
                  Find your failed payment and open the payment details
                </Trans>
              </Text>
            </LI>
            <LI>
              <Text preset={TypePreset.Body_01}>
                <Trans>
                  Click <Text weight={FontWeight.SemiBold}>Retry payment</Text>
                </Trans>
              </Text>
            </LI>
          </OL>

          <PlainLink
            href="https://hub.gocardless.com/s/article/Retry-a-failed-payment"
            target="_blank"
            effect={HoverEffect.TextDecoration}
          >
            <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
              <Trans>View the help article</Trans>
            </Text>{" "}
            <Icon name={Glyph.Export} size="12px" />
          </PlainLink>
        </Interpose>
      </DialogBodyWrapper>
    </Dialog>
  </>
));
