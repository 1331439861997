import { useReportingExperiment } from "../components/experiments/hooks/useReportingExperiment";

import { useOrganisation } from "src/libraries/organisation/hooks";

export const useReportingTemplateAreas = () => {
  const outboundPaymentsEnabled =
    useOrganisation()?.outbound_payments_enabled ?? false;

  const { isFutureDataExperimentEnabled, isUpsellExperimentEnabled } =
    useReportingExperiment();

  const isExperimentEnabled =
    isFutureDataExperimentEnabled || isUpsellExperimentEnabled;

  const getTemplateAreas = () => {
    if (isFutureDataExperimentEnabled) {
      return [
        `
         ${
           outboundPaymentsEnabled
             ? `
          "outbound"
          "spacer"
          `
             : ""
         }
        "collection"
        "revenueInsights"
        "paymentInsights"
        "customerInsights"
        `,
        `
        ${
          outboundPaymentsEnabled
            ? `
          "outbound outbound"
          "spacer spacer"
          `
            : ""
        }
        "collection collection"
        "revenueInsights revenueInsights"
        "paymentInsights paymentInsights"
        "customerInsights customerInsights"
        `,
        null,
        `
        ${
          outboundPaymentsEnabled
            ? `
          "outbound outbound outbound"
          "spacer spacer spacer"
          `
            : ""
        }
        "collection collection collection"
        "revenueInsights revenueInsights revenueInsights"
        "paymentInsights paymentInsights paymentInsights"
        "customerInsights customerInsights customerInsights"
        `,
      ];
    }

    if (isUpsellExperimentEnabled) {
      return [
        `
        ${
          outboundPaymentsEnabled
            ? `
          "outbound"
          "spacer"
          `
            : ""
        }
        "collection"
        "overview"
        "accountHealth"
        `,
        `
        ${
          outboundPaymentsEnabled
            ? `
          "outbound outbound"
          "spacer spacer"
          `
            : ""
        }
        "collection collection"
        "overview overview"
        "accountHealth accountHealth"
        `,
        null,
        `
        ${
          outboundPaymentsEnabled
            ? `
          "outbound outbound outbound"
          "spacer spacer spacer"
          `
            : ""
        }
        "collection collection collection"
        "overview overview overview"
        "accountHealth accountHealth accountHealth"
        `,
      ];
    }

    return [
      `
      ${
        outboundPaymentsEnabled
          ? `
        "outbound"
        "spacer"
        `
          : ""
      }
      "collection"
      "accountHealth"
      "."
      "overview"
      `,
      `
      ${
        outboundPaymentsEnabled
          ? `
        "outbound outbound"
        "spacer spacer"
        `
          : ""
      }
      "collection collection"
      "accountHealth accountHealth"
      ". ."
      "overview overview"
      `,
      null,
      `
      ${
        outboundPaymentsEnabled
          ? `
        "outbound outbound outbound"
        "spacer spacer spacer"
        `
          : ""
      }
      "collection collection collection"
      ${outboundPaymentsEnabled || isExperimentEnabled ? "" : `". . ."`}
      "overview overview accountHealth"
      `,
    ];
  };

  const templateAreas = getTemplateAreas();

  return {
    templateAreas,
  };
};
