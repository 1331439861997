import { TrackingEvent } from "src/common/trackingEvents";

export const reportingChartTypes = {
  collectedPayments: "collected_payments",
  totalPaidOut: "total_paid_out",
  failedPaymentAttempts: "failed_payment_attempts",
} as const;

export interface DataPoint {
  date: Date;
  value: number;
}

export type ReportingChartType =
  (typeof reportingChartTypes)[keyof typeof reportingChartTypes];

export const getSelectedChartTypeEvent = (chartType: ReportingChartType) => {
  switch (chartType) {
    case "collected_payments":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_COLLECTED_PAYMENTS_CHART_TYPE_SELECTED;
    case "failed_payment_attempts":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FAILED_PAYMENT_ATTEMPTS_CHART_TYPE_SELECTED;
    case "total_paid_out":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_TOTAL_EARNINGS_CHART_TYPE_SELECTED;
  }
};

export const getChartTypeTooltipEvent = (chartType: ReportingChartType) => {
  switch (chartType) {
    case "collected_payments":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_COLLECTED_PAYMENTS_CHART_WIDGET_TOOLTIP_CLICKED;
    case "failed_payment_attempts":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FAILED_PAYMENT_ATTEMPTS_TOOLTIP_CLICKED;
    case "total_paid_out":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_TOTAL_EARNINGS_TOOLTIP_CLICKED;
  }
};
