import { useEffect, useState } from "react";
import { useUpgradeList } from "@gocardless/api/dashboard/upgrade";
import { Trans } from "@lingui/macro";

import { PackageAddonUpgradeType } from "../packages/package/addons";
import {
  PricingPackagesV3,
  usePackageStateV3,
} from "../packages/package/usePackageStateV3/usePackageStateV3";
import { getBasePackage } from "../packages/package/usePackageStateV3/getBasePackage/getBasePackageState";

import {
  doUpgradesMatchPackage,
  getActiveUpgrades,
  getUpgradesToAdd,
} from "./upgradeHelpers";
import { useUpgradeRequest } from "./useUpgradeRequest";

import { useOrganisation } from "src/libraries/organisation/hooks";
import { useToastNotification } from "src/hooks/useToastNotification";

export interface SelectedUpgrades {
  own_sun: boolean;
  custom_notifications: boolean;
  custom_payment_pages: boolean;
}

export const useUpgrades = () => {
  const organisation = useOrganisation();
  const { data: upgradeList } = useUpgradeList();
  const { activeBasePackage, merchantRequestedPackage } = usePackageStateV3();
  const { triggerErrorNotification } = useToastNotification();
  const { createSingleUpgrade, createMultipleUpgrades } = useUpgradeRequest();

  const firstTimePackageSelection = !merchantRequestedPackage;

  const [selectedUpgrades, setSelectedUpgrades] = useState<SelectedUpgrades>({
    own_sun: false,
    custom_notifications: false,
    custom_payment_pages: false,
  });

  const [cachedSelectedUpgrades, setCachedSelectedUpgrades] =
    useState<SelectedUpgrades>({
      own_sun: false,
      custom_notifications: false,
      custom_payment_pages: false,
    });

  const updateSelectedUpgradeState = (
    activeUpgradeTypes?: PackageAddonUpgradeType[]
  ) => {
    if (!activeUpgradeTypes?.length) return;

    const temporaryState = { ...selectedUpgrades };

    activeUpgradeTypes?.forEach((activeUpgrade) => {
      if (activeUpgrade) {
        temporaryState[activeUpgrade] = true;
      }
    });

    setSelectedUpgrades(temporaryState);
  };

  useEffect(() => {
    const activeUpgrades = getActiveUpgrades(
      upgradeList?.upgrades,
      organisation
    );
    updateSelectedUpgradeState(activeUpgrades as PackageAddonUpgradeType[]);
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upgradeList?.upgrades, organisation]);

  useEffect(() => {
    // Creating a single upgrade can only be made by merchants coming back to edit their previously selected package.
    async function updateUpgrade() {
      if (!firstTimePackageSelection) {
        const updatedUpgrades = getUpgradesToAdd(
          selectedUpgrades,
          upgradeList?.upgrades,
          organisation
        );
        await createSingleUpgrade(updatedUpgrades);
      }
    }

    updateUpgrade();
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUpgrades, upgradeList?.upgrades, firstTimePackageSelection]);

  const selectUpgrade = (upgradeType: PackageAddonUpgradeType) => {
    const selected = !!selectedUpgrades[upgradeType];

    const update = {
      ...selectedUpgrades,
      [upgradeType]: !selected,
    };

    // Only allow updating upgrade state for new merchants or valid upgrade choices that are associated
    // with the current package of existing merchants.
    if (
      firstTimePackageSelection ||
      doUpgradesMatchPackage(activeBasePackage, update)
    ) {
      setSelectedUpgrades(update);
    } else {
      // This is for existing merchants who select an upgrade that isn't supported by their current package.
      triggerErrorNotification({
        message: (
          <Trans id="setup.package-selection-v3.use-upgrades.plan-error">
            Please change packages to enable this upgrade
          </Trans>
        ),
      });
    }
  };

  const isValidUpgradeSubmission = (pkg: PricingPackagesV3) => {
    const basePackage = getBasePackage(pkg);
    return doUpgradesMatchPackage(basePackage, selectedUpgrades);
  };

  const submitUpgrades = async (
    onSuccess: () => void,
    upgrades?: SelectedUpgrades
  ) => {
    const updatedUpgrades = getUpgradesToAdd(
      upgrades ?? selectedUpgrades,
      upgradeList?.upgrades
    );

    await createMultipleUpgrades(updatedUpgrades, onSuccess);
  };

  const getUpgrade = (upgrade_type: string) =>
    upgradeList?.upgrades?.find(
      (upgrade) => upgrade.upgrade_type === upgrade_type
    );

  return {
    selectedUpgrades,
    cachedSelectedUpgrades,
    selectUpgrade,
    submitUpgrades,
    isValidUpgradeSubmission,
    getUpgrade,
    setSelectedUpgrades,
    setCachedSelectedUpgrades,
  };
};
