import {
  ExperimentDataWidgetType,
  ExperimentDataWidgetTypes,
  ExperimentNames,
} from "../utils";

import { TrackingEvent } from "src/common/trackingEvents";

export const useExperimentDataWidgetTypesMap: () => Record<
  ExperimentDataWidgetType,
  {
    name: string;
    tooltipDescription: string;
    tooltipId: string;
    tooltipLabel: string;
    trackingEvent: {
      event: TrackingEvent;
      properties?: Record<string, unknown>;
    };
  }
> = () => ({
  [ExperimentDataWidgetTypes.AverageMonthlyEarnings]: {
    name: "Average monthly earnings",
    tooltipId: "averageMonthlyEarnings",
    tooltipLabel: "About Average monthly earnings",
    tooltipDescription:
      "The average of your total collected payment value over the last 12 months, pre fee deduction.",
    trackingEvent: {
      event:
        TrackingEvent.MERCHANT_DASHBOARD_REPORTING_AVERAGE_MONTHLY_EARNINGS_TOOLTIP_CLICKED,
      properties: {
        experiment_name: ExperimentNames.FutureData,
        metric_name: ExperimentDataWidgetTypes.AverageMonthlyEarnings,
      },
    },
  },
  [ExperimentDataWidgetTypes.PaymentVolumeMoMTrend]: {
    name: "Payment volume MoM trend",
    tooltipId: "paymentVolumeMoM",
    tooltipLabel: "About Payment volume MoM trend",
    tooltipDescription:
      "The average month on month % change of your payment volumes over the last 12 months.",
    trackingEvent: {
      event:
        TrackingEvent.MERCHANT_DASHBOARD_REPORTING_PAYMENT_VOLUME_MOM_TREND_TOOLTIP_CLICKED,
      properties: {
        experiment_name: ExperimentNames.FutureData,
        metric_name: ExperimentDataWidgetTypes.PaymentVolumeMoMTrend,
      },
    },
  },
  [ExperimentDataWidgetTypes.TotalEarnings]: {
    name: "Total earnings",
    tooltipId: "totalEarnings",
    tooltipLabel: "About Total earnings",
    tooltipDescription:
      "The total amount of payments collected over the past 12 months, pre fee deduction.",
    trackingEvent: {
      event:
        TrackingEvent.MERCHANT_DASHBOARD_REPORTING_TOTAL_EARNINGS_TOOLTIP_CLICKED,
      properties: {
        experiment_name: ExperimentNames.FutureData,
        metric_name: ExperimentDataWidgetTypes.TotalEarnings,
      },
    },
  },
  [ExperimentDataWidgetTypes.RecentFailedPayments]: {
    name: "Recent failed payments",
    tooltipId: "recentFailedPayments",
    tooltipLabel: "About Recent failed payments",
    tooltipDescription: "The number of payments that failed today.",
    trackingEvent: {
      event:
        TrackingEvent.MERCHANT_DASHBOARD_REPORTING_RECENT_FAILED_PAYMENTS_TOOLTIP_CLICKED,
      properties: {
        experiment_name: ExperimentNames.FutureData,
        metric_name: ExperimentDataWidgetTypes.RecentFailedPayments,
      },
    },
  },
  [ExperimentDataWidgetTypes.AverageFailureRate]: {
    name: "Average failure rate",
    tooltipId: "averageFailureRate",
    tooltipLabel: "About Average failure rate",
    tooltipDescription:
      "Your average payment failure rate over the last 12 months.",
    trackingEvent: {
      event:
        TrackingEvent.MERCHANT_DASHBOARD_REPORTING_AVERAGE_FAILURE_RATE_TOOLTIP_CLICKED,
      properties: {
        experiment_name: ExperimentNames.FutureData,
        metric_name: ExperimentDataWidgetTypes.AverageFailureRate,
      },
    },
  },
  [ExperimentDataWidgetTypes.PaymentFailureMoMTrend]: {
    name: "Payment failure MoM trend",
    tooltipId: "paymentFailureMoM",
    tooltipLabel: "About Payment failure MoM",
    tooltipDescription:
      "The average month on month percentage change of your failure rate over the last 12 months.",
    trackingEvent: {
      event:
        TrackingEvent.MERCHANT_DASHBOARD_REPORTING_PAYMENT_FAILURE_MOM_TREND_TOOLTIP_CLICKED,
      properties: {
        experiment_name: ExperimentNames.FutureData,
        metric_name: ExperimentDataWidgetTypes.PaymentFailureMoMTrend,
      },
    },
  },
});
