import {
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Dialog,
  Interpose,
  PlainLink,
  Space,
  LI,
  P,
  H2,
  Link,
  UL,
  Visibility,
  TypePreset,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Trans } from "@lingui/macro";
import { useEffect } from "react";

import { ExperimentNames } from "../utils";

import IBPImage from "src/assets/svg/instant-bank-pay.svg";
import IBPImageMobile from "src/assets/svg/instant-bank-pay-mobile.svg";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";

const trackingContext = "IBP education dialog";

interface InstantBankPaymentEducationDialogProp {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const InstantBankPaymentEducationDialog: React.FC<
  InstantBankPaymentEducationDialogProp
> = ({ open, onClose, onSubmit }) => {
  const { sendEvent } = useSegment();

  useEffect(() => {
    if (open) {
      sendEvent(TrackingEvent.MERCHANT_DASHBOARD_REPORTING_IBP_DIALOG_VIEWED, {
        ctx: trackingContext,
        experiment_name: ExperimentNames.UpsellIBP,
      });
    }
  }, [open, sendEvent]);

  return (
    <Dialog
      open={open}
      bodyGutterV={2}
      aria-labelledby="instantBankPaymentsEducationDialogTitle"
      closeAction={{
        label: <Trans id="Close">Close</Trans>,
        onClose: () => {
          onClose();
          sendEvent(TrackingEvent.MERCHANT_DASHBOARD_REPORTING_DIALOG_CLOSED, {
            ctx: trackingContext,
            experiment_name: ExperimentNames.UpsellIBP,
          });
        },
      }}
      header={
        <H2
          preset={TypePreset.Heading_02}
          id="instantBankPaymentsEducationDialogTitle"
        >
          Collect instant bank payments
        </H2>
      }
      footer={
        <ButtonGroup arrangement={["column-center", "row-end-reverse"]}>
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            size={[ButtonSize.Lg, ButtonSize.Sm]}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            onClick={() => {
              onSubmit();
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_SET_UP_INSTANT_PAYMENTS_CTA_CLICKED,
                {
                  ctx: trackingContext,
                  experiment_name: ExperimentNames.UpsellIBP,
                }
              );
            }}
          >
            Set up Instant payments
          </Button>
          <Link
            href="https://support.gocardless.com/hc/articles/4411785453714-Instant-Bank-Pay"
            target="_blank"
            rel="noopener"
            size={[ButtonSize.Lg, ButtonSize.Sm]}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            variant={ButtonVariant.TextOnLight}
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LEARN_MORE_BUTTON_CLICKED,
                {
                  ctx: trackingContext,
                  experiment_name: ExperimentNames.UpsellIBP,
                }
              );
            }}
          >
            Learn more
          </Link>
        </ButtonGroup>
      }
    >
      <Visibility visible={["none", "block"]}>
        <Image
          src={IBPImage}
          alt=""
          width={0}
          height={0}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </Visibility>
      <Visibility visible={["block", "none"]}>
        <Image
          src={IBPImageMobile}
          alt=""
          width={0}
          height={0}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </Visibility>
      <Space v={2} />
      <Interpose node={<Space v={1} />}>
        <P size={2}>
          Powered by the latest open banking technology, Instant Bank Pay allows
          you to:
        </P>

        <UL size={2}>
          <LI>Charge customers immediately</LI>
          <LI>Receive instant payment confirmation</LI>
          <LI>Get funds paid out on the same day</LI>
        </UL>

        <P size={2}>
          Effortlessly collect one-off payments, initial payments before
          recurring billing, or recover missed or failed payments. Customers can
          easily authorise payments through their banking app for a smooth and
          secure experience.
        </P>
        <P size={2}>
          Further questions?{" "}
          <PlainLink
            href="https://manage.gocardless.com/forms/zendesk/134125"
            decoration="underline"
            target="_blank"
            rel="noopener"
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_CONTACT_SUPPORT_CTA_CLICKED,
                {
                  ctx: trackingContext,
                  experiment_name: ExperimentNames.UpsellIBP,
                }
              );
            }}
          >
            Contact support
          </PlainLink>
        </P>
      </Interpose>
    </Dialog>
  );
};
