import { FC } from "react";
import {
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Dialog,
  DialogProps,
  FontWeight,
  Interpose,
  P,
  PlainLink,
  Space,
  Text,
} from "@gocardless/flux-react";

import { ToTranslate } from "src/components/i18n";
import { LinkBuilder, Route } from "src/components/routing";

interface MoveCustomersDialog extends Pick<DialogProps, "open"> {
  onClose: () => void;
}

export const MoveCustomersDialog: FC<MoveCustomersDialog> = ({
  open,
  onClose,
}) => (
  <Dialog
    as="div"
    open={open}
    header={{
      content: (
        <Text weight={FontWeight.SemiBold}>
          <ToTranslate>Move your existing customers to GoCardless</ToTranslate>
        </Text>
      ),
    }}
    footer={{
      content: (
        <ButtonGroup arrangement={["column-center", null, "row-end-reverse"]}>
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            size={ButtonSize.Sm}
            layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
            onClick={onClose}
          >
            Back
          </Button>
        </ButtonGroup>
      ),
    }}
    closeAction={{
      label: "Close",
      onClose,
    }}
  >
    <Interpose node={<Space v={1.5} />}>
      <P>
        <ToTranslate>
          Once a customer is set up, you can debit ad-hoc or recurring payments
          from them whenever due — saving your customers the hassle of paying
          manually each time or being chased.
        </ToTranslate>
      </P>
      <P>
        <ToTranslate>
          Below are some useful ways to invite customers to set up:
        </ToTranslate>
      </P>

      <LinkBuilder route={Route.CustomersCreate} routeParams={{ id: "link" }}>
        {(props) => (
          <PlainLink
            target="_blank"
            textDecoration="underline"
            weight={FontWeight.SemiBold}
            {...props}
          >
            <ToTranslate>
              Share an invite link with multiple customers
            </ToTranslate>
          </PlainLink>
        )}
      </LinkBuilder>

      <LinkBuilder route={Route.CustomersCreate} routeParams={{ id: "csv" }}>
        {(props) => (
          <PlainLink
            target="_blank"
            textDecoration="underline"
            weight={FontWeight.SemiBold}
            {...props}
          >
            <ToTranslate>Bulk invite customers using a CSV</ToTranslate>
          </PlainLink>
        )}
      </LinkBuilder>

      <PlainLink
        href="https://hub.gocardless.com/s/article/Migrating-bank-debit-customers-to-GoCardless"
        target="_blank"
        textDecoration="underline"
        weight={FontWeight.SemiBold}
      >
        <ToTranslate>
          Bulk change customers from another Direct Debit provider
        </ToTranslate>
      </PlainLink>

      <PlainLink
        href="https://gocardless.com/guides/posts/customer-payment-invite-templates/#template-switching-existing-customers"
        target="_blank"
        textDecoration="underline"
        weight={FontWeight.SemiBold}
      >
        <ToTranslate>
          Not sure what to say to customers? Browse our templates
        </ToTranslate>
      </PlainLink>
    </Interpose>
  </Dialog>
);
