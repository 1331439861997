import { Card, ClassicCard } from "@braze/web-sdk";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Box, useTheme } from "@gocardless/flux-react";

import { BrazeBanner } from "./BrazeBanner";
import { filterAndSortCards } from "./utils";

import { useBraze } from "src/technical-integrations/braze/useBraze";
import { BannerPriority, BannerType } from "src/common/bannerProperties";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

export interface BrazeWithBannerTypes extends Card {
  type: BannerType.Braze;
  priority: BannerPriority.Low;
  component: undefined;
}
export interface WithBrazeCardsProps {
  brazeCards: BrazeWithBannerTypes[];
}

export const BrazeBanners: React.FC = () => {
  const { loadContentCards, cleanupBrazeSubscription } = useBraze();
  const router = useRouter();
  const [cards, setCards] = useState<Card[]>(() => {
    const storedCards = localStorage.getItem("cachedCards");
    return storedCards ? JSON.parse(storedCards) : [];
  });
  const { theme } = useTheme();

  useEffect(() => {
    const fetchContentCards = async () => {
      try {
        const contentCards = await loadContentCards();
        if (contentCards.cards) {
          localStorage.setItem(
            "cachedCards",
            JSON.stringify(contentCards.cards)
          );
          setCards(contentCards.cards);
        }
      } catch (error) {
        /* empty */
      }
    };

    fetchContentCards();

    return () => cleanupBrazeSubscription();
  }, [loadContentCards, cleanupBrazeSubscription, router]);
  return (
    <Box layout="flex" flexDirection="column" css={{ gap: theme.spacing(1) }}>
      {filterAndSortCards(router, cards).map((card) => (
        <BrazeBanner key={card.id} card={card as ClassicCard} />
      ))}
    </Box>
  );
};

export const withBrazeBanners = <P extends object>(
  Component: React.ComponentType<P & WithBrazeCardsProps>
) => {
  const WithBrazeBanners = (props: P) => {
    const { loadContentCards, cleanupBrazeSubscription } = useBraze();
    const [cards, setCards] = useState<BrazeWithBannerTypes[]>();
    const { isVariationOn } = useOptimizelyVariation({
      flag: OptimizelyFlag.BI_BRAZE_BANNERS,
    });
    const router = useRouter();

    useEffect(() => {
      const fetchContentCards = async () => {
        try {
          const contentCards = await loadContentCards();
          if (contentCards.cards) {
            localStorage.setItem(
              "cachedCards",
              JSON.stringify(contentCards.cards)
            );
            setCards(
              filterAndSortCards(router, contentCards.cards).map(
                (card) =>
                  ({
                    ...card,
                    type: BannerType.Braze,
                    priority: BannerPriority.Low,
                  }) as BrazeWithBannerTypes
              )
            );
          }
        } catch (error) {
          /* empty */
        }
      };

      if (isVariationOn) {
        fetchContentCards();
      } else {
        setCards([]);
      }

      return () => cleanupBrazeSubscription();
    }, [loadContentCards, cleanupBrazeSubscription, isVariationOn, router]);
    return typeof cards === "undefined" ? null : (
      <Component {...props} brazeCards={cards as BrazeWithBannerTypes[]} />
    );
  };

  return WithBrazeBanners;
};
