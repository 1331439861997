import {
  Dialog,
  P,
  Color,
  TypePreset,
  Text,
  Glyph,
  PlainLink,
  Icon,
  FontWeight,
  Interpose,
  Space,
  HoverEffect,
  OL,
  LI,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Trans } from "@lingui/macro";

import { DialogBodyWrapper } from "../components/DialogBodyWrapper";
import { withChildrenTarget } from "../components/withChildrenTarget";
import { DialogCTA } from "../components/DialogCTA";

import AddCustomersInBulkDialogSVG from "src/assets/svg/suggested-actions-add-customers-in-bulk-dialog.svg";
import { Route } from "src/common/routing";

export const AddCustomersInBulk = withChildrenTarget(({ ...props }) => (
  <Dialog
    {...props}
    footer={
      <DialogCTA
        route={Route.CustomersCreate}
        routeParams={{ id: "csv" }}
        name={props.id}
      />
    }
  >
    <DialogBodyWrapper
      bg={Color.Ultraviolet_100}
      image={
        <Image
          src={AddCustomersInBulkDialogSVG}
          alt="add-customers-in-bulk"
          width={96}
          height={96}
        />
      }
      title={<Trans>Add multiple new customers at once</Trans>}
    >
      <Interpose node={<Space v={1} />}>
        <P preset={TypePreset.Body_01}>
          <Trans>
            No need to upload customers one-by-one, you can upload customers via
            CSV file and send authorisations in bulk.
          </Trans>
        </P>
        <P preset={TypePreset.Body_01}>
          <Text weight={FontWeight.SemiBold}>
            <Trans>Follow these steps:</Trans>
          </Text>
        </P>
        <OL preset={TypePreset.Body_01}>
          <LI>
            <Trans>
              Go to <Text weight={FontWeight.SemiBold}>Customers</Text>, click{" "}
              <Text weight={FontWeight.SemiBold}>Invite customers</Text>
            </Trans>
          </LI>
          <LI>
            <Trans>
              Choose <Text weight={FontWeight.SemiBold}>Import</Text>
            </Trans>
          </LI>
          <LI>
            <Trans>
              {`Download the template and add your customers’ details into the
              template and save it.`}
            </Trans>
          </LI>
          <LI>
            <Trans>Choose Upload CSV and choose the file you just saved</Trans>
          </LI>
          <LI>
            <Trans>
              Click <Text weight={FontWeight.SemiBold}>Upload</Text>
            </Trans>
          </LI>
        </OL>
        <P preset={TypePreset.Body_01}>
          <Trans>
            Your upload will be validated. You will be informed if there are any
            errors so you can rectify these and re-upload.
          </Trans>
        </P>
        <P preset={TypePreset.Body_01}>
          <Trans>
            Once your upload has been validated, click Send invitations.
          </Trans>
        </P>
        <Space v={1} />
        <PlainLink
          href="https://hub.gocardless.com/s/article/Set-up-customers-via-CSV"
          target="_blank"
          effect={HoverEffect.TextDecoration}
        >
          <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            <Trans>View the help article</Trans>
          </Text>{" "}
          <Icon name={Glyph.Export} size="12px" />
        </PlainLink>
      </Interpose>
    </DialogBodyWrapper>
  </Dialog>
));
