import {
  Box,
  Color,
  FontWeight,
  Glyph,
  Icon,
  Layer,
  PlainButton,
  Text,
  Tooltip,
  TypePreset,
} from "@gocardless/flux-react";
import Image from "next/image";

import { ExperimentChartWidgetType, ExperimentNames } from "../utils";

import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";

interface HiddenMetricProps {
  image: { src: string; alt: string };
  metricName: ExperimentChartWidgetType;
  onRegisterInterest: () => void;
  filter?: string;
  opacity?: number;
}

export const HiddenMetric: React.FC<HiddenMetricProps> = ({
  image: { src, alt },
  metricName,
  onRegisterInterest,
  filter = "blur(6px)",
  opacity = 0.6,
}) => {
  const { sendEvent } = useSegment();

  const handleTooltipClick = () => {
    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_REPORTING_HIDDEN_METRIC_TOOLTIP_CLICKED,
      {
        experiment_name: ExperimentNames.FutureData,
        metric_name: metricName,
      }
    );
  };

  const handleRegisterInterestClick: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    onRegisterInterest();

    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_REPORTING_REGISTER_INTEREST_CTA_CLICKED,
      {
        experiment_name: ExperimentNames.FutureData,
        metric_name: metricName,
      }
    );
  };

  return (
    <Layer mode="relative">
      <Image
        src={src}
        alt={alt}
        sizes="100vw"
        style={{
          width: "100%",
          height: "auto",
          filter,
          opacity,
        }}
        height={242}
        width={384}
      />

      <Layer
        mode="absolute"
        top="calc(50% - 32px)"
        left="calc(50% - 32px)"
        bottom={0}
        right={0}
        css={{ width: "48px", height: "48px" }}
      >
        <Text onClick={handleTooltipClick}>
          <Tooltip
            message={
              <>
                <Text layout="block" spaceBelow={0.75}>
                  This data isn&apos;t available yet. Register your interest to
                  learn more.
                </Text>
                <PlainButton
                  decoration="underline"
                  onClick={handleRegisterInterestClick}
                  preset={TypePreset.Body_01}
                  weight={FontWeight.SemiBold}
                >
                  Register interest
                </PlainButton>
              </>
            }
            positionStrategy="absolute"
          >
            {(triggerProps) => (
              <PlainButton {...triggerProps} aria-label="Metric unavailable">
                <Box
                  bg={Color.Brownstone_Moonstone}
                  gutterH={1}
                  gutterV={1}
                  borderColor={Color.Greystone_400}
                  borderWidth={1}
                  borderRadius={0.5}
                  width="48px"
                  height="48px"
                >
                  <Icon name={Glyph.ViewHidden} />
                </Box>
              </PlainButton>
            )}
          </Tooltip>
        </Text>
      </Layer>
    </Layer>
  );
};
