import {
  Box,
  Text,
  TypePreset,
  TextArea,
  Space,
  FormFieldStatus,
  Hint,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";

import { FeedbackFormData } from "../../utils";

import { ToTranslate } from "src/components/i18n";
import { useSendPageViewEvent } from "src/technical-integrations/segment/useSendPageViewEvent";
import { TrackingEvent } from "src/common/trackingEvents";

export const DataInsightsStep: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FeedbackFormData>();

  useSendPageViewEvent(
    TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FEEDBACK_FORM_DATA_INSIGHTS_STEP_VIEWED
  );

  return (
    <Box>
      <Text preset={TypePreset.Heading_01} spaceBelow={2}>
        <ToTranslate>
          1.
          <Space layout="inline" h={0.5} />
          What data insights and reports would you most like to see from
          GoCardless?
        </ToTranslate>
      </Text>

      <Box spaceAbove={0.5}>
        <TextArea
          id="dataInsights"
          {...register("dataInsights")}
          placeholder="Add description here"
          rows={5}
        />
        <Space v={0.5} />
        {errors.dataInsights ? (
          <Hint status={FormFieldStatus.Danger}>
            <ToTranslate>
              Please provide your feedback on data insight
            </ToTranslate>
          </Hint>
        ) : null}
      </Box>
    </Box>
  );
};
