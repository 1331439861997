import {
  AlignItems,
  Box,
  useTheme,
  H4,
  Breakpoint,
  Separator,
  TypePreset,
  Accordion,
  AccordionVariant,
  AccordionSize,
  AccordionMode,
  AccordionItem,
} from "@gocardless/flux-react";
import { useCallback, PropsWithChildren } from "react";

import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";
import useToggle from "src/hooks/useToggle";

interface ResponsiveWrapperProps extends PropsWithChildren {
  id: string;
  listTitle: string;
}

export const ResponsiveWrapper: React.FC<ResponsiveWrapperProps> = ({
  children,
  id,
  listTitle,
}) => {
  const isSmallOrTabletBreakpoint = useIsBreakpoint({ max: Breakpoint.Md });
  const { theme } = useTheme();

  const { on, off, isOn } = useToggle();
  const onChange = useCallback(
    (openItems: string[]) => {
      if (!openItems.length) {
        off();
      } else {
        on();
      }
    },
    [off, on]
  );
  return (
    <>
      {isSmallOrTabletBreakpoint ? (
        <Box
          layout="flex"
          width="100%"
          height="100%"
          flexDirection="column"
          alignItems={AlignItems.FlexStart}
        >
          <Accordion
            onChange={onChange}
            toggleVisibility="visible"
            variant={AccordionVariant.SolidOnLight}
            size={AccordionSize.Sm}
            mode={AccordionMode.Single}
            minimumSelected={0}
            aria-label="Basic accordion demo"
            css={{
              width: "100%",
              height: "100%",
              borderRadius: 0,
              padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
              [`#${id}_header`]: {
                padding: `0 ${theme.spacing(0.75)}`,
              },
            }}
          >
            <AccordionItem
              id={id}
              css={{
                padding: 0,
              }}
              title={
                <H4 preset={TypePreset.Body_01}>{`${
                  isOn ? "Hide" : "View"
                }  ${listTitle.toLowerCase()}`}</H4>
              }
            >
              <Box spaceAbove={2}>{children}</Box>
            </AccordionItem>
          </Accordion>
        </Box>
      ) : (
        <Box width="100%" layout="flex" flexDirection="column" flexGrow={1}>
          <Separator spacing={[[2, 0]]} />
          {children}
        </Box>
      )}
    </>
  );
};
