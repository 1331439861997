import {
  AlignItems,
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  Glyph,
  H3,
  Icon,
  JustifyContent,
  Space,
  Text,
  TypeScale,
} from "@gocardless/flux-react";
import { ComponentType, FC, ReactNode } from "react";
import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";

import CollectedPayments from "../CollectedPayments/CollectedPayments";

import { Route, routerPush } from "src/common/routing";

interface QuickActionCardProps {
  header: ReactNode;
  description?: ReactNode;
  buttonLabel: ReactNode;
  status?: CreditorsVerificationStatus;
  onClick: () => void;
}

const QuickActionCard: FC<QuickActionCardProps> & {
  ViewPayments: ComponentType<{
    status: CreditorsVerificationStatus;
  }>;
  ActivatePayouts: ComponentType<{
    status: CreditorsVerificationStatus;
    setOpenActivatePayoutsDialog: (value: boolean) => void;
  }>;
  AddCustomers: ComponentType;
  CreatePayments: ComponentType;
} = ({ header, description, buttonLabel, onClick }) => (
  <Box
    layout="flex"
    flexDirection="column"
    alignItems={AlignItems.FlexStart}
    gutterH={1.5}
    gutterV={2}
    bg={ColorPreset.BackgroundLight_01}
    borderWidth={1}
    borderColor={ColorPreset.BorderOnLight_04}
    borderRadius={1}
  >
    {header}

    <Space v={0.5} />

    {description && (
      <Text size={2} color={ColorPreset.TextOnLight_02}>
        {description}
      </Text>
    )}

    <Space v={1.5} />

    <Button
      size={ButtonSize.Sm}
      variant={ButtonVariant.PrimaryOnLight}
      onClick={onClick}
    >
      {buttonLabel}
    </Button>
  </Box>
);

QuickActionCard.ViewPayments = ({ status }) => (
  <QuickActionCard
    header={<CollectedPayments status={status} />}
    buttonLabel={
      <Trans id="get-your-first-payout.view-payments-button">
        View payments
      </Trans>
    }
    onClick={() => routerPush({ route: Route.Payments })}
  />
);

QuickActionCard.ActivatePayouts = ({
  status,
  setOpenActivatePayoutsDialog,
}) => (
  <QuickActionCard
    header={<CollectedPayments status={status} />}
    buttonLabel={<Trans>Activate payouts</Trans>}
    onClick={() => setOpenActivatePayoutsDialog(true)}
  />
);

QuickActionCard.AddCustomers = () => (
  <QuickActionCard
    header={
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Box
          layout="flex"
          flexDirection="row"
          justifyContent={JustifyContent.Center}
          alignItems={AlignItems.Center}
          alignSelf={AlignItems.Start}
          width="32px"
          height="32px"
          spaceAfter={0.75}
          bg={Color.Ultraviolet_50}
          borderRadius={2}
        >
          <Icon
            name={Glyph.User}
            color={ColorPreset.IconOnLight_01}
            size="14px"
          />
        </Box>

        <H3 size={TypeScale.Size_04}>
          <Trans>Add customers</Trans>
        </H3>
      </Box>
    }
    description={
      <Trans>
        Send an invitation via a sharable link, customisable email or CSV
        import.
      </Trans>
    }
    buttonLabel={<Trans>Add</Trans>}
    onClick={() => {
      routerPush({
        route: Route.CustomersCreate,
        routeParams: { id: "link" },
      });
    }}
  />
);

QuickActionCard.CreatePayments = () => (
  <QuickActionCard
    header={
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Box
          layout="flex"
          flexDirection="row"
          justifyContent={JustifyContent.Center}
          alignItems={AlignItems.Center}
          alignSelf={AlignItems.Start}
          width="32px"
          height="32px"
          spaceAfter={0.75}
          bg={Color.Ultraviolet_50}
          borderRadius={2}
        >
          <Icon
            name={Glyph.Recurring}
            color={ColorPreset.IconOnLight_01}
            size="18px"
          />
        </Box>

        <H3 size={TypeScale.Size_04}>
          <Trans>Create payments</Trans>
        </H3>
      </Box>
    }
    description={
      <Trans>
        Create one-off or subscription payments for new or existing customers.
      </Trans>
    }
    buttonLabel={<Trans>Create</Trans>}
    onClick={() => {
      routerPush({
        route: Route.PaymentCreate,
      });
    }}
  />
);

export default QuickActionCard;
