import { Field, Label, Option, Select } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { t } from "@lingui/macro";
import { IntervalUnit } from "@gocardless/api/dashboard/types";
import { useLingui } from "@lingui/react";

import { getInputErrorStatus } from "src/utils/inputValidation";

export const CUSTOM_INTERVAL = "Custom";
export const fieldName = "interval_unit";
export const defaultValue = IntervalUnit.Monthly;

export interface FormWithIntervalUnit {
  interval_unit: IntervalUnit | typeof CUSTOM_INTERVAL;
}
interface IntervalUnitFieldProps {
  isRestricted?: boolean;
  renderLabel: () => React.ReactNode;
  defaultValue?: FormWithIntervalUnit["interval_unit"];
}

export const IntervalUnitField: React.FC<IntervalUnitFieldProps> = ({
  isRestricted,
  renderLabel,
  defaultValue: defaultValueProp = defaultValue,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormWithIntervalUnit>();

  const { i18n } = useLingui();
  const INTERVAL_UNIT_WEEKLY = i18n._(
    t({ id: "plan-create.interval-unit-weekly", message: "Weekly" })
  );
  const INTERVAL_UNIT_MONTHLY = i18n._(
    t({ id: "plan-create.interval-unit-monthly", message: "Monthly" })
  );
  const INTERVAL_UNIT_YEARLY = i18n._(
    t({ id: "plan-create.interval-unit-yearly", message: "Yearly" })
  );
  const INTERVAL_UNIT_CUSTOM = i18n._(
    t({ id: "plan-create.custom", message: "Custom" })
  );
  const INTERVALS = [
    { key: INTERVAL_UNIT_WEEKLY, value: IntervalUnit.Weekly },
    { key: INTERVAL_UNIT_MONTHLY, value: IntervalUnit.Monthly },
    { key: INTERVAL_UNIT_YEARLY, value: IntervalUnit.Yearly },
    { key: INTERVAL_UNIT_CUSTOM, value: CUSTOM_INTERVAL },
  ];

  return (
    <Field>
      <Label htmlFor={fieldName}>{renderLabel()}</Label>
      <Select
        disabled={isRestricted}
        id={fieldName}
        status={getInputErrorStatus(
          !!errors.interval_unit || !!errors.root?.timeLimitExceeded
        )}
        defaultValue={defaultValueProp}
        {...register(fieldName, { required: true })}
      >
        {INTERVALS.map((interval) => (
          <Option key={interval.key} value={interval.value}>
            {interval.key}
          </Option>
        ))}
      </Select>
    </Field>
  );
};
