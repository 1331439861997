import {
  Box,
  Icon,
  Glyph,
  ColorPreset,
  Breakpoint,
  Interpose,
  Separator,
} from "@gocardless/flux-react";
import {
  OutboundPaymentBalanceResource,
  OutboundPaymentBalancesCurrency,
} from "@gocardless/api/dashboard/types";

import { HomepageCard } from "../routes/Home/components/HomepageCard";
import { Section } from "../routes/Home/components/Section";

import { OutboundPaymentBalanceCard } from "./OutboundPaymentBalanceCard";
import { OutboundPaymentBalanceType } from "./OutboundPaymentBalanceType";
import { useOutboundPaymentBalance } from "./useOutboundPaymentBalance";

import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";

const SpinnerComponent = (
  <HomepageCard>
    <Icon
      name={Glyph.Spinner}
      color={ColorPreset.IconOnLight_01}
      size="32px"
      data-testid="spinner"
    />
  </HomepageCard>
);

export const OutboundPaymentBalance = () => {
  const currency = OutboundPaymentBalancesCurrency.Gbp;
  const { data: balance, isLoading } = useOutboundPaymentBalance(currency);

  return (
    <Box layout="flex" flexDirection="column">
      {isLoading ? (
        SpinnerComponent
      ) : (
        <OutboundPaymentBalanceComponent
          balance={balance}
          isLoading={isLoading}
          currency={currency}
        />
      )}
    </Box>
  );
};

const OutboundPaymentBalanceComponent: React.FC<{
  balance: OutboundPaymentBalanceResource | undefined;
  isLoading: boolean;
  currency: OutboundPaymentBalancesCurrency;
}> = ({ balance, isLoading, currency }) => {
  const isMobileOrTabletBreakpoint = useIsBreakpoint({ max: Breakpoint.Md });

  if (isLoading) {
    return <></>;
  }

  const cardConfigs = [
    { type: OutboundPaymentBalanceType.Current, currency, balance },
    { type: OutboundPaymentBalanceType.Available, currency, balance },
    { type: OutboundPaymentBalanceType.Scheduled, currency, balance },
  ];

  if (isMobileOrTabletBreakpoint) {
    return (
      <HomepageCard>
        <Section>
          <Interpose node={<Separator spacing={0} />}>
            {cardConfigs.map((props) => (
              <OutboundPaymentBalanceCard key={props.type} {...props} />
            ))}
          </Interpose>
        </Section>
      </HomepageCard>
    );
  }

  return (
    <Section>
      {cardConfigs.map((props) => (
        <Box height="100%" key={props.type}>
          <HomepageCard>
            <OutboundPaymentBalanceCard {...props} />
          </HomepageCard>
        </Box>
      ))}
    </Section>
  );
};
