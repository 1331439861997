import { Box, Separator, Space, XYGrid } from "@gocardless/flux-react";
import { mutate } from "swr";

import { ErrorBanner } from "./components/ErrorBanner/ErrorBanner";
import { CollectionSection } from "./components/sections/CollectionSection";
import { OverviewSection } from "./components/sections/OverviewSection";
import { AccountHealthSection } from "./components/sections/AccountHealthSection";
import { useReportingContext } from "./components/ReportingContextProvider";
import { OutboundSection } from "./components/sections/OutboundSection";
import { useReportingTemplateAreas } from "./hooks/useReportingTemplateAreas";
import { RevenueInsightsSection } from "./components/experiments/sections/RevenueInsightsSection";
import { PaymentInsightsSection } from "./components/experiments/sections/PaymentInsightsSection";
import { CustomerInsightsSection } from "./components/experiments/sections/CustomerInsightsSection";
import { useReportingExperiment } from "./components/experiments/hooks/useReportingExperiment";

import { useOrganisation } from "src/libraries/organisation/hooks";

const FutureDataDashboard = () => (
  <>
    <CollectionSection />
    <RevenueInsightsSection />
    <PaymentInsightsSection />
    <CustomerInsightsSection />
  </>
);

const Dashboard = () => (
  <>
    <CollectionSection />
    <OverviewSection />
    <AccountHealthSection />
  </>
);

export const Reporting = () => {
  const { failedRequestKeys, setFailedRequestKeys } = useReportingContext();

  const { isUpsellExperimentEnabled, isFutureDataExperimentEnabled } =
    useReportingExperiment();

  const isExperimentEnabled =
    isUpsellExperimentEnabled || isFutureDataExperimentEnabled;

  const { templateAreas } = useReportingTemplateAreas();

  const outboundPaymentsEnabled =
    useOrganisation()?.outbound_payments_enabled ?? false;

  const handleReload = () => {
    mutate(
      (key) => typeof key === "string" && failedRequestKeys.includes(key),
      undefined,
      { revalidate: true }
    );

    setFailedRequestKeys([]);
  };

  const DashboardComponent = isFutureDataExperimentEnabled
    ? FutureDataDashboard
    : Dashboard;

  return (
    <Box>
      {failedRequestKeys.length > 0 ? (
        <>
          <ErrorBanner onReload={handleReload} />
          <Space v={3} />
        </>
      ) : null}

      <XYGrid
        columnGap={2}
        rowGap={isExperimentEnabled ? 3 : 2}
        templateColumns={["1fr", "repeat(2, 1fr)", null, "repeat(3, 1fr)"]}
        templateAreas={templateAreas}
      >
        {outboundPaymentsEnabled ? (
          <>
            <OutboundSection />
            <Box gridArea="spacer">
              <Separator />
            </Box>
          </>
        ) : null}
        <DashboardComponent />
      </XYGrid>
    </Box>
  );
};
