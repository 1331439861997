import {
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Dialog,
  Interpose,
  Space,
  P,
  H2,
  Link,
  Visibility,
  FontWeight,
  TypePreset,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Trans } from "@lingui/macro";
import { useEffect } from "react";

import { ExperimentNames } from "../utils";

import UpsellFasterACH from "src/assets/svg/upsell-faster-ach.svg";
import UpsellFasterACHMobile from "src/assets/svg/upsell-faster-ach-mobile.svg";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";

interface FasterACHUpgradeDialogProp {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const trackingContext = "Faster ACH upgrade dialog";

export const FasterACHUpgradeDialog: React.FC<FasterACHUpgradeDialogProp> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { sendEvent } = useSegment();

  useEffect(() => {
    if (open) {
      sendEvent(
        TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FASTER_ACH_UPGRADE_DIALOG_VIEWED,
        {
          ctx: trackingContext,
          experiment_name: ExperimentNames.UpsellFasterACH,
        }
      );
    }
  }, [open, sendEvent]);

  return (
    <Dialog
      open={open}
      bodyGutterV={2}
      aria-labelledby="fasterACHUpgradeDialogTitle"
      closeAction={{
        label: <Trans id="Close">Close</Trans>,
        onClose: () => {
          onClose();
          sendEvent(TrackingEvent.MERCHANT_DASHBOARD_REPORTING_DIALOG_CLOSED, {
            ctx: trackingContext,
            experiment_name: ExperimentNames.UpsellFasterACH,
          });
        },
      }}
      header={
        <H2 preset={TypePreset.Heading_02} id="fasterACHUpgradeDialogTitle">
          Speed up your payments with Faster ACH
        </H2>
      }
      footer={
        <ButtonGroup arrangement={["column-center", "row-end-reverse"]}>
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            size={[ButtonSize.Lg, ButtonSize.Sm]}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            onClick={() => {
              onSubmit();
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_UPGRADE_CTA_CLICKED,
                {
                  ctx: trackingContext,
                  experiment_name: ExperimentNames.UpsellFasterACH,
                }
              );
            }}
          >
            Upgrade now
          </Button>
          <Link
            href="https://gocardless.com/en-us/pricing/"
            target="_blank"
            rel="noopener"
            size={[ButtonSize.Lg, ButtonSize.Sm]}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            variant={ButtonVariant.TextOnLight}
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_VIEW_PLANS_CTA_CLICKED,
                {
                  ctx: trackingContext,
                  experiment_name: ExperimentNames.UpsellFasterACH,
                }
              );
            }}
          >
            View all plans
          </Link>
        </ButtonGroup>
      }
    >
      <Interpose node={<Space v={1} />}>
        <P size={3} weight={FontWeight.Bold}>
          Upgrade to our most popular plan to access Faster ACH plus additional
          benefits.
        </P>
        <P size={2}>
          Click below to upgrade, and your plan, along with access to advanced
          features including Faster ACH, will be automatically updated on the
          1st of the upcoming month.
        </P>
      </Interpose>
      <Space v={2} />
      <Visibility visible={["none", "block"]}>
        <Image
          src={UpsellFasterACH}
          alt=""
          width={0}
          height={0}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </Visibility>
      <Visibility visible={["block", "none"]}>
        <Image
          src={UpsellFasterACHMobile}
          alt=""
          width={0}
          height={0}
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </Visibility>
    </Dialog>
  );
};
