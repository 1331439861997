import { ReactNode } from "react";
import {
  AlignItems,
  Box,
  ColorPreset,
  Field,
  FontWeight,
  Glyph,
  Hint,
  Icon,
  Interpose,
  JustifyContent,
  Label,
  P,
  Radio,
  Space,
  Tooltip,
  TypePreset,
  XYGrid,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import {
  DateFormatter,
  parseDate,
  today,
  getLocalTimeZone,
} from "@internationalized/date";
import { Trans } from "@lingui/macro";

import { useI18n } from "src/technical-integrations/i18n";

export interface FormWithTakePaymentAsSoonAsPossible {
  takePaymentAsSoonAsPossible: string;
}

interface TakePaymentAsSoonAsPossibleFieldProps {
  firstAvailableDate?: string;
  isRestricted?: boolean;
  asapLabelContent?: ReactNode;
  renderAsapLabelDescription?:
    | ((firstAvailableDate: string) => ReactNode)
    | null;
  specificDayLabelContent?: ReactNode;
  specificDayLabelDescription?: ReactNode;
  label?: ReactNode;
  tooltip?: ReactNode;
  hint?: ReactNode;
}

export const getMinimumChargeDate = () => today(getLocalTimeZone());
export const getMinimumChargeDateString = () =>
  getMinimumChargeDate().toString();

export const fieldName = "takePaymentAsSoonAsPossible";

export const TakePaymentAsSoonAsPossibleField: React.FC<
  TakePaymentAsSoonAsPossibleFieldProps
> = ({
  firstAvailableDate: firstAvailableDateProp,
  isRestricted,
  asapLabelContent = (
    <Trans id="customer.take-payments-asap">
      Take payments as soon as possible
    </Trans>
  ),
  renderAsapLabelDescription,
  specificDayLabelContent = (
    <Trans id="customer.take-payments-from-specific-day">
      Take payments from a specific day
    </Trans>
  ),
  specificDayLabelDescription,
  label,
  tooltip,
  hint,
}) => {
  const { register } = useFormContext<FormWithTakePaymentAsSoonAsPossible>();

  const titleColor = isRestricted
    ? ColorPreset.TextOnDark_02
    : ColorPreset.TextOnLight_01;

  const [locale] = useI18n();
  const firstAvailableDate =
    firstAvailableDateProp ?? getMinimumChargeDateString();
  const formattedFirstAvailableDate = new DateFormatter(locale, {
    day: "2-digit",
    month: "long",
    year: "numeric",
  }).format(parseDate(firstAvailableDate).toDate(getLocalTimeZone()));

  return (
    <Field>
      <Interpose node={<Space v={1} />}>
        <>
          <Label htmlFor={fieldName}>
            <Box layout="flex" justifyContent={JustifyContent.SpaceBetween}>
              <XYGrid
                templateColumns="auto auto"
                columnGap={0.5}
                alignItems={AlignItems.Center}
              >
                <>{label}</>

                {tooltip ? (
                  <Tooltip message={tooltip}>
                    {(triggerProps) => (
                      <Icon {...triggerProps} name={Glyph.Tooltip} />
                    )}
                  </Tooltip>
                ) : null}
              </XYGrid>
            </Box>
          </Label>
          {hint ? (
            <Hint>
              <P color={ColorPreset.TextOnLight_02} preset={TypePreset.Body_02}>
                {hint}
              </P>
            </Hint>
          ) : null}
        </>
        <Interpose node={<Space v={0.5} />}>
          <Radio
            disabled={isRestricted}
            {...register(fieldName, { required: true })}
            name={fieldName}
            id={`${fieldName}-true`}
            value="true"
          >
            <P
              color={titleColor}
              preset={TypePreset.Body_02}
              weight={FontWeight.SemiBold}
            >
              {asapLabelContent}
            </P>
            {renderAsapLabelDescription ? (
              <>{renderAsapLabelDescription(formattedFirstAvailableDate)}</>
            ) : (
              <P
                color={
                  isRestricted
                    ? ColorPreset.TextOnDark_02
                    : ColorPreset.TextOnLight_02
                }
                preset={TypePreset.Body_01}
              >
                ({formattedFirstAvailableDate})
              </P>
            )}
          </Radio>
          <Radio
            disabled={isRestricted}
            {...register(fieldName, { required: true })}
            name={fieldName}
            id={`${fieldName}-false`}
            value="false"
          >
            <P
              color={titleColor}
              preset={TypePreset.Body_02}
              weight={FontWeight.SemiBold}
            >
              {specificDayLabelContent}
            </P>
            {specificDayLabelDescription ? specificDayLabelDescription : null}
          </Radio>
        </Interpose>
      </Interpose>
    </Field>
  );
};
