import {
  Box,
  DateRangePicker,
  AlignItems,
  Interpose,
  Space,
  Color,
  Text,
  Icon,
  TypePreset,
  Glyph,
  Visibility,
  TypeScale,
  Tooltip,
  P,
  XYGrid,
  useTheme,
} from "@gocardless/flux-react";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import {
  CalendarDate,
  getLocalTimeZone,
  parseDate,
  today,
} from "@internationalized/date";

import { DateRangeMenu } from "../DateRangeMenu/DateRangeMenu";
import { useReportingContext } from "../ReportingContextProvider";
import BetaWidget from "../BetaWidget/BetaWidget";
import {
  betaWidgetContainerSmallBreakpointStyle,
  chartWidgetContainerStyle,
} from "../../utils/style";
import { ChartWidget } from "../ChartWidget/ChartWidget";
import { ExperimentChartWidgetTypes } from "../experiments/utils";
import { getMostRecentDate } from "../../utils/common";
import { useReportingExperiment } from "../experiments/hooks/useReportingExperiment";
import { ExperimentChartWidget } from "../experiments/ExperimentChartWidget/ExperimentChartWidget";

import { useI18nLocale } from "src/hooks/useI18nLocale";
import { LastUpdatedText } from "src/components/routes/Home/components/LastUpdatedText";
import { HomeSectionHeader } from "src/components/HomeSectionHeader/HomeSectionHeader";
import { useOrganisation } from "src/libraries/organisation/hooks";

export const OverviewSection: React.FC = () => {
  const {
    isChartWidgetLoading,
    updatedAtDates,
    startDateFilter,
    endDateFilter,
    setStartDateFilter,
    setEndDateFilter,
  } = useReportingContext();

  const { isUpsellExperimentEnabled, isFutureDataExperimentEnabled } =
    useReportingExperiment();

  const isExperimentEnabled =
    isUpsellExperimentEnabled || isFutureDataExperimentEnabled;

  const outboundPaymentsEnabled =
    useOrganisation()?.outbound_payments_enabled ?? false;

  const { i18n } = useLingui();
  const i18nLocale = useI18nLocale();

  const lastUpdatedDate = getMostRecentDate(updatedAtDates);
  const yesterday = today(getLocalTimeZone()).subtract({ days: 1 });

  const { theme } = useTheme();

  const handleDatePickerChange = (date: CalendarDate[]) => {
    if (!date.length) return;
    const startDateObj = date[0] as CalendarDate;
    const endDateObj = date[1] as CalendarDate;
    const startDate = new CalendarDate(
      startDateObj.year,
      startDateObj.month,
      startDateObj.day
    ).toString();
    const endDate = new CalendarDate(
      endDateObj.year,
      endDateObj.month,
      endDateObj.day
    ).toString();
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };

  const getOverviewHeader = () => {
    const spaceBelow = isExperimentEnabled ? 2 : 0;

    const experimentHeader = (
      <HomeSectionHeader spaceBelow={spaceBelow}>
        Revenue performance
      </HomeSectionHeader>
    );

    if (outboundPaymentsEnabled) {
      return isUpsellExperimentEnabled ? experimentHeader : null;
    }

    return isUpsellExperimentEnabled ? (
      experimentHeader
    ) : (
      <HomeSectionHeader spaceBelow={spaceBelow}>
        <Trans>Overview</Trans>
      </HomeSectionHeader>
    );
  };

  const overviewHeader = getOverviewHeader();

  const selectedDateRange = [
    parseDate(startDateFilter),
    parseDate(endDateFilter),
  ];

  return (
    <>
      {isExperimentEnabled ? null : overviewHeader}
      <Box gridArea="overview" layout="flex" flexDirection="column">
        {isExperimentEnabled ? overviewHeader : null}
        <Box spaceBelow={[1, 2]} layout="flex" alignItems={AlignItems.Center}>
          <Interpose node={<Space h={[1, 2]} layout="inline" />} trailing>
            <DateRangeMenu />
            <Box layout="flex" alignItems={AlignItems.Center}>
              <DateRangePicker
                aria-label={i18n._(
                  t({
                    message: "Select custom chart date range",
                  })
                )}
                disabled={isChartWidgetLoading}
                i18n={i18nLocale}
                format="dd-mm-yyyy"
                value={selectedDateRange}
                onChange={handleDatePickerChange}
                minDate={yesterday.subtract({ months: 3 })}
                maxDate={yesterday}
                triggerButton={{
                  style: {
                    padding: 0,
                    "&:hover": {
                      background: Color.Transparent,
                      textDecoration: "underline",
                    },
                    "&:focus": {
                      background: Color.Transparent,
                    },
                  },
                  children: (
                    <>
                      <Text preset={TypePreset.Heading_01}>
                        <Trans>Dates</Trans>
                      </Text>
                      <Space layout="inline" h={0.5} />
                      <Icon name={Glyph.Calendar} size="12px" />
                    </>
                  ),
                }}
              />
            </Box>
          </Interpose>

          {lastUpdatedDate ? (
            <>
              <Visibility visible={["none", null, "block"]}>
                <Space h={1} layout="inline" />
                <Text size={TypeScale.Size_01}>
                  <LastUpdatedText dateTimeString={lastUpdatedDate} />
                </Text>
                <Space layout="inline" h={0.75} />
                <Tooltip
                  positionStrategy="absolute"
                  message={
                    <>
                      <P spaceBelow={1}>
                        <Trans>
                          GoCardless will refresh all data shown on a daily
                          basis just after midday (approximately 13:30 UTC).
                        </Trans>
                      </P>
                      <P>
                        <Trans>
                          This will mean data accuracy will vary depending on
                          when the homepage is viewed.
                        </Trans>
                      </P>
                    </>
                  }
                  triggeredBy="hover"
                >
                  <Trans>
                    Information about reporting metric refresh rates
                  </Trans>
                </Tooltip>
              </Visibility>
            </>
          ) : null}
        </Box>

        <Box css={betaWidgetContainerSmallBreakpointStyle(theme)}>
          <BetaWidget />
        </Box>

        {isUpsellExperimentEnabled ? (
          <XYGrid
            columnGap={2}
            rowGap={2}
            templateColumns={["1fr", null, "repeat(2, minmax(0, 1fr))"]}
            autoColumns="1fr"
          >
            <Box css={chartWidgetContainerStyle(theme)} height="100%">
              <ChartWidget />
            </Box>

            <Box height="100%">
              <ExperimentChartWidget
                type={ExperimentChartWidgetTypes.PayoutForecast}
              />
            </Box>
          </XYGrid>
        ) : (
          <Box css={chartWidgetContainerStyle(theme)} height="100%">
            <ChartWidget />
          </Box>
        )}
      </Box>
    </>
  );
};
