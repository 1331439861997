import { useGetPrimaryCreditorResponse } from "src/libraries/creditor/hooks";

interface useGetDefaultCustomerInviteLinkParams {
  shouldMakeRequest?: boolean;
}

export const useGetDefaultCustomerInviteLink = ({
  shouldMakeRequest = true,
}: useGetDefaultCustomerInviteLinkParams = {}) => {
  const { data: creditor, isLoading: creditorIsLoading } =
    useGetPrimaryCreditorResponse({}, shouldMakeRequest);
  const creditorCustomerInviteLink =
    creditor?.creditors?.customer_invite_link ?? "";

  return {
    link: creditorIsLoading ? "" : creditorCustomerInviteLink,
    isLoading: creditorIsLoading,
  };
};
