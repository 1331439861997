import { useMemo } from "react";
import {
  Box,
  ButtonVariant,
  ColorPreset,
  Glyph,
  IconButton,
  P,
  PlainLink,
} from "@gocardless/flux-react";
import { PaylinkListResponseBody } from "@gocardless/api/dashboard/paylink";
import { Plural, Trans } from "@lingui/macro";

import { useShareWithEvents } from "../../hooks/useShareWithEvents";
import { ListProps } from "../../../Home/types";

import { DashboardList } from "./component/DashboardList";

import { ShareWrapper } from "src/components/payment-sharing/ShareWrapper";
import { TrackingEvent } from "src/common/trackingEvents";
import { Route } from "src/common/routing";
import { LinkBuilder } from "src/components/routing";

export interface TopOneOffPaymentsProps {
  entry?: PaylinkListResponseBody;
  isLoading: boolean;
  list: ListProps;
  hasWritePermissions: boolean;
}
export const TopOneOffPayments: React.FC<TopOneOffPaymentsProps> = ({
  entry,
  isLoading,
  list,
  hasWritePermissions,
}) => {
  const onCopyPaylink = useShareWithEvents({
    event: TrackingEvent.PAYLINKS_SHARE_COMPLETED,
    dedupedEvent: TrackingEvent.PAYLINKS_SHARE_COMPLETED,
  });

  const items = useMemo(
    () =>
      entry?.paylinks?.map((payment) => ({
        id: payment.id,
        title: (
          <>
            <LinkBuilder
              route={Route.PaylinkShare}
              routeParams={{ id: payment.id }}
            >
              {(result) => (
                <PlainLink {...result}>
                  {`${(Number(payment.amount) / 100).toFixed(2)} ${
                    payment.currency
                  }`}{" "}
                </PlainLink>
              )}
            </LinkBuilder>
          </>
        ),
        subtitle: (
          <Box layout="flex" flexDirection="column">
            <P
              color={ColorPreset.TextOnLight_01}
              css={{
                textOverflow: "ellipsis",
                overflowX: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {payment.name}
            </P>
            <P
              color={ColorPreset.TextOnLight_02}
              css={{
                textOverflow: "ellipsis",
                overflowX: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              <Plural
                id="homepage.quickactions.paylinks-taken-text"
                value={payment.payment_count ?? 0}
                one="# payment taken"
                other="# payments taken"
              />
            </P>
          </Box>
        ),
        actionIcon: hasWritePermissions ? (
          <ShareWrapper onClick={() => onCopyPaylink(payment.url)}>
            {({ isActive, ...triggerProps }) => (
              <IconButton
                {...triggerProps}
                icon={Glyph.Link}
                label={<Trans id="share-link.copy-link">Copy link</Trans>}
                variant={
                  isActive
                    ? ButtonVariant.SecondaryOnLight
                    : ButtonVariant.TextOnLight
                }
              />
            )}
          </ShareWrapper>
        ) : null,
      })),
    [entry?.paylinks, onCopyPaylink, hasWritePermissions]
  );
  return <DashboardList {...list} loading={isLoading} items={items} />;
};
