import {
  AlignItems,
  Box,
  Button,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  ColorScheme,
  FontWeight,
  Glyph,
  H3,
  H4,
  HoverEffect,
  Icon,
  JustifyContent,
  P,
  PlainButton,
  Space,
  Tag,
  TagColor,
  Text,
  Tooltip,
  TypePreset,
  useTheme,
} from "@gocardless/flux-react";
import { ReactNode } from "react";
import { ReportingMetricResponseBody } from "@gocardless/api/dashboard/reporting";
import { ReportingTimeSeriesMetric } from "@gocardless/api/dashboard/types";

import LineChart from "../../charts/LineChart/LineChart";
import BarChart from "../../charts/BarChart/BarChart";
import {
  ExperimentChartWidgetType,
  ExperimentChartWidgetTypes,
  ExperimentNames,
} from "../utils";
import { useFallbackDataPoints } from "../../../hooks/useFallbackDataPoints";
import { InstantBankPaymentEducationDialog } from "../dialogs/InstantBankPaymentEducationDialog";
import { FasterACHEducationDialog } from "../dialogs/FasterACHEducationDialog";
import { FasterACHUpgradeDialog } from "../dialogs/FasterACHUpgradeDialog";
import { HiddenMetric } from "../HiddenMetric/HiddenMetric";

import { useReportingExperiment } from "./useReportingExperiment";

import lineChartImage from "src/assets/svg/line-chart.svg";
import doubleLineChartImage from "src/assets/svg/double-line-chart.svg";
import dataTableImage from "src/assets/svg/data-table.svg";
import { Currency } from "src/common/currencies";
import { Route, routerPush } from "src/common/routing";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { usePackageSelectionV3 } from "src/components/routes/Setup/package-selection/v3/usePackageSelectionV3/usePackageSelectionV3";
import { PricingPackagesV3 } from "src/components/routes/Setup/package-selection/v3/packages/package/usePackageStateV3/usePackageStateV3";
import LoadingDialog from "src/components/ui/LoadingDialog/LoadingDialog";
import useToggle from "src/hooks/useToggle";

export const useExperimentChartWidgetTypesMap: ({
  currency,
  data,
  onOpenFeedbackDialog,
}: {
  currency: Currency;
  data:
    | ReportingMetricResponseBody
    | (ReportingMetricResponseBody & {
        rolling_six_month_payment_volume?: ReportingTimeSeriesMetric[] &
          {
            monthly_payment_volume: number;
          }[];
      })
    | undefined;
  onOpenFeedbackDialog: () => void;
}) => Record<
  ExperimentChartWidgetType,
  {
    header: ReactNode;
    chart: ReactNode;
    footer: ReactNode;
  }
> = ({ currency = Currency.Gbp, data, onOpenFeedbackDialog }) => {
  const {
    isOn: isIBPEducationDialogOpen,
    on: openIBPEducationDialog,
    off: closeIBPEducationDialog,
  } = useToggle();

  const {
    isOn: isFasterACHEducationDialogOpen,
    on: openFasterACHEducationDialog,
    off: closeFasterACHEducationDialog,
  } = useToggle();

  const {
    isOn: isUpgradeFasterACHDialogOpen,
    on: openUpgradeFasterACHDialog,
    off: closeUpgradeFasterACHDialog,
  } = useToggle();

  const { requestPackage, loading } = usePackageSelectionV3();

  const { sendEvent } = useSegment();
  const { theme } = useTheme();

  const fallbackDataPoints = useFallbackDataPoints();
  const { isUpsellFasterAchExperimentEnabled } = useReportingExperiment();

  const handleSetUpIBP = () =>
    routerPush({ route: Route.OneOffPaymentCustomerSearch });

  const handleContinueFasterACH = () => {
    closeFasterACHEducationDialog();
    openUpgradeFasterACHDialog();
  };

  const handleSetUpFasterACH = () => {
    closeUpgradeFasterACHDialog();
    requestPackage(PricingPackagesV3.ADVANCED);
  };

  return {
    [ExperimentChartWidgetTypes.PayoutForecast]: {
      header: (
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Tag color={TagColor.Info} colorScheme={ColorScheme.OnDark}>
            <Text size={1}>New</Text>
          </Tag>
          <Space h={0.75} layout="inline" />
          <H3 id="payout-forecast" size={[4, 3]}>
            30 day Cash flow forecasting
          </H3>
          <Space h={0.5} layout="inline" />
          <Text
            onClick={() =>
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_30_DAY_CASH_FLOW_FORECASTING_TOOLTIP_CLICKED,
                {
                  experiment_name: isUpsellFasterAchExperimentEnabled
                    ? ExperimentNames.UpsellFasterACH
                    : ExperimentNames.UpsellIBP,
                  metric_name: ExperimentChartWidgetTypes.PayoutForecast,
                }
              )
            }
          >
            <Tooltip
              positionStrategy="absolute"
              message={
                <>
                  <Text layout="block" spaceBelow={0.5}>
                    Projected funds expected to be deposited into your bank
                    account on a specific future date, based on payments you
                    have created to date.
                  </Text>
                  <Text layout="block">
                    Please note that this is a directional forecast and may
                    change due to payment failures or cancellations.
                  </Text>
                </>
              }
            >
              More information about 30 day Cash flow forecasting chart
            </Tooltip>
          </Text>
        </Box>
      ),
      chart: (
        <Box height={[200, 300]}>
          <LineChart
            isAnimationActive={false}
            ariaLabelledby="payout-forecast"
            currency={currency}
            data={data?.payout_forecast?.[currency] ?? fallbackDataPoints}
            xDataKey="date"
            lines={[
              { dataKey: "value", stroke: Color.Dusk_600 },
              ...(isUpsellFasterAchExperimentEnabled
                ? [
                    {
                      dataKey: "upgrade_value",
                      stroke: Color.Ultraviolet_600,
                      strokeDasharray: "5 5",
                    },
                  ]
                : []),
            ]}
            customLabels={
              isUpsellFasterAchExperimentEnabled
                ? {
                    value: "Your payouts",
                    upgrade_value: "Your payouts with Faster ACH",
                  }
                : undefined
            }
            hoverEvent={{
              event:
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_30_DAY_CASH_FLOW_FORECASTING_CHART_HOVERED,
              properties: {
                experiment_name: isUpsellFasterAchExperimentEnabled
                  ? ExperimentNames.UpsellFasterACH
                  : ExperimentNames.UpsellIBP,
                metric_name: ExperimentChartWidgetTypes.PayoutForecast,
              },
            }}
          />
        </Box>
      ),
      footer: (
        <>
          <Box
            bg={Color.Ocean_50}
            borderColor={Color.Ocean_200}
            borderWidth={1}
            borderRadius={1}
            gutterV={1.5}
            gutterH={1.5}
            layout="flex"
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.SpaceBetween}
            flexWrap="wrap"
            css={{ gap: theme.spacing(1) }}
          >
            <Box spaceAfter={0.5}>
              <H4 spaceBelow={0.25} size={[3, 2]}>
                {isUpsellFasterAchExperimentEnabled
                  ? "Need funds faster?"
                  : "Need your payments faster?"}
              </H4>

              <P preset={TypePreset.Caption_01}>
                {isUpsellFasterAchExperimentEnabled
                  ? "Receive funds in your account up to 2 days sooner with Faster ACH"
                  : "Boost your cash flow with our Instant Bank Pay feature"}
              </P>
            </Box>
            <Box flexShrink={0}>
              <Button
                size={ButtonSize.Sm}
                variant={ButtonVariant.SecondaryOnLight}
                onClick={() => {
                  isUpsellFasterAchExperimentEnabled
                    ? openFasterACHEducationDialog()
                    : openIBPEducationDialog();
                  sendEvent(
                    isUpsellFasterAchExperimentEnabled
                      ? TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LEARN_MORE_BUTTON_CLICKED
                      : TrackingEvent.MERCHANT_DASHBOARD_REPORTING_TRY_INSTANT_PAYMENT_CTA_CLICKED,
                    {
                      ctx: isUpsellFasterAchExperimentEnabled
                        ? "Faster ACH banner"
                        : "IBP banner",
                      experiment_name: isUpsellFasterAchExperimentEnabled
                        ? ExperimentNames.UpsellFasterACH
                        : ExperimentNames.UpsellIBP,
                      metric_name: ExperimentChartWidgetTypes.PayoutForecast,
                    }
                  );
                }}
              >
                {isUpsellFasterAchExperimentEnabled
                  ? "Learn more"
                  : "Try an instant payment"}
              </Button>
            </Box>
          </Box>
          {isUpsellFasterAchExperimentEnabled ? (
            <>
              <FasterACHEducationDialog
                open={isFasterACHEducationDialogOpen}
                onClose={closeFasterACHEducationDialog}
                onSubmit={handleContinueFasterACH}
              />
              <FasterACHUpgradeDialog
                open={isUpgradeFasterACHDialogOpen}
                onClose={closeUpgradeFasterACHDialog}
                onSubmit={handleSetUpFasterACH}
              />
              <LoadingDialog open={loading} />
            </>
          ) : (
            <InstantBankPaymentEducationDialog
              open={isIBPEducationDialogOpen}
              onClose={closeIBPEducationDialog}
              onSubmit={handleSetUpIBP}
            />
          )}
        </>
      ),
    },
    [ExperimentChartWidgetTypes.MonthlyPaymentVolume]: {
      header: (
        <Box layout="flex" alignItems={AlignItems.Center}>
          <H3 id="monthly-payment-volume" size={[4, 3]}>
            Monthly payment volume
          </H3>
          <Space h={0.5} layout="inline" />
          <Text
            onClick={() =>
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_MONTHLY_PAYMENT_VOLUME_TOOLTIP_CLICKED,
                {
                  experiment_name: ExperimentNames.FutureData,
                  metric_name: ExperimentChartWidgetTypes.MonthlyPaymentVolume,
                }
              )
            }
          >
            <Tooltip
              positionStrategy="absolute"
              message="Your total amount of payments collected from your customers each month."
            >
              More information about Monthly payment volume chart
            </Tooltip>
          </Text>
        </Box>
      ),
      chart: (
        <Box height={[200, 300]}>
          <BarChart
            isAnimationActive={false}
            ariaLabelledby="monthly-payment-volume"
            currency={currency}
            data={
              data?.monthly_payment_volume?.[currency] ?? fallbackDataPoints
            }
            xDataKey="date"
            bars={[{ dataKey: "value", fill: Color.Dusk_600 }]}
            showTooltips={
              (data?.monthly_payment_volume?.[currency] ?? []).length > 0
            }
            hoverEvent={{
              event:
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_MONTHLY_PAYMENT_VOLUME_CHART_HOVERED,
              properties: {
                experiment_name: ExperimentNames.FutureData,
                metric_name: ExperimentChartWidgetTypes.MonthlyPaymentVolume,
              },
            }}
          />
        </Box>
      ),
      footer: (
        <PlainButton
          decoration="underline"
          onClick={() => {
            onOpenFeedbackDialog();
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_REPORTING_VIEW_MORE_CTA_CLICKED,
              {
                experiment_name: ExperimentNames.FutureData,
                metric_name: ExperimentChartWidgetTypes.MonthlyPaymentVolume,
              }
            );
          }}
          preset={TypePreset.Body_01}
          weight={FontWeight.SemiBold}
        >
          View more
        </PlainButton>
      ),
    },
    [ExperimentChartWidgetTypes.FutureRevenueForecasting]: {
      header: (
        <Box layout="flex" alignItems={AlignItems.Center}>
          <H3 size={[4, 3]}>Future revenue forecasting</H3>
          <Space h={0.5} layout="inline" />
          <Text
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FUTURE_REVENUE_FORECASTING_TOOLTIP_CLICKED,
                {
                  experiment_name: ExperimentNames.FutureData,
                  metric_name:
                    ExperimentChartWidgetTypes.FutureRevenueForecasting,
                }
              );
            }}
          >
            <Tooltip
              positionStrategy="absolute"
              message="This data isn't available yet. Register your interest to learn more."
            >
              More information about Future revenue forecasting chart
            </Tooltip>
          </Text>
        </Box>
      ),
      chart: (
        <HiddenMetric
          filter="blur(4px)"
          opacity={0.8}
          image={{
            src: lineChartImage,
            alt: "Future revenue forecasting chart",
          }}
          metricName={ExperimentChartWidgetTypes.FutureRevenueForecasting}
          onRegisterInterest={onOpenFeedbackDialog}
        />
      ),
      footer: (
        <PlainButton
          decoration="underline"
          onClick={() => {
            onOpenFeedbackDialog();
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LEARN_MORE_BUTTON_CLICKED,
              {
                experiment_name: ExperimentNames.FutureData,
                metric_name:
                  ExperimentChartWidgetTypes.FutureRevenueForecasting,
              }
            );
          }}
          preset={TypePreset.Body_01}
          weight={FontWeight.SemiBold}
        >
          Learn more
        </PlainButton>
      ),
    },
    [ExperimentChartWidgetTypes.PaymentFailureReasons]: {
      header: (
        <Box layout="flex" alignItems={AlignItems.Center}>
          <H3 size={[4, 3]}>Reasons for payment failures</H3>
          <Space h={0.5} layout="inline" />
          <Text
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_REASONS_FOR_PAYMENT_FAILURES_TOOLTIP_CLICKED,
                {
                  experiment_name: ExperimentNames.FutureData,
                  metric_name: ExperimentChartWidgetTypes.PaymentFailureReasons,
                }
              );
            }}
          >
            <Tooltip
              positionStrategy="absolute"
              message="This data isn't available yet. Register your interest to learn more."
            >
              More information about Reasons for payment failures table
            </Tooltip>
          </Text>
        </Box>
      ),
      chart: (
        <HiddenMetric
          image={{
            src: dataTableImage,
            alt: "Reasons for payment failures table",
          }}
          metricName={ExperimentChartWidgetTypes.PaymentFailureReasons}
          opacity={0.2}
          onRegisterInterest={onOpenFeedbackDialog}
        />
      ),
      footer: (
        <PlainButton
          decoration="underline"
          onClick={() => {
            onOpenFeedbackDialog();
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LEARN_MORE_BUTTON_CLICKED,
              {
                experiment_name: ExperimentNames.FutureData,
                metric_name: ExperimentChartWidgetTypes.PaymentFailureReasons,
              }
            );
          }}
          preset={TypePreset.Body_01}
          weight={FontWeight.SemiBold}
        >
          Learn more
        </PlainButton>
      ),
    },
    [ExperimentChartWidgetTypes.MonthlyFailureRate]: {
      header: (
        <Box layout="flex" alignItems={AlignItems.Center}>
          <H3 id="monthly-failure-rate" size={[4, 3]}>
            Failure rate
          </H3>
          <Space h={0.5} layout="inline" />
          <Text
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FAILURE_RATE_TOOLTIP_CLICKED,
                {
                  experiment_name: ExperimentNames.FutureData,
                  metric_name: ExperimentChartWidgetTypes.MonthlyFailureRate,
                }
              );
            }}
          >
            <Tooltip
              positionStrategy="absolute"
              message={`
              This graph displays your monthly payment failure rates alongside the
              12-month average for your business, with the addition of a combined
              location and business type (Sole trader & Limited company) average
              across all GoCardless merchants.
            `}
            >
              More information about Failure rate chart
            </Tooltip>
          </Text>
        </Box>
      ),
      chart: (
        <Box height={[200, 300]}>
          <LineChart
            isAnimationActive={false}
            ariaLabelledby="monthly-failure-rate"
            data={data?.monthly_failure_rate?.[currency] ?? fallbackDataPoints}
            dataFormat="percentage"
            xDataKey="date"
            lines={[
              { dataKey: "value", stroke: Color.Dusk_600 },
              {
                dataKey: "benchmarks.merchant",
                stroke: Color.Ultraviolet_500,
                strokeDasharray: "5 5",
              },
              {
                dataKey: "benchmarks.geo",
                stroke: Color.Ultraviolet_900,
                strokeDasharray: "5 5",
              },
            ]}
            showTooltips={
              (data?.monthly_failure_rate?.[currency]?.length ?? 0) > 0
            }
            customLabels={{
              value: "Your monthly failure rate",
              "benchmarks.merchant": "Your 12 month average",
              "benchmarks.geo": "GoCardless country average",
            }}
            hoverEvent={{
              event:
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FAILURE_RATE_CHART_HOVERED,
              properties: {
                experiment_name: ExperimentNames.FutureData,
                metric_name: ExperimentChartWidgetTypes.MonthlyFailureRate,
              },
            }}
          />
        </Box>
      ),
      footer: (
        <PlainButton
          decoration="underline"
          onClick={() => {
            onOpenFeedbackDialog();
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_REPORTING_VIEW_MORE_CTA_CLICKED,
              {
                experiment_name: ExperimentNames.FutureData,
                metric_name: ExperimentChartWidgetTypes.MonthlyFailureRate,
              }
            );
          }}
          preset={TypePreset.Body_01}
          weight={FontWeight.SemiBold}
        >
          View more
        </PlainButton>
      ),
    },
    [ExperimentChartWidgetTypes.SimilarBusinessFailureRates]: {
      header: (
        <Box layout="flex" alignItems={AlignItems.Center}>
          <H3 size={[4, 3]}>Failure rate for similar businesses</H3>
          <Space h={0.5} layout="inline" />
          <Text
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FAILURE_RATE_FOR_SIMILAR_BUSINESSES_TOOLTIP_CLICKED,
                {
                  experiment_name: ExperimentNames.FutureData,
                  metric_name:
                    ExperimentChartWidgetTypes.SimilarBusinessFailureRates,
                }
              );
            }}
          >
            <Tooltip
              positionStrategy="absolute"
              message="This data isn't available yet. Register your interest to learn more."
            >
              More information about Failure rate for similar businesses chart
            </Tooltip>
          </Text>
        </Box>
      ),
      chart: (
        <HiddenMetric
          filter="blur(4px)"
          opacity={0.8}
          image={{
            src: doubleLineChartImage,
            alt: "Failure rate for similar businesses chart",
          }}
          metricName={ExperimentChartWidgetTypes.SimilarBusinessFailureRates}
          onRegisterInterest={onOpenFeedbackDialog}
        />
      ),
      footer: (
        <PlainButton
          decoration="underline"
          onClick={() => {
            onOpenFeedbackDialog();
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LEARN_MORE_BUTTON_CLICKED,
              {
                experiment_name: ExperimentNames.FutureData,
                metric_name:
                  ExperimentChartWidgetTypes.SimilarBusinessFailureRates,
              }
            );
          }}
          preset={TypePreset.Body_01}
          weight={FontWeight.SemiBold}
        >
          Learn more
        </PlainButton>
      ),
    },
    [ExperimentChartWidgetTypes.RollingSixMonthPaymentVolume]: {
      header: (
        <Box layout="flex" alignItems={AlignItems.Center}>
          <H3 id="rolling-payment-volume" size={[4, 3]}>
            Rolling 6-month total payment volume
          </H3>
          <Space h={0.5} layout="inline" />
          <Text
            onClick={() =>
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_ROLLING_6_MONTH_PAYMENT_VOLUME_TOOLTIP_CLICKED,
                {
                  experiment_name: ExperimentNames.FutureData,
                  metric_name:
                    ExperimentChartWidgetTypes.RollingSixMonthPaymentVolume,
                }
              )
            }
          >
            <Tooltip
              positionStrategy="absolute"
              message={
                <>
                  <Text layout="block" spaceBelow={0.5}>
                    This chart illustrates the 6-month rolling total of payment
                    volumes against the monthly payment volumes.
                  </Text>
                  <Text layout="block">
                    The rolling total aggregates payment volumes from the past
                    six months, providing a smoothed view of your financial
                    trends. Use this comparison to analyse long-term performance
                    and identify seasonal patterns.
                  </Text>
                </>
              }
            >
              More information about Rolling 6-month payment volume chart
            </Tooltip>
          </Text>
        </Box>
      ),
      chart: (
        <Box height={[200, 300]}>
          <LineChart
            isAnimationActive={false}
            ariaLabelledby="rolling-payment-volume"
            currency={currency}
            data={
              (data?.rolling_six_month_payment_volume as ReportingTimeSeriesMetric[]) ??
              fallbackDataPoints
            }
            xDataKey="date"
            lines={[
              { dataKey: "value", stroke: Color.Dusk_600 },
              {
                dataKey: "monthly_payment_volume",
                stroke: Color.Ultraviolet_500,
              },
            ]}
            showTooltips={
              (
                data?.rolling_six_month_payment_volume as ReportingTimeSeriesMetric[]
              )?.length > 0
            }
            customLabels={{
              value: "Rolling total",
              monthly_payment_volume: "Monthly volume",
            }}
            hoverEvent={{
              event:
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_ROLLING_6_MONTH_PAYMENT_VOLUME_CHART_HOVERED,
              properties: {
                experiment_name: ExperimentNames.FutureData,
                metric_name:
                  ExperimentChartWidgetTypes.RollingSixMonthPaymentVolume,
              },
            }}
          />
        </Box>
      ),
      footer: (
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.SpaceBetween}
          flexWrap="wrap-reverse"
          css={{ gap: theme.spacing(0.5) }}
        >
          <PlainButton
            decoration="underline"
            onClick={() => {
              onOpenFeedbackDialog();
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_VIEW_MORE_CTA_CLICKED,
                {
                  experiment_name: ExperimentNames.FutureData,
                  metric_name:
                    ExperimentChartWidgetTypes.RollingSixMonthPaymentVolume,
                }
              );
            }}
            preset={TypePreset.Body_01}
            weight={FontWeight.SemiBold}
          >
            View more
          </PlainButton>

          <Text
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_ROLLING_6_MONTH_PAYMENT_VOLUME_TOOLTIP_CLICKED,
                {
                  ctx: "Why show 24 months data?",
                  experiment_name: ExperimentNames.FutureData,
                  metric_name:
                    ExperimentChartWidgetTypes.RollingSixMonthPaymentVolume,
                }
              );
            }}
          >
            <Tooltip
              positionStrategy="absolute"
              message={`
              We display 24 months of data instead of 12, providing a more
              comprehensive insight into your rolling average.
            `}
            >
              {(triggerProps) => (
                <PlainButton
                  {...triggerProps}
                  effect={HoverEffect.TextDecoration}
                  preset={TypePreset.Body_01}
                >
                  <Icon
                    color={ColorPreset.IconOnLight_03}
                    name={Glyph.InfoCircle}
                    verticalAlign="middle"
                  />
                  <Space h={0.5} layout="inline" />
                  <Text>Why show 24 months data?</Text>
                </PlainButton>
              )}
            </Tooltip>
          </Text>
        </Box>
      ),
    },
    [ExperimentChartWidgetTypes.AverageRevenuePerCustomer]: {
      header: (
        <Box layout="flex" alignItems={AlignItems.Center}>
          <H3 id="average-revenue-per-customer" size={[4, 3]}>
            Average monthly revenue per customer
          </H3>
          <Space h={0.5} layout="inline" />
          <Text
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_AVERAGE_REVENUE_PER_CUSTOMER_TOOLTIP_CLICKED,
                {
                  experiment_name: ExperimentNames.FutureData,
                  metric_name:
                    ExperimentChartWidgetTypes.AverageRevenuePerCustomer,
                }
              );
            }}
          >
            <Tooltip
              positionStrategy="absolute"
              message={
                <>
                  <Text layout="block" spaceBelow={0.5}>
                    This chart displays the average revenue generated from each
                    customer over a monthly period.
                  </Text>
                  <Text layout="block">
                    It calculates the total value of collected payments divided
                    by the number of active customers that were charged in that
                    month. This does not take into account failed payments or
                    additional fees. It helps you track revenue trends, assess
                    customer value, and identify opportunities for growth.
                  </Text>
                </>
              }
            >
              More information about Average revenue per customer chart
            </Tooltip>
          </Text>
        </Box>
      ),
      chart: (
        <Box height={[200, 300]}>
          <LineChart
            isAnimationActive={false}
            ariaLabelledby="average-revenue-per-customer"
            currency={currency}
            data={
              data?.average_revenue_per_customer?.[currency] ??
              fallbackDataPoints
            }
            xDataKey="date"
            lines={[{ dataKey: "value", stroke: Color.Dusk_600 }]}
            showTooltips={
              (data?.average_revenue_per_customer?.[currency] ?? []).length > 0
            }
            hoverEvent={{
              event:
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_AVERAGE_REVENUE_PER_CUSTOMER_CHART_HOVERED,
              properties: {
                experiment_name: ExperimentNames.FutureData,
                metric_name:
                  ExperimentChartWidgetTypes.AverageRevenuePerCustomer,
              },
            }}
          />
        </Box>
      ),
      footer: (
        <PlainButton
          decoration="underline"
          onClick={() => {
            onOpenFeedbackDialog();
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_REPORTING_VIEW_MORE_CTA_CLICKED,
              {
                experiment_name: ExperimentNames.FutureData,
                metric_name:
                  ExperimentChartWidgetTypes.AverageRevenuePerCustomer,
              }
            );
          }}
          preset={TypePreset.Body_01}
          weight={FontWeight.SemiBold}
        >
          View more
        </PlainButton>
      ),
    },
    [ExperimentChartWidgetTypes.ActiveCustomersHistory]: {
      header: (
        <Box layout="flex" alignItems={AlignItems.Center}>
          <H3 id="active-customers" size={[4, 3]}>
            Active customers
          </H3>
          <Space h={0.5} layout="inline" />
          <Text
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_ACTIVE_CUSTOMERS_TOOLTIP_CLICKED,
                {
                  experiment_name: ExperimentNames.FutureData,
                  metric_name:
                    ExperimentChartWidgetTypes.ActiveCustomersHistory,
                }
              );
            }}
          >
            <Tooltip
              positionStrategy="absolute"
              message="Your total number of active customers."
            >
              More information about Active customers chart
            </Tooltip>
          </Text>
        </Box>
      ),
      chart: (
        <Box height={[200, 300]}>
          <BarChart
            isAnimationActive={false}
            ariaLabelledby="active-customers"
            data={data?.active_customers_history ?? fallbackDataPoints}
            xDataKey="date"
            bars={[{ dataKey: "value", fill: Color.Dusk_600 }]}
            showTooltips={(data?.active_customers_history ?? []).length > 0}
            hoverEvent={{
              event:
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_ACTIVE_CUSTOMERS_CHART_HOVERED,
              properties: {
                experiment_name: ExperimentNames.FutureData,
                metric_name: ExperimentChartWidgetTypes.ActiveCustomersHistory,
              },
            }}
          />
        </Box>
      ),
      footer: (
        <PlainButton
          decoration="underline"
          onClick={() => {
            onOpenFeedbackDialog();
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_REPORTING_VIEW_MORE_CTA_CLICKED,
              {
                experiment_name: ExperimentNames.FutureData,
                metric_name: ExperimentChartWidgetTypes.ActiveCustomersHistory,
              }
            );
          }}
          preset={TypePreset.Body_01}
          weight={FontWeight.SemiBold}
        >
          View more
        </PlainButton>
      ),
    },
    [ExperimentChartWidgetTypes.CustomersAtRisk]: {
      header: (
        <Box layout="flex" alignItems={AlignItems.Center}>
          <H3 id="customers-at-risk" size={[4, 3]}>
            Customers at risk
          </H3>
          <Space h={0.5} layout="inline" />
          <Text
            onClick={() => {
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_CUSTOMERS_AT_RISK_TOOLTIP_CLICKED,
                {
                  experiment_name: ExperimentNames.FutureData,
                  metric_name: ExperimentChartWidgetTypes.CustomersAtRisk,
                }
              );
            }}
          >
            <Tooltip
              positionStrategy="absolute"
              message="This data isn't available yet. Register your interest to learn more."
            >
              More information about Customers at risk table
            </Tooltip>
          </Text>
        </Box>
      ),
      chart: (
        <HiddenMetric
          image={{
            src: dataTableImage,
            alt: "Customers at risk table",
          }}
          metricName={ExperimentChartWidgetTypes.CustomersAtRisk}
          opacity={0.2}
          onRegisterInterest={onOpenFeedbackDialog}
        />
      ),
      footer: (
        <PlainButton
          decoration="underline"
          onClick={() => {
            onOpenFeedbackDialog();
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LEARN_MORE_BUTTON_CLICKED,
              {
                experiment_name: ExperimentNames.FutureData,
                metric_name: ExperimentChartWidgetTypes.CustomersAtRisk,
              }
            );
          }}
          preset={TypePreset.Body_01}
          weight={FontWeight.SemiBold}
        >
          Learn more
        </PlainButton>
      ),
    },
  };
};
