import { rawPaths, Route, RouteParams } from "src/common/routing/routes";

export interface RedirectResult {
  previousPage: Route;
  previousRouteParams?: RouteParams;
  previousQueryParams?: Record<string, string>;
}

interface QueryParams {
  [key: string]: string | string[] | undefined;
}

export const acceptedRedirects = {
  payments_share: "payments_share",
  payments: "payments",
  customers: "customers",
  recurrent_payment: "recurrent_payment",
  one_off_payment: "one_off_payment",
  instalment_schedules: "instalment_schedules",
  success_plus_configure: "success_plus_configure",
};

export const acceptedRedirectsRawPaths: Record<string, string> = {
  [acceptedRedirects.payments_share]: rawPaths[Route.PaymentShare],
  [acceptedRedirects.payments]: rawPaths[Route.PaymentCreate],
  [acceptedRedirects.customers]: rawPaths[Route.CustomersCreate],
  [acceptedRedirects.recurrent_payment]: rawPaths[Route.RecurringPaymentCreate],
  [acceptedRedirects.one_off_payment]: rawPaths[Route.OneOffPaymentCreate],
  [acceptedRedirects.instalment_schedules]:
    rawPaths[Route.InstalmentScheduleCreate],
  [acceptedRedirects.success_plus_configure]:
    rawPaths[Route.SuccessPlusConfigure],
};

const filterStringValues = <T extends Record<string, string | undefined>>(
  queryParams: QueryParams | undefined
): T | undefined =>
  !queryParams
    ? undefined
    : (Object.fromEntries(
        Object.entries(queryParams).filter(
          ([, value]) => typeof value === "string"
        )
      ) as T);

export const getValidRedirect = (
  query: QueryParams
): RedirectResult | undefined => {
  const redirectTo = (query as { redirectTo?: string }).redirectTo;
  if (redirectTo === undefined) {
    return undefined;
  }
  if (redirectTo in acceptedRedirectsRawPaths) {
    for (const routeKey in rawPaths) {
      if (
        rawPaths[routeKey as Route] === acceptedRedirectsRawPaths[redirectTo]
      ) {
        const mergedQuery = { ...query };
        delete mergedQuery.redirectTo;
        const isRouteParam = rawPaths[routeKey as Route].includes(":");
        return {
          previousPage: routeKey as Route,
          previousRouteParams: isRouteParam
            ? filterStringValues<RouteParams>(mergedQuery)
            : undefined,
          previousQueryParams: !isRouteParam
            ? filterStringValues<Record<string, string>>(mergedQuery)
            : undefined,
        };
      }
    }
  }
  return undefined;
};

export const getBackTo = (pathname: string) => {
  if (pathname === undefined) {
    return undefined;
  }
  for (const key in acceptedRedirectsRawPaths) {
    if (Object.prototype.hasOwnProperty.call(acceptedRedirectsRawPaths, key)) {
      // compare Next path with our raw path syntax
      if (
        acceptedRedirectsRawPaths[key] ===
        pathname.replace(/\[([^\]]+)\]/g, ":$1")
      ) {
        return key;
      }
    }
  }
  return undefined;
};
