import {
  Interpose,
  Space,
  Dialog,
  Text,
  TypePreset,
  FontWeight,
  Glyph,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useEffect } from "react";

import CustomerCard from "./CustomerCard";

import { Route, routerPush } from "src/common/routing";
import { useSegmentForSetup } from "src/components/routes/Setup/common/hooks/useSegmentForSetup";
import { TrackingEvent } from "src/common/trackingEvents";

interface CreatePaymentDialogProps {
  open: boolean;
  toggleDialog: () => void;
  openPaymentFromCustomerDialog: () => void;
}

const CreatePaymentDialog: React.FC<CreatePaymentDialogProps> = ({
  open,
  toggleDialog,
  openPaymentFromCustomerDialog,
}) => {
  const { sendEvent, sendEventPromise } = useSegmentForSetup();

  useEffect(() => {
    if (!open) return;

    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_CREATE_PAYMENT_DIALOG_OPENED
    );
  }, [open, sendEvent]);

  return (
    <Dialog
      as="div"
      open={open}
      header={
        <Text preset={TypePreset.Heading_03} weight={FontWeight.SemiBold}>
          <Trans id="create-payment-dialog.title">Create new payment</Trans>
        </Text>
      }
      closeAction={{
        label: "Close",
        onClose: () => {
          sendEvent(
            TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_CREATE_PAYMENT_DIALOG_CLOSED
          );
          toggleDialog();
        },
      }}
    >
      <Interpose node={<Space v={1} />}>
        <Text preset={TypePreset.Body_03} weight={FontWeight.SemiBold}>
          <Trans id="create-payment-dialog.description">
            Who are your collecting this payment from?
          </Trans>
        </Text>

        <CustomerCard
          title={
            <Trans id="create-payment-dialog.new-customers-title">
              New customers
            </Trans>
          }
          description={
            <Trans id="create-payment-dialog.new-customers-description">
              Request payments from new customers
            </Trans>
          }
          icon={Glyph.MailPlane}
          onClick={async () => {
            await sendEventPromise(
              TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_CREATE_PAYMENT_DIALOG_LINK_CLICKED,
              {
                target: "new_customers",
              }
            );
            routerPush({ route: Route.SetupPayments });
          }}
          testId="New customers"
        />

        <CustomerCard
          title={
            <Trans id="create-payment-dialog.existing-customers-title">
              Existing customers
            </Trans>
          }
          description={
            <Trans id="create-payment-dialog.existing-customers-description">
              Schedule payments for customers with a mandate
            </Trans>
          }
          icon={Glyph.User}
          onClick={() => {
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_CREATE_PAYMENT_DIALOG_LINK_CLICKED,
              { target: "existing_customers" }
            );
            openPaymentFromCustomerDialog();
          }}
          testId="Existing customers"
        />
      </Interpose>
    </Dialog>
  );
};

export default CreatePaymentDialog;
