import { useState } from "react";
import { HTTPError } from "ky-universal";
import _ from "lodash";
import { useUpgradeCreate } from "@gocardless/api/dashboard/upgrade";
import { getErrorsFromErrorResponse } from "@gocardless/api/utils/error";
import { UpgradeType } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";

import { PackageAddonUpgradeType } from "../packages/package/addons";

import { useOrganisationPreferences } from "src/libraries/organisation/hooks";
import { useToastNotification } from "src/hooks/useToastNotification";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useHasSelectedPackage } from "src/components/routes/SuccessPlus/api/index.hooks";

export const useUpgradeRequest = () => {
  const [error, setError] = useState(false);
  const { triggerErrorNotification, triggerSuccessNotification } =
    useToastNotification();
  const { updateOrganisationPreferences } = useOrganisationPreferences();
  const hasSelectedPackage = useHasSelectedPackage();

  const { isVariationOn: packageSelectionExperimentEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.PACKAGE_SELECTION_PHASE_ONE,
    });

  const [createUpgrade] = useUpgradeCreate({
    onSuccess: () => {
      if (!hasSelectedPackage) {
        triggerSuccessNotification({
          title: "Add-on added successfully",
          message: (
            <Trans id="setup.package-selection.add-on-added-successfully">
              {"Add-on added successfully"}
            </Trans>
          ),
        });
      }
    },
    onError: async (httpError: HTTPError) => {
      const errors = await getErrorsFromErrorResponse(httpError);

      errors.forEach((err) => {
        const humanizedRequestPointer = _.capitalize(
          _.last(_.get(err, "request_pointer", "").split("/"))
        ).replace(/_/g, " ");

        // TODO - come back to this logic [refactor]
        // this is to prevent error notifications from being displayed for upgrades that are already in review
        // useEffect in useUpgrades tries to submit upgrades that are already in review when calling  await createSingleUpgrade(updatedUpgrades);
        const in_review_error = err.message === "Upgrade already in review";
        if (packageSelectionExperimentEnabled && in_review_error) {
          return;
        }

        setError(true);

        triggerErrorNotification({
          message: `${humanizedRequestPointer} ${err.message}`,
        });
      });
    },
  });

  const upgradeRequest = (upgrade: PackageAddonUpgradeType) => {
    if (upgrade === UpgradeType.OwnSun) {
      // We set custom_sun_selected to true as we only create that upgrade later in the in flow when
      // we ask the merchant what name they would like to display.
      return updateOrganisationPreferences({
        preferences: { merchant_has_requested_custom_sun: true },
      });
    } else {
      return createUpgrade({ upgrade_type: upgrade });
    }
  };

  const createMultipleUpgrades = async (
    upgradesToAdd: PackageAddonUpgradeType[],
    onSuccess: () => void
  ) => {
    setError(false);

    if (upgradesToAdd.length) {
      const upgradePromises = upgradesToAdd.map((upgrade) =>
        upgradeRequest(upgrade)
      );

      await Promise.allSettled(upgradePromises);
    }

    if (!error) {
      onSuccess();
    }
  };

  const createSingleUpgrade = async (
    upgradesToAdd: PackageAddonUpgradeType[]
  ) => {
    if (!upgradesToAdd[0]) return;

    await upgradeRequest(upgradesToAdd[0]);
  };

  return {
    createSingleUpgrade,
    createMultipleUpgrades,
  };
};
