import Image from "next/image";
import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  H2,
  Icon,
  JustifyContent,
  P,
  PlainLink,
  ReadingOptimized,
  Space,
  Text,
  TypeScale,
  Visibility,
  useTheme,
} from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { LEARN_MORE_BASE_URL } from "../../utils/common";

import { betaWidgetStyle } from "./BetaWidget.style";

import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import FeedbackAnimatedIcon from "src/assets/svg/feedback-animated.svg";

const BetaWidget: React.FC = () => {
  const { i18n } = useLingui();
  const { theme } = useTheme();
  const { sendEvent } = useSegment();

  const Heading = () => (
    <H2
      color={Color.Ultraviolet_700}
      decoration={["underline", "none"]}
      layout={["inline", "block"]}
      size={[TypeScale.Size_02, TypeScale.Size_03]}
      spaceBelow={[0, 0.5]}
      weight={FontWeight.SemiBold}
    >
      {i18n._(t({ message: "Get more from reporting" }))}
    </H2>
  );

  return (
    <Box
      bg={[Color.Ultraviolet_50, Color.White]}
      borderColor={[Color.Transparent, ColorPreset.BorderOnLight_04]}
      borderWidth={[0, 1]}
      borderRadius={1}
      flexGrow={1}
      gutterH={1.5}
      gutterV={[1, 1.5]}
      justifyContent={JustifyContent.SpaceBetween}
      css={betaWidgetStyle(theme)}
    >
      <Box
        layout="flex"
        flexDirection={["row-reverse", "column"]}
        justifyContent={JustifyContent.Center}
        alignItems={[AlignItems.Center, AlignItems.FlexStart]}
      >
        <Text spaceBelow={[0, 0.75]}>
          <Image src={FeedbackAnimatedIcon} alt="" width={16} height={16} />
        </Text>
        <Space h={[0.5, 0]} layout="inline" />

        <Visibility visible={["block", "none"]}>
          <PlainLink
            onClick={() =>
              sendEvent(
                TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LEARN_MORE_BUTTON_CLICKED
              )
            }
            href={LEARN_MORE_BASE_URL}
            target="_blank"
            rel="noopener"
          >
            <Heading />
          </PlainLink>
        </Visibility>

        <Visibility visible={["none", "block"]}>
          <Heading />
        </Visibility>

        <Visibility visible={["none", "block"]}>
          <ReadingOptimized textWidth={["100%"]}>
            <P size={TypeScale.Size_01}>
              <Trans>
                Learn how our product can help monitor your business&apos;s
                performance.
              </Trans>
            </P>
          </ReadingOptimized>
        </Visibility>
      </Box>

      <Visibility visible={["none", "block"]}>
        <PlainLink
          href={LEARN_MORE_BASE_URL}
          target="_blank"
          rel="noopener"
          onClick={() =>
            sendEvent(
              TrackingEvent.MERCHANT_DASHBOARD_REPORTING_LEARN_MORE_BUTTON_CLICKED
            )
          }
        >
          <Box
            layout="inline-flex"
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.Center}
            borderRadius={2}
            borderWidth={2}
            borderColor={Color.Greystone_1400}
            width={36}
            height={36}
          >
            <Icon name={Glyph.ArrowForward} size="15px" />
          </Box>
        </PlainLink>
      </Visibility>
    </Box>
  );
};

export default BetaWidget;
