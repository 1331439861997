import {
  Box,
  Text,
  TypePreset,
  Radio,
  Input,
  TypeScale,
  Space,
  Interpose,
  Field,
  Label,
  FieldSet,
  Legend,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useFormContext } from "react-hook-form";

import { FeedbackFormData } from "../../utils";

import { Currency } from "src/common/currencies";
import { ToTranslate } from "src/components/i18n";
import { getInputErrorStatus, VALID_AMOUNT } from "src/utils/inputValidation";
import { useSendPageViewEvent } from "src/technical-integrations/segment/useSendPageViewEvent";
import { TrackingEvent } from "src/common/trackingEvents";

export const PricingFeedbackStep: React.FC = () => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<FeedbackFormData>();

  useSendPageViewEvent(
    TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FEEDBACK_FORM_PRICING_STEP_VIEWED
  );

  const isWillingToPay = watch("willingToPay");

  const shouldRequireCostFields = isWillingToPay === "yes";
  return (
    <Box>
      <Interpose node={<Space v={0.25} />}>
        <FieldSet>
          <Legend>
            <Text preset={TypePreset.Heading_01}>
              3.
              <Space layout="inline" h={0.5} />
              <ToTranslate>
                Would you be willing to pay a fee for a great data insight &
                reporting offering?
              </ToTranslate>
            </Text>
          </Legend>
          <Space v={0.25} />
          <Box layout="flex">
            <Radio
              {...register("willingToPay", {
                required: true,
              })}
              value="yes"
              name="willingToPay"
            >
              <Trans>Yes</Trans>
            </Radio>
            <Space layout="inline" h={1.5} />
            <Radio
              {...register("willingToPay", {
                required: true,
              })}
              value="no"
              name="willingToPay"
            >
              <Trans>No</Trans>
            </Radio>
          </Box>
        </FieldSet>
        <Space v={0.75} />
        <Field>
          <Label htmlFor="expectedPrice">
            <Text preset={TypePreset.Heading_01}>
              <ToTranslate>
                At what monthly cost would you consider that offering great
                value for money?
              </ToTranslate>
            </Text>
          </Label>
          <Input
            id="expectedPrice"
            type="text"
            inputMode="decimal"
            min={0}
            step={0.01}
            placeholder="0.00"
            status={getInputErrorStatus(!!errors.expectedPrice)}
            {...register("expectedPrice", {
              required: shouldRequireCostFields,
              pattern: VALID_AMOUNT,
              min: { value: 0, message: "Amount must be greater than 0" },
              validate: (value) =>
                !value || Number(value) <= 0
                  ? "Amount must be greater than 0"
                  : undefined,
            })}
            rightAccessory={
              <>
                <Text size={TypeScale.Size_03} layout="inline-block">
                  {Currency.Gbp}
                </Text>
                <Space layout="inline" h={2} />
              </>
            }
          />
        </Field>
        <Space v={0.75} />
        <Field>
          <Label htmlFor="maxPayablePrice">
            <Text preset={TypePreset.Heading_01}>
              <ToTranslate>
                At what monthly cost would you consider the offering too
                expensive to consider it?
              </ToTranslate>
            </Text>
          </Label>
          <Input
            id="maxPayablePrice"
            type="text"
            inputMode="decimal"
            min={0}
            step={0.01}
            placeholder="0.00"
            status={getInputErrorStatus(!!errors.maxPayablePrice)}
            {...register("maxPayablePrice", {
              required: shouldRequireCostFields,
              pattern: VALID_AMOUNT,
              min: { value: 0, message: "Amount must be greater than 0" },
              validate: (value) =>
                !value || Number(value) <= 0
                  ? "Amount must be greater than 0"
                  : undefined,
            })}
            rightAccessory={
              <>
                <Text size={TypeScale.Size_03} layout="inline-block">
                  {Currency.Gbp}
                </Text>
                <Space layout="inline" h={2} />
              </>
            }
          />
        </Field>
      </Interpose>
    </Box>
  );
};
