import { colorToRgba } from "@react-spring/shared";

export const getRgbaColorWithOpacity = (
  hexColor: string,
  opacity: number
): string => {
  const rgbaColor = colorToRgba(hexColor);
  return typeof rgbaColor === "string"
    ? rgbaColor.substring(0, rgbaColor.length - 2) + opacity.toString() + ")"
    : "";
};
